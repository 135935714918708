<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  [closable]="false"
  [focusOnShow]='false'
  appendTo="body"
  header="{{'branches.trainingPlans.sendReminder' | translate }}"
>
  <div *ngIf='popupVisible' [formGroup]="formGroup">
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [errorTemplate]='contactErrorTemplate'
                                      formField='contactPersonId'
                                      fieldKey='branches.trainingPlans.contactPerson'
                                      [required]='true'>

      <alimento-ipv-frontend-contact-person-select formControlName='contactPersonId'
                                                   [pAutoFocus]='true'
                                                   [contactPersons]='contactPersons'
                                                   [isBranchOnlyContactPerson]='true'
                                                   [showLabels]='true'
      ></alimento-ipv-frontend-contact-person-select>

      <ng-template #contactErrorTemplate>
        <small *ngIf="formGroup.get('contactPersonId').errors?.['invalidContactPerson']">
          {{"branches.trainingPlans.contactPersonHasNoEmail" | translate }}
        </small>
        <small *ngIf="formGroup.get('contactPersonId').errors?.['required']">
          {{"validation.isRequired" | translate:{field: 'branches.trainingPlans.contactPerson' | translate} }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        [loading]="loading"
        (onClick)="submitPopup()"
        label="{{ 'branches.trainingPlans.sendReminder' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        label="{{ 'branches.trainingPlans.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
