<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [closable]="false"
  appendTo="body"
  header="{{
    (correction?.reimbursementId ? 'reimbursements.editCorrection' : 'reimbursements.addCorrection') | translate
  }}"
>
  <div *ngIf="formGroup" [formGroup]="formGroup">

    <div class='conditional-fields pb-4 grid grid-cols-3 gap-4'>
      <alimento-ipv-frontend-metadata-item *ngIf="parentReimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.TRAINING"
                                           header='trainings.totalCost'
                                           [value]='trainingData.sumTotalAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="parentReimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.TRAINING"
                                           header='trainings.trainingReimbursementMaxAmount'
                                           [value]='extraInfo.maxCorrection | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="parentReimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.PREPARATION"
                                           header='trainings.totalPreparationCost'
                                           [value]='trainingData.sumPreparationAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="parentReimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.PREPARATION"
                                           header='trainings.preparationReimbursementMaxAmount'
                                           [value]='extraInfo.maxCorrection | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item header='trainings.smeBonusBudget'
                                           *ngIf='parentReimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.SME_BONUS && trainingData.smeBalance'
                                           [value]='trainingData.smeBalance | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="parentReimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.SME_BONUS"
                                           header='trainings.smeBonusReimbursementAmount'
                                           [value]='extraInfo.maxCorrection | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="trainingData.totalAmountToPayout"
                                           header='trainings.totalAmountToPayout'
                                           [value]='trainingData.totalAmountToPayout | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>
    </div>

    <div>
      <div class="field-radiobutton mb-1">
        <p-radioButton
          name="adjustmentType"
          [value]="REIMBURSEMENT_CORRECTION.ADD_EXTRA"
          formControlName="adjustmentType"
          inputId="adjustment-type-true"
        ></p-radioButton>
        <label for='adjustment-type-true'>{{ 'reimbursements.addExtra' | translate }}</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton
          name="adjustmentType"
          [value]="REIMBURSEMENT_CORRECTION.ADD_ADJUSTMENT"
          formControlName="adjustmentType"
          inputId="adjustment-type-false"
        ></p-radioButton>
        <label for='adjustment-type-false'>{{ 'reimbursements.addAdjustment' | translate }}</label>
      </div>
    </div>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      [errorTemplate]='amountErrorTemplate'
                                      fieldKey='reimbursements.amount'
                                      formField='amount'>
      <p-inputNumber #numberControl
                     inputStyleClass='w-full'
                     formControlName='amount'
                     [maxFractionDigits]='2'
                     (onKeyDown)='onKeyDown($event, numberControl)'
                     mode="currency"
                     currency="EUR"
                     locale='en-US'
                     id='amount'>
      </p-inputNumber>

      <ng-template #amountErrorTemplate>
        <small *ngIf="formGroup.get('amount')?.hasError('max') && formGroup.get('adjustmentType').value == REIMBURSEMENT_CORRECTION.ADD_EXTRA">
          {{ 'validation.maxValue' | translate : { number: extraInfo.maxCorrection | currency:"EUR"} }}
        </small>
        <small *ngIf="formGroup.get('amount')?.hasError('max') && formGroup.get('adjustmentType').value == REIMBURSEMENT_CORRECTION.ADD_ADJUSTMENT">
          {{ 'validation.maxValue' | translate : { number: extraInfo.minCorrection | currency:"EUR"} }}
        </small>
        <small *ngIf="formGroup.get('amount')?.hasError('min')">
          {{ 'validation.minValue' | translate : { number: 0 | currency:"EUR"} }}
        </small>
        <small *ngIf="formGroup.get('amount')?.hasError('required')">
          {{'validation.isRequired'| translate : { field: 'reimbursements.amount' | translate } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='remarks'
      fieldKey='reimbursements.internalRemarks'
    >
      <textarea
        class="w-full"
        formControlName="remarks"
        id="remarks"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='externalRemarks'
      fieldKey='reimbursements.externalRemarks'
    >
      <textarea
        class="w-full"
        formControlName="externalRemarks"
        id="externalRemarks"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        [loading]="loading"
        (onClick)="addOrUpdateReimbursement()"
        label="{{ ( correction?.reimbursementId ? 'reimbursements.editCorrection' : 'reimbursements.addCorrection') | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        label="{{ 'reimbursements.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
