import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import { delay, first, Subject, takeUntil } from 'rxjs';

import { emailPattern, FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { Branch, TrainingPlan } from '../../../types/branch.type';
import { ENROLLMENT_VIA } from '../../../types/enrollment.enum';
import { Training } from '../../../types/training.type';
import { BranchService } from '../../../services/branch.service';
import { EnrollmentDetail } from '../../../types/enrollment.type';
import { DataLabelType } from '../../../types/reference-data.type';
import { isPupilProjectType, isTeacherProjectType, TRAINING_TYPE } from '../../../types/reference-data.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-branch-form',
    templateUrl: './enrollment-branch-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EnrollmentBranchFormComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: EnrollmentBranchFormComponent
        },
        { provide: FormComponent, useExisting: EnrollmentBranchFormComponent }
    ],
    standalone: false
})
export class EnrollmentBranchFormComponent extends FormComponent implements ControlValueAccessor, Validator, OnChanges {
  enrollmentViaOptions: DataLabelType[];
  isMaxOfCompany = false;
  enrollmentViaReadOnly: string;
  trainingPlan: TrainingPlan;
  showEnrollmentViaReadOnly = false;

  @Input()
  selectedBranch?: Branch;

  @Input()
  training: Training;

  @Input()
  enrollmentId?: string;

  @ViewChild(FormComponent)
  addressComponent?: FormComponent;

  @ViewChild("viaComponent")
  focusElement: Dropdown;

  private cancelFetchBranch$: Subject<void>;
  protected readonly ENROLLMENT_VIA = ENROLLMENT_VIA;
  protected readonly ALL_EMPLOYMENT_VIA = [
    { data: ENROLLMENT_VIA.NO_COMPANY, label: this.translateService.instant('enrollments.noCompany') },
    { data: ENROLLMENT_VIA.COMPANY, label: this.translateService.instant('enrollments.company') },
    { data: ENROLLMENT_VIA.CEVORA, label: this.translateService.instant('enrollments.cevora') }
  ];
  protected readonly ALL_INSTITUTION_VIA = [
    { data: ENROLLMENT_VIA.NO_COMPANY, label: this.translateService.instant('enrollments.noInstitution') },
    { data: ENROLLMENT_VIA.COMPANY, label: this.translateService.instant('enrollments.institution') }
  ];
  isInstitution = false;
  trainingPlanState: string;

  constructor(
    private formBuilder: FormBuilder,
    private branchService: BranchService,
    private translateService: TranslateService,
    private referenceDataService: ReferenceDataService
  ) {
    super();
    this.createFormGroup();
  }

  static createFormData(value: EnrollmentDetail): any {
    if (value) {
      return {
        enrollmentViaId: value.enrollment.enrollmentViaId ? value.enrollment.enrollmentViaId :
          (value.enrollment.branch ? ENROLLMENT_VIA.COMPANY : ENROLLMENT_VIA.NO_COMPANY),
        branch: value.enrollment.branch ? {
          branchId: value.enrollment.branch.data,
          name: value.enrollment.branch.label
        } : null,
        invoicingSame: !value.enrollment.invoiceName,
        invoiceName: value.enrollment.invoiceName,
        invoiceEmail: value.enrollment.invoiceEmail,
        invoiceAddress: {
          street: value.enrollment.invoiceStreet,
          city: value.enrollment.invoiceCity,
          houseNumber: value.enrollment.invoiceHouseNumber,
          postalCode: value.enrollment.invoicePostalCode,
          mailbox: value.enrollment.invoiceMailBox,
          country: value.enrollment.invoiceCountry
        },
        invoiceReference: value.enrollment.invoiceReference,
        trainingPlan: value.enrollment.trainingPlan
      }
    }
    else {
      return undefined;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['training']?.currentValue) {
      this.isInstitution = isTeacherProjectType(this.training.typeId) || isPupilProjectType(this.training.typeId);
      this.enrollmentViaOptions = this.isInstitution ? this.ALL_INSTITUTION_VIA : this.ALL_EMPLOYMENT_VIA;
      if (this.training.typeId === TRAINING_TYPE.CEVORA) {
        this.enrollmentViaOptions = this.enrollmentViaOptions.filter(option => option.data === ENROLLMENT_VIA.COMPANY);
      }
    }

    this.showEnrollmentViaReadOnly = !!this.enrollmentId || this.training?.typeId === TRAINING_TYPE.CEVORA;
  }

  override writeValue(value: any) {
    if (value) {
      this.enrollmentViaReadOnly = this.enrollmentViaOptions
        .filter(enrollmentVia => enrollmentVia.data === value.enrollmentViaId)[0].label;
      this.formGroup.setValue(value);
    }
    else {
      const resetValue = {
        invoicingSame: true,
        enrollmentViaId: (this.training?.typeId === TRAINING_TYPE.CEVORA || this.isInstitution) ? ENROLLMENT_VIA.COMPANY : ENROLLMENT_VIA.NO_COMPANY };
      this.formGroup.reset(resetValue);
      this.enrollmentViaReadOnly = this.enrollmentViaOptions
        .filter(enrollmentVia => enrollmentVia.data === resetValue.enrollmentViaId)[0].label;
    }
  }

  override isValid(): boolean {
    const valid = super.isValid();
    return (!this.addressComponent || this.addressComponent.isValid()) && valid;
  }

  validate() {
    return this.formGroup.valid ? null : { branchForm: { valid: false } };
  }

  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      enrollmentViaId: [ENROLLMENT_VIA.NO_COMPANY, Validators.required],
      branch: [null],
      invoicingSame: [true],
      invoiceName: [null],
      invoiceEmail: [null, emailPattern()],
      invoiceAddress: [],
      invoiceReference: [null],
      trainingPlan: [null],
    });

    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(delay(1)).subscribe((value) => {
        if (value.invoiceAddress) {
          value.invoiceCity = value.invoiceAddress.city;
          value.invoiceCountry = value.invoiceAddress.country;
          value.invoiceStreet = value.invoiceAddress.street;
          value.invoiceHouseNumber = value.invoiceAddress.houseNumber;
          value.invoiceMailbox = value.invoiceAddress.mailbox;
          value.invoicePostalCode = value.invoiceAddress.postalCode;
          delete value.invoiceAddress;
        }

        this.onChange(value);
        this.onTouched();
      })
    );

    this.subscriptions.push(
      this.formGroup.get('branch').valueChanges
        .subscribe((branch) => this.selectBranch(branch?.branchId))
    );
    this.setBranchRequiredIfEnrollmentViaBranch();
    this.setInvoiceFieldsRequiredIfNotTheSameAsBranch();
  }

  selectBranch(branchId: string) {
    this.isMaxOfCompany = false;

    this.formGroup.patchValue({
      trainingPlan: (this.formGroup.get("enrollmentViaId").value === ENROLLMENT_VIA.CEVORA || this.isInstitution) ? false : null
    });
    this.formGroup.get('trainingPlan').markAsPristine();
    this.formGroup.get('trainingPlan').markAsUntouched();

    this.cancelFetchBranch$?.next();
    this.trainingPlan = undefined;
    if (branchId) {
      this.cancelFetchBranch$ = new Subject();
      this.branchService
        .getBranch(branchId)
        .pipe(first(), takeUntil(this.cancelFetchBranch$))
        .subscribe(branch => {
          this.selectedBranch = branch;
          if (branch.isFood) {
            this.branchService.getTrainingPlans(branch.branchId).pipe(first())
              .subscribe(trainingPlans => {
                this.trainingPlan = trainingPlans[0];
                if (this.trainingPlan) {
                  this.referenceDataService.getTrainingPlanState(this.trainingPlan.stateId).pipe(first())
                    .subscribe(trainingPlanState => this.trainingPlanState = trainingPlanState.label);
                }
              });
          }
          else {
            this.formGroup.get("trainingPlan").setValue(false);
          }
        });

      this.branchService
        .getCurrentCountOfEnrollmentsForBranch(this.training.trainingId, branchId)
        .pipe(first(), takeUntil(this.cancelFetchBranch$))
        .subscribe(currentCount => {
          this.isMaxOfCompany =
            !this.training.maxParticipantsPerCompany || this.training.maxParticipantsPerCompany <= currentCount;
        });
    }
    else {
      this.selectedBranch = undefined;
    }
  }

  private setBranchRequiredIfEnrollmentViaBranch() {
    this.subscriptions.push(
      this.formGroup.get('enrollmentViaId').valueChanges.subscribe((newValue) => {
        const branch = this.formGroup.get('branch');

        if (newValue === ENROLLMENT_VIA.CEVORA) {
          branch.setValidators(Validators.required);
        }
        else if (newValue === ENROLLMENT_VIA.COMPANY) {
          branch.setValidators(Validators.required);
        }
        else {
          branch.clearValidators();
        }

        this.formGroup.get('trainingPlan').reset();
        branch.reset();
        branch.updateValueAndValidity();
        this._updateTrainingPlanValidation();
      }),
      this.formGroup.get("branch").valueChanges.subscribe(() => this._updateTrainingPlanValidation())
    );
  }

  private _updateTrainingPlanValidation(): void {
    const enrollmentVia = this.formGroup.get('enrollmentViaId').value;
    const branch = this.formGroup.get('branch').value;
    const trainingPlanControl = this.formGroup.get('trainingPlan');

    if (enrollmentVia === ENROLLMENT_VIA.COMPANY && !this.isInstitution && branch?.isFood === true) {
      trainingPlanControl.setValidators(Validators.required);
    }
    else {
      trainingPlanControl.clearValidators();
    }
    trainingPlanControl.updateValueAndValidity();
  }

  private setInvoiceFieldsRequiredIfNotTheSameAsBranch() {
    const invoicingSameFormField = this.formGroup.get('invoicingSame');

    if (invoicingSameFormField) {
      this.subscriptions.push(
        invoicingSameFormField.valueChanges.subscribe((invoicingSame: boolean) => {
          this.formGroup.get('invoiceName')?.setValidators(!invoicingSame ? Validators.required : []);
          this.formGroup.get('invoiceName')?.reset();
          this.formGroup.get('invoiceAddress')?.reset();
          this.formGroup.get('invoiceAddress')?.updateValueAndValidity();
        })
      );
    }
  }

  override setFocus(): void {
    setTimeout(() => this.focusElement?.focus());
  }
}
