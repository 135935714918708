<p-dialog
  (blur)="popupVisible = false"
  *ngIf='sessionForm'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '50rem' }"
  [closable]="false"
  [focusOnShow]='false'
  appendTo="body"
  header="{{
    trainingSession ? ((trainingSession.id ? 'trainings.sessions.edit' : 'trainings.sessions.new') | translate) : ''
  }}"
>
  <div *ngIf="trainingSession" [formGroup]="sessionForm">
    <alimento-ipv-frontend-form-field
      [formGroup]='sessionForm'
      [errorTemplate]='titleErrorTemplate'
      fieldKey='trainings.sessions.title'
      formField='title'
    >
      <input
        autofocus
        class="w-full"
        formControlName="title"
        id="title"
        pInputText
        placeholder="{{ 'trainings.sessions.titlePlaceholder' | translate }}"
        type="text"
      />
      <span class="text-grey-4 text-[12px]">{{ 'trainings.sessions.sessionTitleInfo' | translate }}</span>
      <ng-template #titleErrorTemplate>
        <small *ngIf="sessionForm.get('title')?.hasError('maxlength')">
          {{ 'trainings.sessions.titleMaxLength' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <div class="w-[202px]" *ngIf="sessionForm.contains('date')">
      <alimento-ipv-frontend-form-field
        [formGroup]='sessionForm'
        [errorTemplate]='dateErrorTemplate'
        [required]='isEventType(trainingType) || isExternOfferTraining()'
        formField='date'
        fieldKey='trainings.sessions.date'
      >
        <lib-date-picker
          formControlName="date"
          id="date"
        ></lib-date-picker>

        <ng-template #dateErrorTemplate>
          <small *ngIf='sessionForm.get("date")?.hasError("required")'>
            {{ 'trainings.sessions.dateRequired' | translate }}
          </small>
          <small *ngIf='sessionForm.get("date").hasError("invalidDate")'>
            {{ 'validation.invalidDate' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <div class="field">
      <div class="grid grid-cols-[130px_130px_200px] gap-x-4">
        <label class="block" for="startTime">
          {{ 'trainings.sessions.startTime' | translate }}
          <span *ngIf="isExternOfferTraining()">({{ 'validation.optional' | translate }})</span>
        </label>
        <label class="block" for="endTime">
          {{ 'trainings.sessions.endTime' | translate }}
          <span *ngIf="isExternOfferTraining()">({{ 'validation.optional' | translate }})</span>
        </label>
        <label class="block" for="duration">
          {{ 'trainings.sessions.duration' | translate }}
          <span *ngIf="isExternOfferTraining()">({{ 'validation.optional' | translate }})</span>
        </label>
        <alimento-ipv-frontend-time-picker
          id="startTime"
          [formControl]="$any(sessionForm.controls['startTime'])"
          [required]="!isExternOfferTraining()"
        ></alimento-ipv-frontend-time-picker>
        <alimento-ipv-frontend-time-picker
          id="endTime"
          [formControl]="$any(sessionForm.controls['endTime'])"
          [required]="!isExternOfferTraining()"
        ></alimento-ipv-frontend-time-picker>
        <alimento-ipv-frontend-time-picker
          id="duration"
          [formControl]="$any(sessionForm.controls['duration'])"
          [required]="!isExternOfferTraining()"
        ></alimento-ipv-frontend-time-picker>
      </div>

      <small
        *ngIf="
          (sessionForm.get('startTime')?.dirty || sessionForm.get('startTime')?.touched) &&
          sessionForm.get('startTime')?.hasError('required')
        "
        class="p-error block"
      >
        {{ 'trainings.sessions.startTimeRequired' | translate }}
      </small>
      <small
        *ngIf="
          (sessionForm.get('endTime')?.dirty || sessionForm.get('endTime')?.touched) &&
          sessionForm.get('endTime')?.hasError('required')
        "
        class="p-error block"
      >
        {{ 'trainings.sessions.endTimeRequired' | translate }}
      </small>
      <small *ngIf="sessionForm.get('endTime')?.hasError('minValueToLow')" class="p-error block">
        {{ 'trainings.sessions.endTimeToLow' | translate }}
      </small>

      <small
        *ngIf="
          (sessionForm.get('duration')?.dirty || sessionForm.get('duration')?.touched) &&
          sessionForm.get('duration')?.hasError('required')
        "
        class="p-error block"
      >
        {{ 'validation.isRequired' | translate : { field: 'trainings.sessions.duration' | translate } }}
      </small>
      <small *ngIf="sessionForm.get('duration')?.hasError('invalidDuration')" class="p-error block">
        {{ 'trainings.sessions.invalidDuration' | translate }}
      </small>
      <small *ngIf="sessionForm.get('duration')?.hasError('minDurationToLow')" class="p-error block">
        {{ 'trainings.sessions.durationToLow' | translate }}
      </small>
    </div>

    <div class='mb-4' *ngIf='sessionForm.contains("teacherBranches")'>
      <div *ngFor="let teacherBranchGroup of teacherBranches.controls; let i = index">
        <alimento-ipv-frontend-form-field
          [formGroup]='sessionForm'
          formGroupClass='!pb-2'
          fieldKey='trainings.sessions.teacherBranch'
        >
          <ng-container formArrayName='teacherBranches'>
            <div class='flex flex-col gap-2'>
              <div [formGroupName]="i" class='flex items-center justify-between'>
                <div class='flex-grow'>
                  <alimento-ipv-frontend-branch-select
                    formControlName='branch'
                    placeholder='trainings.sessions.teacherBranchPlaceholder'
                    [extraFilters]='[teacherFilter]'
                  ></alimento-ipv-frontend-branch-select>
                </div>
                <div class='basis-12'>
                  <button *ngIf="teacherBranches.controls.length > 1" (click)="deleteTeacherBranch(i)" class="p-2 group">
                    <i class="pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </alimento-ipv-frontend-form-field>

        <div class='conditional-fields !pb-4 !mb-2' *ngIf='trainingType !== TRAINING_TYPE.CEVORA &&
        teacherBranchGroup.value?.branch?.branchId &&
        !(teacherCount > 3 &&
        getTeachers($any(teacherBranchGroup))?.controls?.length === 0)'>

          <alimento-ipv-frontend-form-field
            [formGroup]='sessionForm'
            formGroupClass='!pb-2'
            *ngIf='teacherCount > 0 && getTeachers($any(teacherBranchGroup))?.controls?.length > 0'
            fieldKey='trainings.sessions.teacher'
          >
            <ng-container formArrayName='teacherBranches'>
              <div class='flex flex-col gap-2' [formGroupName]='i'>
                <div *ngFor="let teacherForm of getTeachers($any(teacherBranchGroup)).controls; let j = index" formGroupName='teachers'>
                  <div [formGroupName]="j" class='flex items-center justify-between'>
                    <div class='flex-grow'>
                      <alimento-ipv-frontend-employment-select
                        [formControl]='$any(teacherForm)'
                        [branchId]='teacherBranchGroup.value.branch?.branchId'
                      ></alimento-ipv-frontend-employment-select>
                    </div>
                    <div class='basis-12'>
                      <button (click)="deleteTeacher($any(teacherBranchGroup), j)" class="p-2 group">
                        <i class="pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </alimento-ipv-frontend-form-field>

          <div *ngIf='teacherCount < 4'
               class='flex items-center w-fit p-2 text-primary hover:bg-primary-hover' >
            <button (click)='addTeacher($any(teacherBranchGroup))'><i class='pi pi-plus mr-2'></i> {{'trainings.sessions.addTeacher' | translate}}</button>
          </div>

        </div>
      </div>
      <div *ngIf='teacherBranches.hasError("branchesUnique")' class='p-error block'>
        <small>{{'trainings.sessions.notUniqueTeacherBranches' | translate}}</small>
      </div>
      <div *ngIf='teacherBranches.hasError("teachersUnique")' class='p-error block'>
        <small>{{'trainings.sessions.notUniqueTeachers' | translate}}</small>
      </div>
      <div *ngIf='teacherBranches.controls.length < 4'
           class='flex items-center w-fit p-2 text-primary hover:bg-primary-hover' >
        <button (click)='addTeacherBranch()'><i class='pi pi-plus mr-2'></i> {{'trainings.sessions.addTeacherBranch' | translate}}</button>
      </div>
    </div>

    <ng-container *ngIf='sessionForm.contains("sessionMethodId")'>
      <alimento-ipv-frontend-form-field
        [formGroup]='sessionForm'
        formField='sessionMethodId'
        fieldKey='trainings.sessions.method'
        [required]='true'
      >
        <p-select
          *ngIf="methods$ | async as methods"
          [filter]="true"
          [options]="methods"
          [showClear]="true"
          appendTo="body"
          formControlName="sessionMethodId"
          id="sessionMethodId"
          optionLabel="label"
          optionValue="data"
          placeholder="{{ 'trainings.sessions.methodPlaceholder' | translate }}"
          styleClass="w-full"
        ></p-select>
      </alimento-ipv-frontend-form-field>

      <div class="conditional-fields" *ngIf="showLocationFields">
        <alimento-ipv-frontend-form-field *ngIf="sessionForm.contains('locationId')" [formGroup]='sessionForm'
                                          formField='locationId'
                                          fieldKey='trainings.sessions.location'
                                          [formInputTemplate]='locationTemplate'
        >
          <ng-template #locationTemplate>
            <alimento-ipv-frontend-branch-select
              formControlName='locationId'
              placeholder='trainings.sessions.locationPlaceholder'
              [extraFilters]='[locationFilter]'
            ></alimento-ipv-frontend-branch-select>
          </ng-template>
        </alimento-ipv-frontend-form-field>

        <div *ngFor="let sessionOption of includedSessionOptions$ | async; let i = index"
             class="field field-checkbox">
          <p-checkbox
            [formControl]="$any(sessionForm.controls['includedSessionOptionIds'])"
            inputId="included-option-{{ i }}"
            name="includeSessionOptionGroup"
            value="{{ sessionOption.data }}"
          ></p-checkbox>
          <label for='included-option-{{ i }}'>{{ sessionOption.label }}</label>
        </div>
      </div>

      <alimento-ipv-frontend-form-field
        *ngIf='sessionForm.contains("sessionComments")'
        [formGroup]='sessionForm'
        formField='sessionComments'
        fieldKey='trainings.sessions.sessionComments'
      >
      <textarea
        class="w-full"
        formControlName="sessionComments"
        id="sessionComments"
        pTextarea
        rows="2"
      ></textarea>
      </alimento-ipv-frontend-form-field>
    </ng-container>

    <div *ngIf='sessionForm.contains("siteVisitBranch")'>
      <alimento-ipv-frontend-form-field [formGroup]='sessionForm'
                                        [required]='true'
                                        formField='siteVisitBranch'
                                        fieldKey='trainings.sessions.siteVisitBranch'
      >
        <alimento-ipv-frontend-branch-select formControlName='siteVisitBranch'
        ></alimento-ipv-frontend-branch-select>
      </alimento-ipv-frontend-form-field>

      <div class='conditional-fields' *ngIf='sessionForm.get("siteVisitBranch")?.value'>
        <alimento-ipv-frontend-form-field [formGroup]='sessionForm'
                                          formField='siteVisitEmploymentId'
                                          fieldKey='trainings.sessions.siteVisitEmployment'
        >
          <alimento-ipv-frontend-employment-select [branchId]='$any(sessionForm.get("siteVisitBranch")?.value)?.branchId'
                                                   formControlName='siteVisitEmploymentId'
          ></alimento-ipv-frontend-employment-select>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>
  </div>

  <div *ngIf='showOutsideYearOfRequestWarning()' class='my-4 p-4 bg-grey-0 border-l-4 border-l-primary flex flex-col'>
    {{'trainings.sessions.sessionOutsideYearOfRequest' | translate}}
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        *ngIf="trainingSession"
        [loading]="loading"
        (onClick)="addOrUpdateSession()"
        label="{{ 'trainings.sessions.save' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        label="{{ 'trainings.sessions.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
