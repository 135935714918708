import { NgModule } from '@angular/core';
import { TrainingTemplateListComponent } from './pages/training-template-list/training-template-list.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { TrainingTemplateDetailComponent } from './pages/training-template-detail/training-template-detail.component';
import { ConfirmationService } from 'primeng/api';
import { SessionsModule } from '../sessions/sessions.module';
import { UtilsModule } from '../utils/utils.module';
import { TemplateCardComponent } from './components/template-card/template-card.component';
import { TemplateStatusChipComponent } from './components/template-status-chip/template-status-chip.component';
import {
  BulkExternalOfferPopupComponent
} from './components/bulk-external-offer-popup/bulk-external-offer-popup.component';
import { ExternalOfferCreateComponent } from './components/external-offer-create/external-offer-create.component';
import { BranchesModule } from '../branches/branches.module';

@NgModule({
  imports: [
    UiLibModule,
    UtilsModule,
    SessionsModule,
    BranchesModule
  ],
  providers: [ConfirmationService, CanDeactivateGuard],
  declarations: [
    TrainingTemplateListComponent,
    TrainingTemplateDetailComponent,
    TemplateCardComponent,
    TemplateStatusChipComponent,
    BulkExternalOfferPopupComponent,
    ExternalOfferCreateComponent
  ]
})
export class TrainingTemplatesModule {
}
