import { HttpParams } from '@angular/common/http';
import { booleanFilters, FilterType, SearchParam, SearchRequest, singleValueFilters } from '../types/search.type';

export class SearchMapper {

  public static getHttpParams(param: SearchRequest): HttpParams {
    let params: HttpParams = new HttpParams();

    params = param?.first ? params.append('off', param?.first) : params;
    params = param?.rows ? params.append('l', param?.rows) : params;

    params = param?.sortField ? params.append('s', param?.sortField) : params;
    params = param?.sortField ? params.append('o', param?.sortOrder === 1 ? 'asc' : 'desc') : params;

    (param.filters || [])
      .forEach((filter: any) => params = params.append(filter.type + '[]', filter.values));

    return params;
  }

  public static getParams(param: SearchRequest): SearchParam {
    const resultParams: SearchParam = {};

    if (param.first) {
      resultParams.offset = param.first;
    }
    if (param.rows) {
      resultParams.limit = param.rows;
    }
    if (param.sortField) {
      resultParams.sort = param.sortField;
      resultParams.order = param.sortOrder === 1 ? 'asc' : 'desc';
    }

    (param.filters || [])
      .forEach((filter: any) => resultParams[filter.type] = filter.values);

    if (resultParams[FilterType.fromDate]) {
      resultParams[FilterType.fromDate] = {
        criteria: 'After',
        date: resultParams[FilterType.fromDate][0]
      };
    }

    if (resultParams[FilterType.toDate]) {
      resultParams[FilterType.toDate] = {
        criteria: 'Before',
        date: resultParams[FilterType.toDate][0]
      };
    }

    new Set([...singleValueFilters, ...booleanFilters])
      .forEach(filter => {
        if (resultParams[filter]?.length === 1) {
          resultParams[filter] = resultParams[filter][0];
        }
        else if (resultParams[filter]?.length > 1) {
          delete resultParams[filter];
        }
      });

    booleanFilters.forEach(booleanFilter => {
      if (resultParams[booleanFilter]) {
        resultParams[booleanFilter] = resultParams[booleanFilter] === 'true' || resultParams[booleanFilter] === true;
      }
    });

    return resultParams;
  }

  public static getGETParams(param: SearchRequest): SearchParam {
    const resultParams: any = {};

    if (param.first) {
      resultParams.off = param.first;
    }
    if (param.rows) {
      resultParams.l = param.rows;
    }
    if (param.sortField) {
      resultParams.s = param.sortField;
      resultParams.o = param.sortOrder === 1 ? 'asc' : 'desc';
    }

    (param.filters || [])
      .forEach((filter: any) => resultParams[filter.type] = filter.values);

    if (resultParams[FilterType.search]) {
      resultParams[FilterType.searchShort] = resultParams[FilterType.search];
      delete resultParams[FilterType.search]
    }

    return resultParams;
  }
}
