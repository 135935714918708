import { Route } from '@angular/router';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import { EnterpriseListComponent } from './pages/enterprise-list/enterprise-list.component';
import { EnterpriseDetailComponent } from './pages/enterprise-detail/enterprise-detail.component';

export const EnterprisesRoutes: Route[] = [
  {
    path: '',
    component: EnterpriseListComponent,
    data: { title: 'titles.enterprises.list' }
  },
  {
    path: 'create',
    component: EnterpriseDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    pathMatch: 'full',
    data: { title: 'titles.enterprises.create' }
  },
  {
    path: ':enterpriseId',
    component: EnterpriseDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.enterprises.detail' }
  },
];
