import { ExternalOfferComponent } from './pages/external-offer/external-offer.component';
import { Route } from '@angular/router';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import {
  TrainingTemplateDetailComponent
} from '../training-templates/pages/training-template-detail/training-template-detail.component';
import { TEMPLATE_TYPE } from '../types/training-template.enum';

export const externalOfferRoutes: Route[] = [
  {
    path: '',
    component: ExternalOfferComponent,
    data: { title: 'titles.externalOffer.list' }
  },
  {
    path: 'templates/create',
    component: TrainingTemplateDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.externalOffer.create', templateType: TEMPLATE_TYPE.EXTERNAL_OFFER }
  },
  {
    path: 'templates/:templateId',
    component: TrainingTemplateDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.externalOffer.templateDetail', templateType: TEMPLATE_TYPE.EXTERNAL_OFFER }
  },
];
