<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [closable]="false"
  appendTo="body"
  header="{{
    (reimbursement?.reimbursementId ? 'reimbursements.editReimbursement' : 'reimbursements.new') | translate
  }}"
>
  <div *ngIf="reimbursement" [formGroup]="formGroup">

    <div class='conditional-fields pb-4 grid grid-cols-3 gap-4'>
      <alimento-ipv-frontend-metadata-item [header]='"trainings.participantsCount"'
                                           [value]='trainingData.nrOfParticipants + ""'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item [header]='"trainings.totalCost"'
                                           [value]='trainingData.sumTotalAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="extraInfo.trainingReimbursementMaxAmount"
                                           [header]='"trainings.trainingReimbursementMaxAmount"'
                                           [value]='extraInfo.trainingReimbursementMaxAmount > 999999 ? "-" :
                                           (extraInfo.trainingReimbursementMaxAmount | currency:"EUR")'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item [header]='"trainings.totalPreparationCost"'
                                           [value]='trainingData.sumPreparationAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="extraInfo.preparationReimbursementMaxAmount"
                                           [header]='"trainings.preparationReimbursementMaxAmount"'
                                           [value]='extraInfo.preparationReimbursementMaxAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item [header]='"trainings.smeBonusBudget"'
                                           *ngIf='trainingData.smeBalance'
                                           [value]='trainingData.smeBalance | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="extraInfo.smeBonusReimbursementAmount"
                                           [header]='"trainings.smeBonusReimbursementAmount"'
                                           [value]='extraInfo.smeBonusReimbursementAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item [header]='"trainings.smeBonusDate"'
                                           *ngIf='trainingData.smeBonusValidFrom'
                                           [value]='(trainingData.smeBonusValidFrom | date:"dd/MM/yyyy") + " - " + (trainingData.smeBonusValidUntil | date:"dd/MM/yyyy")'
      ></alimento-ipv-frontend-metadata-item>
    </div>


    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      formField='reimbursementTypeId'
                                      fieldKey='reimbursements.type'
    >
      <span class='read-only-field' *ngIf='reimbursement?.reimbursementId'>{{type}}</span>
      <p-select
        *ngIf='!reimbursement?.reimbursementId'
        [options]='allowedTypes$ | async'
        [showClear]='true'
        [autofocus]='true'
        formControlName='reimbursementTypeId'
        id='reimbursementTypeId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      *ngIf='formGroup.get("reimbursementTypeId").value === REIMBURSEMENT_TYPE.TRANSITION'
                                      fieldKey='reimbursement.transition'
                                      formField='transitionId'
    >
      <div *ngIf='reimbursement?.reimbursementId && selectedTransition'>
        <ng-container *ngTemplateOutlet='transitionTemplate; context: {transition: selectedTransition}'></ng-container>
      </div>
      <p-select
        *ngIf='!reimbursement?.reimbursementId'
        [options]="extraInfo.reimbursementTransitions"
        appendTo="body"
        formControlName="transitionId"
        id="transitionId"
        optionValue="transitionId"
        styleClass="w-full"
        placeholder="{{ 'enrollments.chooseOption' | translate }}"
        [showClear]="true"
      >
        <ng-template let-item pTemplate="selectedItem">
          <div class='mr-8'>
            <ng-container *ngTemplateOutlet='transitionTemplate; context: {transition: item}'></ng-container>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <ng-container *ngTemplateOutlet='transitionTemplate; context: {transition: item}'></ng-container>
        </ng-template>
      </p-select>

      <ng-template #transitionTemplate let-item="transition">
        <div class='flex items-center justify-between gap-4'>
          <div class='flex flex-col'>
            <span>{{item.transitionPersonName}}</span>
            <span class='text-grey-3'>{{getTransitionType(item.transitionTypeId) | async}}</span>
          </div>
          <div class='flex flex-col items-end'>
            <span>{{'persons.transitions.totalBudget' | translate}}: {{item.transitionBalance | currency:'EUR'}}</span>
            <span>{{'reimbursements.maxAmount' | translate}}: {{item.reimbursementMaxAmount | currency:'EUR'}}</span>
          </div>
        </div>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      [errorTemplate]='amountErrorTemplate'
                                      fieldKey='reimbursements.amount'
                                      formField='amount'>
      <p-inputNumber #numberControl
                     inputStyleClass='w-full'
                     formControlName='amount'
                     [maxFractionDigits]='2'
                     (onKeyDown)='onKeyDown($event, numberControl)'
                     mode="currency"
                     currency="EUR"
                     locale='en-US'
                     id='amount'>
      </p-inputNumber>

      <ng-template #amountErrorTemplate>
        <small *ngIf="formGroup.get('amount')?.hasError('max')">
          {{ 'validation.maxValue' | translate : { number: getMaxAmount() | currency:"EUR"} }}
        </small>
        <small *ngIf="formGroup.get('amount')?.hasError('min')">
          {{ 'validation.minValue' | translate : { number: 0 | currency:"EUR"} }}
        </small>
        <small *ngIf="formGroup.get('amount')?.hasError('required')">
          {{
            'validation.isRequired'
              | translate : { field: 'reimbursements.amount' | translate }
          }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='remarks'
      fieldKey='reimbursements.internalRemarks'
    >
      <textarea
        class="w-full"
        formControlName="remarks"
        id="remarks"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='externalRemarks'
      fieldKey='reimbursements.externalRemarks'
    >
      <textarea
        class="w-full"
        formControlName="externalRemarks"
        id="externalRemarks"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        *ngIf="reimbursement"
        [loading]="loading"
        (onClick)="addOrUpdateReimbursement()"
        label="{{ ( reimbursement.reimbursementId ? 'reimbursements.edit' : 'reimbursements.create') | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        label="{{ 'reimbursements.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
