import { Route } from '@angular/router';
import { TrainingListComponent } from './pages/training-list/training-list.component';
import { TrainingDetailComponent } from './pages/training-detail/training-detail.component';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import { CevoraTrainingDetailComponent } from './pages/cevora-training-detail/cevora-training-detail.component';
import { TRAINING_TYPE } from '../types/reference-data.enum';
import {
  ReimbursementRequestTrainingDetailComponent
} from './pages/reimbursement-request-training-detail/reimbursement-request-training-detail.component';
import { TRAINING_PROJECT_AUDIENCE } from '../types/training.enum';
import {
  ExternalOfferTrainingDetailComponent
} from './pages/external-offer-training-detail/external-offer-training-detail.component';

export const trainingsRoutes: Route[] = [
  {
    path: '',
    component: TrainingListComponent,
    data: { title: 'titles.trainings.list' }
  },
  {
    path: 'open',
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainings.create',
          trainingType: TRAINING_TYPE.OPEN_TRAINING
        }
      },
      {
        path: ':trainingId',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainings.detail',
          trainingType: TRAINING_TYPE.OPEN_TRAINING
        }
      }
    ]
  },
  {
    path: 'cevora',
    children: [
      {
        path: 'create',
        component: CevoraTrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.cevoraTrainings.create',
          trainingType: TRAINING_TYPE.CEVORA,
        }
      },
      {
        path: ':trainingId',
        component: CevoraTrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.cevoraTrainings.detail',
          trainingType: TRAINING_TYPE.CEVORA,
        }
      }
    ]
  },
  {
    path: 'openextern/:trainingId',
    component: ReimbursementRequestTrainingDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      title: 'titles.reimbursementRequests.training',
      trainingType: TRAINING_TYPE.OPEN_EXTERN
    }
  },
  {
    path: 'custom/:trainingId',
    component: ReimbursementRequestTrainingDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      title: 'titles.reimbursementRequests.training',
      trainingType: TRAINING_TYPE.CUSTOM
    }
  },
  {
    path: 'training-project-pupil',
    data: {
      audience: TRAINING_PROJECT_AUDIENCE.PUPIL,
      trainingType: TRAINING_TYPE.PUPIL_GENERAL
    },
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingProjectPupil.create',
        }
      },
      {
        path: ':trainingId',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],

        data: {
          title: 'titles.trainingProjectTeacher.detail',
        }
      }
    ]
  },
  {
    path: 'training-project-teacher',
    data: {
      audience: TRAINING_PROJECT_AUDIENCE.TEACHER,
      trainingType: TRAINING_TYPE.TEACHER_GENERAL
    },
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingProjectPupil.create',
        }
      },
      {
        path: ':trainingId',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingProjectTeacher.detail',
        }
      }
    ]
  },
  {
    path: 'training-event',
    data: {
      trainingType: TRAINING_TYPE.EDUCATION_TYPE_STUB,
    },
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingEvent.create',
        }
      },
      {
        path: ':trainingId',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingEvent.detail',
        }
      }
    ]
  },

  {
    path: 'external-offer-openextern/:trainingId',
    component: ExternalOfferTrainingDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      title: 'titles.reimbursementRequests.training',
      trainingType: TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL
    }
  },
  {
    path: 'external-offer-custom/:trainingId',
    component: ExternalOfferTrainingDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      title: 'titles.reimbursementRequests.training',
      trainingType: TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND
    }
  },
];
