import { Component, EventEmitter, Output } from '@angular/core';
import {
  FilterType,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  SearchFilter,
  SearchFilterType,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { Observable } from 'rxjs';
import { SearchesService } from '../../../services/searches.service';
import { SearchEnterpriseItem } from '../../../types/enterprise.type';
import { LinkEnterpriseEvent } from '@alimento-ipv-frontend/application-lib';

@Component({
  selector: 'alimento-ipv-frontend-link-enterprise-popup',
  templateUrl: './link-enterprise-popup.component.html',
  standalone: false
})
export class LinkEnterprisePopupComponent {
  search$: InfiniteScrollDataAdapter<SearchEnterpriseItem>;
  searchFilters: SearchFilter[] = [];
  dialogVisible = false;
  enterprise: SearchEnterpriseItem;
  currentSelection: SearchEnterpriseItem;

  loading = false;
  disabled = false;

  @Output()
  enterpriseSelected = new EventEmitter<LinkEnterpriseEvent>();

  constructor(private searchesService: SearchesService) {
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    // searchRequest.filters.push({
    //   type: FilterType.filterBranch,
    //   values: [this.enterpriseId]
    // });

    this.search$ = new InfiniteScrollDataAdapter<SearchEnterpriseItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<SearchEnterpriseItem>> => {
      return this.searchesService.searchEnterprises(searchRequest);
    }, searchRequest, true);

    this.searchFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      }
    ];
  }

  openDialog(): void {
    this.dialogVisible = true;
    this._createSearch();
    this.currentSelection = undefined;
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.currentSelection = undefined;
  }

  linkEnterprise(): void {
    if (this.currentSelection) {
      this.enterpriseSelected.emit({
        data: {
          enterpriseId: this.currentSelection.enterpriseId
        },
        setLoading: (state: boolean) => this.loading = state
      });
    }
  }

  selectionChange(trainingPrograms: any[]): void {
    this.currentSelection = trainingPrograms[0];
  }
}
