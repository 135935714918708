<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                     [readonly]='readOnly'
                                     [linkedEntity]='trainingId'
                                     [type]='TrainingNote'
                                     (hasChanges)='notesHasChanges = $event'
                                     (countUpdated)='loadNotesCount()'
></alimento-ipv-frontend-notes-sidebar>

<div [formGroup]="trainingForm" *ngIf='reimbursementRequest()'>
  <alimento-ipv-frontend-detail-page-layout
    [header]="title"
    fallbackUrl='/trainings'
    [breadcrumb]='breadcrumb'
  >
    <ng-container status>
      <alimento-ipv-frontend-training-status-chip
        *ngIf='trainingId'
        [status]="trainingStatus"
        [trainingOngoing]="isOngoing()"
      ></alimento-ipv-frontend-training-status-chip>
    </ng-container>

    <ng-container top-action>
      <div class="flex gap-4" *ngIf='!readOnly'>
        <p-button
          label="{{ 'trainings.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveTraining()"
          [loading]="savingTraining"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-training
        [hidden]="activeTabIndex !== 0"
        [reimbursementRequest]='reimbursementRequest()'
        [firstSessionDate]='firstSessionDate'
        [lastSessionDate]='lastSessionDate'
        [contactPersons]='contactPersons'
        [isGroupContactPerson]='isGroupContactPerson'
        [selectedContactPersons]='selectedContactPersons'
        formControlName="basicDataForm"
        #basicTrainingComponent
      >
      </alimento-ipv-frontend-basic-training>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 1"
        [trainingId]="trainingId"
        [trainingStatus]="trainingStatus"
        [trainingType]='TRAINING_TYPE.OPEN_EXTERN'
        [trainingMethodId]='training()?.methodId'
        [defaultTeacherBranchId]='training()?.teacherBranchId'
        [yearOfRequest]='reimbursementRequest().year'
        (sessionUpdated)='loadTrainingData()'
      >
      </alimento-ipv-frontend-sessions>

      <alimento-ipv-frontend-enrollments
        [hidden]="activeTabIndex !== 2"
        [training]="$any(training)"
        [trainingStatus]="trainingStatus"
        [trainingType]="type"
        [groupId]='enterprise()?.enterpriseGroupId'
        [enterpriseId]='enterprise()?.enterpriseId'
        [reimbursementRequest]='reimbursementRequest()'
        [trainingStartDate]='trainingStartDate()'
        [activeEnrollmentId]='activeEnrollmentId'
        (dataChanged)='loadTrainingData()'
      ></alimento-ipv-frontend-enrollments>

      <alimento-ipv-frontend-training-communications
        *ngIf='activeTabIndex === 3'
        [hidden]='activeTabIndex !== 3'
        [readOnly]='readOnly'
        [training]='$any(training())'
      ></alimento-ipv-frontend-training-communications>

      <div [hidden]='activeTabIndex !== 4'>
        <alimento-ipv-frontend-financial-documents-overview [readOnly]='readOnly'
                                                            [financialDocuments]='financialDocuments'
                                                            [trainingId]='trainingId'
                                                            [trainingData]='trainingData'
                                                            (changes)='financialDocumentsChanged()'
        ></alimento-ipv-frontend-financial-documents-overview>
      </div>

      <div [hidden]='activeTabIndex !== 5'>
        <alimento-ipv-frontend-reimbursements-overview [readOnly]='readOnly'
                                                       [reimbursements]='reimbursements'
                                                       [trainingId]='trainingId'
                                                       [trainingData]='trainingData'
                                                       [selectedContactPersons]='selectedContactPersons'
                                                       (changes)='reimbursementsChanged()'
        ></alimento-ipv-frontend-reimbursements-overview>
      </div>

      <alimento-ipv-frontend-invoices-overview *ngIf='activeTabIndex === 6'
                                               [readOnly]='readOnly'
                                               [trainingId]='trainingId'
                                               [trainingType]='training().typeId'
                                               [hasCounselor]='!!training()?.counselorId'
                                               [purchaseEntries]='purchaseEntries'
                                               [salesInvoices]='salesInvoices'
                                               (purchaseEntryChanges)='getPurchaseEntries()'
                                               (salesInvoiceChanges)='getSalesInvoices()'
      >
      </alimento-ipv-frontend-invoices-overview>
    </ng-container>

    <ng-container metadata>

      <div *ngIf='!readOnly && (trainingStatus === trainingStatusKeys.Cancelled || trainingStatus === trainingStatusKeys.Completed)'
           class='flex flex-col gap-4'>
        <alimento-ipv-frontend-reactivate-training-dialog
          #reactivateTrainingDialog
          [training]='$any(training)'
          [firstSessionDate]='firstSessionDate'
          (reactivateTrainingConfirmed)="reactivateTraining($event)"
        >
        </alimento-ipv-frontend-reactivate-training-dialog>

        <p-button
          label="{{ 'trainings.actions.reactivate' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="reactivatingTraining"
          (click)="reactivateTrainingDialog.open()"
        ></p-button>

      </div>

      <div *ngIf='!readOnly && trainingStatus !== trainingStatusKeys.Draft' class='flex flex-col gap-4'>
        <alimento-ipv-frontend-close-training-dialog
          #closeTrainingDialog
          [training]='$any(training)'
          [firstSessionDate]='firstSessionDate'
          (closeTrainingConfirmed)="closeTraining($event)"
        >
        </alimento-ipv-frontend-close-training-dialog>

        <p-button
          *ngIf='trainingStatus === trainingStatusKeys.Active'
          label="{{ 'trainings.actions.close' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="closingTraining"
          (click)="closeTrainingDialog.open()"
        ></p-button>

        <div *ngIf='sessionService.nrOfSessions() > 0 && enrollmentService.enrollments()?.length > 0'
             class='flex items-center justify-between gap-2 w-full'>
          <p-button label="{{ 'trainings.actions.completeAllAbsences' | translate }}"
                    [link]='true'
                    styleClass='whitespace-nowrap pl-0'
                    (onClick)='!completeAllAbsences.disabled ? completeAllAbsences.writeValue(!completeAllAbsences.checked()) : undefined'
          ></p-button>
          <p-toggle-switch #completeAllAbsences [formControl]="completeAllAbsencesToggle"
          ></p-toggle-switch>
        </div>

        <div *ngIf='trainingId'>
          <alimento-ipv-frontend-clone-training-dialog
            [training]='$any(training())'
            [firstSessionDate]='firstSessionDate'
            (cloneTraining)="cloneTraining($event)"
          >
          </alimento-ipv-frontend-clone-training-dialog>
          <alimento-ipv-frontend-move-training-dialog
            [training]='$any(training())'
            [firstSessionDate]='firstSessionDate'
            [enterprise]='enterprise()'
            [branch]='branch'
            (moveTraining)="moveTraining($event)"
          >
          </alimento-ipv-frontend-move-training-dialog>

          <alimento-ipv-frontend-detail-actions label='trainings.actions.extra'
                                                [generateItems]='extraActions'
                                                (onClick)='setExtraActions()'
          ></alimento-ipv-frontend-detail-actions>
        </div>

        <div *ngIf='trainingStatus === trainingStatusKeys.Active && trainingId && !isOngoing()'>
          <alimento-ipv-frontend-cancel-training-dialog
            #cancelTrainingDialog
            [training]='$any(training())'
            [firstSessionDate]='firstSessionDate'
            (cancelTrainingConfirmed)="cancelTraining($event, cancelTrainingDialog)"
          >
          </alimento-ipv-frontend-cancel-training-dialog>

          <p-button styleClass='px-0 text-danger '
                    label="{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}"
                    [link]='true'
                    [pTooltip]="isOngoing() ? ('trainings.firstSessionAlreadyOver' | translate) : ''"
                    (onClick)='cancelTrainingDialog.open()'
          ></p-button>
        </div>
      </div>

      <span class="h-[1px] bg-grey-2" *ngIf="trainingId"></span>

      <alimento-ipv-frontend-metadata-item header='reimbursementRequests.request'
                                           *ngIf='reimbursementRequest()'
                                           [value]='reimbursementRequest()?.year + " " + branch?.branchName'
                                           [link]="['/reimbursement-requests', reimbursementRequestId]"
                                           [isLink]='true'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item header='reimbursementRequests.branch'
                                           *ngIf='branch'
                                           [value]='branch?.branchName'
                                           [link]="['/branches', branch.branchId]"
                                           [isLink]='true'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="reasonCancelled"
                                           header="trainings.reasonCancelled"
                                           value="{{ reasonCancelled }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.cancelledComment"
                                           header="trainings.cancelledComment"
                                           value="{{ training().cancelledComment }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="reimbursementRequest()?.year"
                                           header='reimbursementRequests.yearOfReimbursement'
                                           [value]='"" + reimbursementRequest().year'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().totalDuration"
                                           header='trainings.totalDuration'
                                           [value]='trainingData().totalDuration'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().sumTotalAmount"
                                           header='trainings.totalCost'
                                           [value]='trainingData().sumTotalAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().sumPreparationAmount"
                                           header='trainings.totalPreparationCost'
                                           [value]='trainingData().sumPreparationAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().totalAmountToPayout"
                                           header='trainings.totalAmountToPayout'
                                           [value]='trainingData().totalAmountToPayout | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.createdBy"
                                           header="trainings.createdBy"
                                           [value]="training().createdBy"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.createdOn"
                                           header="trainings.createdOn"
                                           [value]="training().createdOn | date : 'dd/MM/yyyy'"
      ></alimento-ipv-frontend-metadata-item>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
