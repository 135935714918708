<alimento-ipv-frontend-overview-page-layout header='branches.menuTitle'>
  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [sortItems]='sortFilters'
              [cardTemplate]='cardTemplate'
              name='branches'
              searchPlaceholderKey='branches.searchListPlaceholder'
              availableResultsTranslateKey='branches.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-branch-search-card
        [branchListItem]='item'
        [routerLink]='["/branches", item.branchId]'
        tabindex="0"
      ></alimento-ipv-frontend-branch-search-card>
    </ng-template>
  </lib-search>
</alimento-ipv-frontend-overview-page-layout>
