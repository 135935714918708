import { Inject, Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Branch, ContactPerson, TrainingPlan } from '../types/branch.type';
import { APP_CONFIG_TOKEN, AppConfig, HISTORY_TYPE, HistoryItem } from '@alimento-ipv-frontend/ui-lib';
import { TRAINING_PLAN_ACTION, TRAINING_PLAN_STATE } from '../types/branch.enum';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  private _branchCache: { [key: string]: Observable<Branch> } = {};

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getBranch(branchId: string): Observable<Branch> {
    if (!this._branchCache[branchId]) {
      this._branchCache[branchId] = this.http.get<Branch>(`${this.config.readApiUrl}/branch/${branchId}`).pipe(shareReplay(1));
    }
    return this._branchCache[branchId];
  }

  getContactPersons(branchId: string): Observable<ContactPerson[]> {
    return this.http
      .get<ContactPerson[]>(`${this.config.readApiUrl}/branch/${branchId}/contactpersons`);
  }

  getCurrentCountOfEnrollmentsForBranch(trainingId: string, branchId: string): Observable<number> {
    return this.http.get<number>(`${this.config.readApiUrl}/enrollments/count`, {
      params: {
        branchId: branchId,
        trainingId: trainingId
      }
    });
  }

  getTrainingPlans(branchId: string): Observable<TrainingPlan[]> {
    const order = [
      TRAINING_PLAN_STATE.REQUESTED,
      TRAINING_PLAN_STATE.RECEIVED,
      TRAINING_PLAN_STATE.APPROVED,
      TRAINING_PLAN_STATE.REJECTED
    ];
    return this.http.get<TrainingPlan[]>(`${this.config.readApiUrl}/trainingplans`, {
      params: {
        branchId: branchId
      }
    })
      .pipe(map(plans => {
        return plans.sort((a, b) => {
          if (!a.validFrom) {
            return -1;
          }
          else if (!b.validFrom) {
            return 1;
          }
          else {
            const dateDiff = new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime();
            if (dateDiff === 0) {
              return order.indexOf(a.stateId) - order.indexOf(b.stateId);
            }
            else {
              return dateDiff;
            }
          }
        });
      }));
  }

  createBranch(branch: Branch): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/branches`, branch);
  }

  updateBranch(branch: Branch): Observable<{ id: string }> {
    this._clearCache(branch.branchId);
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/branches/${branch.branchId}`, branch);
  }

  linkEnterprise(branchId: string, data: {enterpriseId: string}): Observable<void> {
    this._clearCache(branchId);
    return this.http.put<void>(`${this.config.writeApiUrl}/branches/${branchId}/linkenterprise`, data);
  }

  createTrainingPlan(branchId: string, formData: any): Observable<{ id: string }> {
    formData.append('branchId', branchId);
    this._clearCache(branchId);
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/register`, formData);
  }

  requestTrainingPlan(branchId: string, trainingPlan: any): Observable<{ id: string }> {
    trainingPlan.branchId = branchId;
    this._clearCache(branchId);
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/request`, trainingPlan);
  }

  updateTrainingPlan(trainingPlanId: string, branchId: string, formData: any): Observable<{ id: string }> {
    formData.append('branchId', branchId);
    this._clearCache(branchId);
    return this.http.put<string>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}`, formData)
      .pipe(map(id => ({ id: id })));
  }

  removeTrainingPlan(trainingPlanId: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}`);
  }

  sendTrainingPlanReminder(trainingPlanId: string, data?: any): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}/reminder`, data);
  }

  approveTrainingPlan(trainingPlanId: string, data: any): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}/approve`, data);
  }

  rejectTrainingPlan(trainingPlanId: string, data: any): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}/reject`, data);
  }

  registerTrainingPlan(trainingPlanId: string, data: any): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}/register`, data);
  }

  getTrainingPlanHistory(trainingPlanId: string): Observable<HistoryItem[]> {
    return this.http.get<{
      actions: HistoryItem[]
    }>(`${this.config.readApiUrl}/trainingplans/${trainingPlanId}/actions`)
      .pipe(map(results => {
        results.actions.forEach(historyItem => {
          historyItem.historyType = HISTORY_TYPE.TRAINING_PLAN;
          historyItem.actionName = TRAINING_PLAN_ACTION[historyItem.actionId];
        });
        return results.actions;
      }));
  }

  setAsMainBranch(branchId: string): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}/branches/${branchId}/main`, {});
  }

  private _clearCache(branchId: string): void {
    delete this._branchCache[branchId];
  }
}
