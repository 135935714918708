import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { TrainingTemplateSearchItem } from '../../../types/training-template.type';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'alimento-ipv-frontend-external-offer-card',
  templateUrl: './external-offer-card.component.html',
  standalone: false
})
export class ExternalOfferCardComponent implements OnChanges {
  @Input()
  template: TrainingTemplateSearchItem;

  rubric: string;
  actions: MenuItem[];

  constructor(private referenceDataService: ReferenceDataService,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['template']?.currentValue) {
      if (this.template.rubricId) {
        this.referenceDataService.getTrainingRubric(this.template.rubricId).pipe(first())
          .subscribe(rubric => this.rubric = rubric.label);
      }
    }
  }

  navigateToTemplate(event?: MouseEvent): void {
    const path = ['/external-offer/templates', this.template.trainingTemplateId];
    if (event?.ctrlKey) {
      window.open(path.join('/'), '_blank');
    }
    else {
      this.router.navigate(path);
    }
  }

}
