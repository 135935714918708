import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PurchaseEntryReference } from '../types/training.type';
import { SimpleBranch } from '../types/branch.type';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';

@Injectable({
  providedIn: 'root'
})
export class PurchaseReferencesService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getPurchaseReferences(trainingId: string): Observable<PurchaseEntryReference[]> {
    return this.http.get<PurchaseEntryReference[]>(`${this.config.readApiUrl}/purchasereferences`, {
      params: {
        trainingId: trainingId
      }
    });
  }

  getPurchaseReferenceBranches(trainingId: string): Observable<SimpleBranch[]> {
    return this.http.get<SimpleBranch[]>(`${this.config.readApiUrl}/trainings/${trainingId}/branches`, {
      params: {
        noPurchaseReferences: true
      }
    });
  }

  createPurchaseReferences(trainingId: string, branchIds: string[]): Observable<void> {
     return this.http.post<void>(`${this.config.writeApiUrl}/purchasereferences`, {
       trainingId: trainingId,
       branchIds: branchIds
     });
  }
}
