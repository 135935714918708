<alimento-ipv-frontend-overview-page-layout header='enterprises.menuTitle'>
  <ng-container top-right>
    <p-button
      label="{{ 'enterprises.actions.add' | translate }}"
      [routerLink]="['create']"
    ></p-button>
  </ng-container>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              name='enterprises'
              searchPlaceholderKey='enterprises.searchPlaceholder'
              availableResultsTranslateKey='enterprises.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-enterprise-card
        [enterprise]='item'
        [routerLink]='["/enterprises", item.enterpriseId]'
        tabindex="0"
      ></alimento-ipv-frontend-enterprise-card>
    </ng-template>
  </lib-search>
</alimento-ipv-frontend-overview-page-layout>
