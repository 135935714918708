import { ENROLLMENT_RESULT_ID, ENROLLMENT_VIA, EnrollmentRequestActionEnum } from './enrollment.enum';
import { DataLabelType, EducationLevel, PersonWorkStatus } from './reference-data.type';
import {
  EnrollmentRequestStatusKey,
  EnrollmentStatusKey,
  MailType,
  TRAINING_TYPE,
  TrainingStatusKey
} from './reference-data.enum';

export type CancelEnrollment = {
  enrollmentId: string;
  reasonCancelledId: string;
  cancelledComment: string;
};

export type EnrollmentAction = {
  action: EnrollmentActionEnum;
  enrollment: EnrollmentListItem;
};

export enum EnrollmentActionEnum {
  create,
  view,
  edit,
  cancel,
  addToReserve,
  reEnroll,
  navigateToPerson,
  navigateToBranch
}

export type EnrollmentBody = {
  enrollment: {
    branchId?: string;
    invoicingSame: boolean;
    invoiceName?: string;
    invoiceStreet?: string;
    invoiceHouseNumber?: string;
    invoiceMailBox?: string;
    invoiceCity?: string;
    invoicePostalCode?: string;
    invoiceEmail?: string;
    invoiceReference?: string;
    restructuringOrDismissal: string;
    outsideWorkingHours: boolean;
    recuperated: boolean;
    isContact: boolean;
    contactPerson: string;
    contactEmail: string;
    contactPhoneNumber: string;
    contactResponsibilities?: string[];
    trainingPlan: boolean;
    comments?: string;
    cost: number;
    workStatus: string;
    interimOfficeId?: string;
    interimOfficeContactFirstName?: string;
    interimOfficeContactLastName?: string;
    interimOfficeContactEmail?: string
    interimOfficeName?: string;
    interimOfficeStreet?: string;
    interimOfficeNumber?: string;
    interimOfficeMailbox?: string;
    interimOfficePostalCode?: string;
    interimOfficeCity?: string;
    interimOfficeCountry?: string;
  };
  employment: {
    employmentId?: string;
    function?: string;
    email: string;
    phoneNumber: string;
  };
  person: {
    personId: string;
    personAlimentoId: number;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: Date;
    language?: string;
    nationality: string;
    educationLevel?: EducationLevel;
    nationalIdentificationNumber?: string;
    phoneNumber?: string;
    email?: string;
    street?: string;
    houseNumber?: string;
    mailbox?: string;
    postalCode?: string;
    city?: string;
    country?: string;
  };
};

export type EnrollmentDetail = {
  enrollment: {
    enrollmentId: string,
    enrollmentViaId: ENROLLMENT_VIA,
    branch: DataLabelType,
    invoiceName?: string,
    invoiceStreet?: string,
    invoiceHouseNumber?: string,
    invoiceMailBox?: string,
    invoiceCity?: string,
    invoicePostalCode?: string,
    invoiceEmail?: string,
    invoiceCountry?: string,
    invoiceReference: string,
    restructuringOrDismissal: DataLabelType,
    outsideWorkingHours: boolean,
    recuperated: boolean,
    contactPerson: string,
    contactEmail: string,
    contactPhoneNumber?: string,
    contactResponsibilities: DataLabelType[],
    isContact: boolean,
    comments?: string,
    trainingPlan: boolean,
    cost: number,
    workStatus: DataLabelType,
    interimOfficeId?: string;
    interimOfficeContactFirstName?: string;
    interimOfficeContactLastName?: string;
    interimOfficeContactEmail?: string

    interimOfficeName?: string;
    interimOfficeStreet?: string,
    interimOfficeNumber?: string,
    interimOfficeMailbox?: string,
    interimOfficePostalCode?: string,
    interimOfficeCity?: string,
    interimOfficeCountry?: string,

    invoiceNeeded: boolean;
    invoiceStatus?: number;
  },
  employment: {
    employmentId: string,
    function: string,
    workStatus: DataLabelType,
    email: string,
    phoneNumber: string;
    startDate?: Date;
  },
  person: {
    personId: string,
    firstName: string,
    lastName: string,
    gender: DataLabelType,
    dateOfBirth: Date,
    language?: string,
    nationality: string,
    educationLevel?: DataLabelType,
    nationalIdentificationNumber?: string,
    phoneNumber?: string,
    email?: string,
    street?: string,
    houseNumber?: string,
    mailbox?: string,
    postalCode?: string,
    city?: string,
    country?: string
  }
}

export type EnrollmentListItem = {
  id: string;
  personId: string;
  branch?: DataLabelType;
  statusId: string;
  firstName: string;
  lastName: string;
  subscribed: Date;
  workStatus?: PersonWorkStatus;
  reasonCancelledId: string;
  cancelledComment: string;
  via: ENROLLMENT_VIA
  nationalIdentificationNumber: string,
  birthDate: Date
  email: string,
  phoneNumber: string,
  contactFirstName: string,
  contactLastName: string,
  contactEmail: string,
  contactPhoneNumber: string,
  comments: string
  interimOfficeName: string
  interimOfficeContactEmail: string
  interimOfficeContactFirstName: string
  interimOfficeContactLastName: string
  employmentStartDate?: Date;
  transitions?: {
    id: string
    transitionStatusId: string
    transitionTypeId: string
    rubricIds: string[],
    validFrom: Date
    validUntil: Date
    employmentId: string
    totalBudget: number
    remainingBudget: number
    numberOfHoursFollowed: number
  }[]
};

export type EnrollmentUpdateEvent = {
  enrollmentId?: string,
  enrollment: EnrollmentBody;
  action: EnrollmentActionEnum;
  setLoading: (state: boolean) => void;
};

export type ExtraMailResponse = {
  willTriggerMail: MailType,
  typeOfMail: number
}

export type CertificatesEligibleResponse = {
  isTrainingEligible: boolean;
  eligibleParticipants: EligibleParticipant[]
}

export type EligibleParticipant = {
  enrollmentId: string,
  participantId: string,
  firstName: string,
  lastName: string
}

export type EnrollmentRequest = {
  id: string
  trainingId: string
  stateId: EnrollmentRequestStatusKey
  branchId: string
  branchAlimentoId: number
  branchName: string
  branchStreet: string
  branchNumber: string
  branchMailbox: string
  branchPostalCode: string
  branchCity: string
  branchCountry: string
  branchNote: string
  companyNumber: string,
  vatNumber: string
  notSubjectToVat: boolean,
  rszNumber: string
  rszCodes: string[]
  contact1EmploymentId: string
  contact1FirstName: string
  contact1LastName: string
  contact1Email: string
  contact1PhoneNumber: string
  contact1Function: string
  contact2EmploymentId: string
  contact2FirstName: string
  contact2LastName: string
  contact2Email: string
  contact2PhoneNumber: string
  contact2Function: string
  customInvoiceAddress: boolean;
  invoiceStreet: string
  invoiceNumber: string
  invoiceMailbox: string
  invoicePostalCode: string
  invoiceCity: string
  invoiceCountry: string
  invoiceReference: string
  invoiceName: string,
  invoiceEmail: string,
  trainingPlan: boolean,
  remarks: string
  participants: EnrollmentRequestParticipant[],
  bankAccountNumber?: string,
  outsideWorkingHours?: boolean;
  recuperated?: boolean,
  createdOn: Date
}

export type EnrollmentRequestParticipant = {
  _uiId?: string,
  _isValid?: boolean;
  id?: string;
  enrollmentRequestId?: string;
  alimentoId: string
  trainingId?: string;
  employerId: string,
  employerName?: string;
  employerCity?: string;
  personId: string,
  nationalIdentificationNumber: string
  firstName: string
  lastName: string
  genderId: string
  language: any;
  email: string
  dateOfBirth?: Date,
  phoneNumber: string
  nationality: string
  workStatusId: string
  function: string
  employmentEmail: string;
  employmentPhoneNumber: string;
  educationLevelId: string,
  outsideWorkingHours: boolean,
  recuperated: boolean,
  interimOfficeId: string,
  interimOfficeName: string,
  interimOfficeStreet: string,
  interimOfficeNumber: string,
  interimOfficeMailbox: string,
  interimOfficePostalCode: string,
  interimOfficeCity: string,
  interimOfficeCountry: string,
  interimOfficeContactFirstName: string,
  interimOfficeContactLastName: string,
  interimOfficeContactEmail: string,
  enrollmentId: string,
  validationResultId: ENROLLMENT_RESULT_ID
  cost?: number;
  restructuringOrDismissalId: string;
  employmentStartDate: Date,
  enrollmentViaCevora: boolean;
  absences?: EnrollmentParticipantAbsence[];
  externalWebsiteCanEdit: boolean;

  street?: string;
  houseNumber?: string;
  mailbox?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

export type EnrollmentParticipantAbsence = {
  sessionId: string,
  reasonForAbsenceId: string
}

export type EnrollmentRequestAction = {
  action: EnrollmentRequestActionEnum;
  enrollmentRequest: EnrollmentRequest;
  participant?: EnrollmentRequestParticipant;
};

export type EnrollmentRequestUpdateEvent = {
  enrollmentRequestId?: string,
  enrollmentRequest: EnrollmentRequest;
  action: EnrollmentRequestActionEnum;
  setLoading: (state: boolean) => void;
};

export type ParticipantUpdateEvent = {
  participant: EnrollmentRequestParticipant,
  setLoading: (state: boolean) => void;
}

export type ValidationRequest = {
  url: string;
  email?: string;
  expires: Date;
  createdOn: Date;
  createdBy: string;
  editedOn: Date;
  editedBy: string;
}

export type EnrollmentSearchItem = {
  id: string
  alimentoId: number
  statusId: EnrollmentStatusKey
  branch: DataLabelType
  workStatusId: string
  subscribed: Date
  reasonCancelledId: string,
  cancelledComment: string,
  via: ENROLLMENT_VIA
  person: {
    id: string
    alimentoId: number
    firstName: string
    lastName: string
    nationalIdentificationNumber: string,
    birthDate: Date
  },
  training: {
    id: string
    alimentoId: number,
    customTitle: string
    typeId: TRAINING_TYPE
    statusId: TrainingStatusKey
    caseManagerId: string
    caseManagerName: string
    startDate: Date
    endDate: Date
    rubricId: string
    trainingAllowanceApplicationId: string;
    trainingAllowanceApplicationAlimentoId: string;
  },
  transitions: {
    id: string,
    statusId: string,
    typeId: string,
    remainingBudget: number,
    trainingTransitionReimbursementLinkStatusId: string
  }[]
}
