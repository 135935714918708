import { NgModule } from '@angular/core';
import { ExternalOfferComponent } from './pages/external-offer/external-offer.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { ConfirmationService } from 'primeng/api';
import { SessionsModule } from '../sessions/sessions.module';
import { UtilsModule } from '../utils/utils.module';
import { ExternalOfferCardComponent } from './components/external-offer-card/external-offer-card.component';
import { ExternalOfferStatusChipComponent } from './components/external-offer-status-chip/external-offer-status-chip.component';
import { CommunicationsModule } from '../communications/communications.module';
import { PaidEducationalLeaveModule } from '../paid-educational-leaves/paid-educational-leave.module';

@NgModule({
  imports: [
    UiLibModule,
    UtilsModule,
    SessionsModule,
    CommunicationsModule,
    PaidEducationalLeaveModule
  ],
  providers: [ConfirmationService, CanDeactivateGuard],
  declarations: [
    ExternalOfferComponent,
    ExternalOfferCardComponent,
    ExternalOfferStatusChipComponent
  ]
})
export class ExternalOfferModule {
}
