<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                     [readonly]='readOnly'
                                     [linkedEntity]='trainingId'
                                     [type]='TrainingNote'
                                     (hasChanges)='notesHasChanges = $event'
                                     (countUpdated)='loadNotesCount()'
></alimento-ipv-frontend-notes-sidebar>

<div *ngIf='trainingForm' [formGroup]="trainingForm">
  <alimento-ipv-frontend-detail-page-layout
    header="{{ typeLabel }}"
    [breadcrumb]='breadcrumb'
    fallbackUrl='/external-offer'
  >
    <ng-container status>
      <alimento-ipv-frontend-training-status-chip
        [status]="trainingStatus"
      ></alimento-ipv-frontend-training-status-chip>
    </ng-container>
    <ng-container top-action>
      <div class="flex gap-4" *ngIf='!readOnly'>
        <p-button
          type="button"
          label="{{ 'trainings.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveTraining()"
          [loading]="savingTraining"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        [trainingType]='type'
        [nrOfSessionsWithDates]='sessionService.nrOfSessionsWithDates'
        formControlName="basicDataForm"
        [showRequiredFieldErrors]="true"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>

      <alimento-ipv-frontend-summary-description
        [hidden]="activeTabIndex !== 1"
        formControlName="descriptionForm"
        [showRequiredFieldErrors]="true"
        [trainingType]='type'
        #summaryDescriptionComponent
      >
      </alimento-ipv-frontend-summary-description>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="trainingId"
        [trainingStatus]="trainingStatus"
        [trainingMethodId]="methodId"
        [trainingType]='type'
        (sessionUpdated)='loadTrainingData()'
      >
      </alimento-ipv-frontend-sessions>
    </ng-container>

    <ng-container metadata>
      <div *ngIf='!readOnly && trainingStatus === trainingStatusKeys.Completed' class='flex flex-col gap-4'>
        <alimento-ipv-frontend-reactivate-training-dialog
          #reactivateTrainingDialog
          [training]='training'
          [firstSessionDate]='firstSessionDate'
          (reactivateTrainingConfirmed)="reactivateTraining($event)"
        >
        </alimento-ipv-frontend-reactivate-training-dialog>

        <p-button
          type="button"
          label="{{ 'trainings.actions.reactivate' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="reactivatingTraining"
          (click)="reactivateTrainingDialog.open()"
        ></p-button>

      </div>

      <div *ngIf='!readOnly && trainingStatus !== trainingStatusKeys.Draft' class='flex flex-col gap-4'>
        <div class='flex flex-col'>
          <alimento-ipv-frontend-close-training-dialog
            #closeTrainingDialog
            [training]='training'
            [firstSessionDate]='firstSessionDate'
            (closeTrainingConfirmed)="closeTraining($event)"
          >
          </alimento-ipv-frontend-close-training-dialog>
          <p-button
            *ngIf='trainingStatus !== trainingStatusKeys.Completed'
            type="button"
            label="{{ 'trainings.actions.close' | translate }}"
            variant='outlined'
            loadingIcon="pi pi-spin pi-spinner"
            styleClass='w-full'
            [loading]="closingTraining"
            (click)="closeTrainingDialog.open()"
          ></p-button>
        </div>
      </div>

      <div>
        <div class='flex items-center justify-between gap-2 w-full'
             [pTooltip]='canPublish() ? "" : (trainingStatus === trainingStatusKeys.Completed ? "trainings.canNotPublishCompleted" : "trainings.publishNotAvailableTooltip") | translate'>
          <p-button label="{{ 'trainings.actions.publish' | translate }}"
                    [link]='true'
                    styleClass='whitespace-nowrap pl-0'
                    (onClick)='publishedToggle.enabled ? publishedToggle.setValue(!publishedToggle.value) : undefined'
          ></p-button>
          <p-toggle-switch [formControl]="publishedToggle"
          ></p-toggle-switch>
        </div>
      </div>

<!--        <span class="h-[1px] bg-grey-2"></span>-->


<!--        <div *ngIf='trainingStatus === trainingStatusKeys.Active && trainingId'>-->
<!--          <alimento-ipv-frontend-cancel-training-dialog-->
<!--            #cancelTrainingDialog-->
<!--            [training]='training()'-->
<!--            [firstSessionDate]='firstSessionDate'-->
<!--            (cancelTrainingConfirmed)="cancelTraining($event, cancelTrainingDialog)"-->
<!--          >-->
<!--          </alimento-ipv-frontend-cancel-training-dialog>-->
<!--          <p-button styleClass='px-0 text-danger '-->
<!--                    label="{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}"-->
<!--                    [link]='true'-->
<!--                    (onClick)='cancelTrainingDialog.open()'-->
<!--          ></p-button>-->
<!--        </div>-->
<!--      </div>-->

      <span class="h-[1px] bg-grey-2" *ngIf="trainingId"></span>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingStatus === trainingStatusKeys.Cancelled && reasonCancelled"
                                           header="{{ 'trainings.reasonCancelled' | translate }}"
                                           value="{{ reasonCancelled }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="trainingStatus === trainingStatusKeys.Cancelled && training()?.cancelledComment"
                                           header="{{ 'trainings.cancelledComment' | translate }}"
                                           value="{{ training().cancelledComment }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="template"
                                           header="{{ 'externalOffer.template' | translate }}"
                                           value="{{ template.trainingTemplateAlimentoId }} - {{template.customTitle}}"
                                           [isLink]='true'
                                           [link]='["/external-offer/templates", training().trainingTemplateId]'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.createdBy"
                                           header="{{ 'trainings.createdBy' | translate }}"
                                           value="{{ training().createdBy }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.createdOn"
                                           header="{{ 'trainings.createdOn' | translate }}"
                                           value="{{ training().createdOn | date : 'dd/MM/yyyy' }}"
      ></alimento-ipv-frontend-metadata-item>

    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
