<p-dialog
  (blur)="popupVisible = false"
  *ngIf='formGroup'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '1200px' }"
  [closable]="false"
  [focusOnShow]='false'
  appendTo="body"
  header="{{'externalOffer.bulkCreate' | translate}}"
>
  <div [formGroup]="formGroup">
    <div class='flex items-center gap-4'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='trainingTypeId'
        fieldKey='trainings.type'
        [required]='true'
      >
        <p-select
          [options]="trainingTypes"
          appendTo="body"
          formControlName="trainingTypeId"
          id="trainingTypeId"
          optionLabel="label"
          optionValue="data"
          placeholder="{{ 'selectPlaceholder' | translate }}"
          styleClass="w-full"
        ></p-select>
      </alimento-ipv-frontend-form-field>

      <div class='field field-checkbox !pb-0' *ngIf='formGroup.get("trainingTypeId").value === TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL'>
        <p-checkbox
          [binary]='true'
          formControlName='availableAsCustomTraining'
          inputId='availableAsCustomTraining'
        ></p-checkbox>
        <label for='availableAsCustomTraining'>{{ 'trainings.basicData.availableAsCustomTraining' | translate }}</label>
      </div>
    </div>

    <h2 class='text-lg mb-2'>{{'externalOffer.trainings' | translate}}</h2>

    <div class='flex flex-col gap-4'>
      <div *ngFor='let training of trainings.controls, let i = index'
           formArrayName='trainings'
           class='relative border rounded-sm border-grey-2 p-4'>
        <div class='absolute right-2 top-2' *ngIf='trainings.length > 1'>
          <button (click)="deleteTraining(i)" class="p-2 group">
            <i class="pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover"></i>
          </button>
        </div>
        <div [formGroupName]='i'>
          <alimento-ipv-frontend-external-offer-create
            [methodId]='methodId'
          ></alimento-ipv-frontend-external-offer-create>
        </div>
      </div>
    </div>

    <div class='flex items-center w-fit p-2 text-primary hover:bg-primary-hover' >
      <button (click)='addTraining()'><i class='pi pi-plus mr-2'></i> {{'externalOffer.addTraining' | translate}}</button>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        [loading]="loading"
        (onClick)="submitForm()"
        label="{{ 'externalOffer.addTrainings' | translate }} ({{trainings.length}})"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        label="{{ 'cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
