import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  catchError,
  concatMap,
  first,
  from,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subscription,
  toArray
} from 'rxjs';
import {
  AbsenceReason,
  CaseManager,
  CostType,
  Counselor,
  Country,
  DataLabelType,
  EducationLevel,
  EnrollmentStatus,
  Gender,
  IncludedSessionOptions,
  LanguageCode,
  Nationality,
  Responsibility,
  RestructuringOrDismissal,
  TrainingRubric,
  TrainingRubricTreeItem,
  TrainingStatus,
  TrainingTemplateStatus,
  TrainingTitleTreeItem,
  TrainingType,
  WorkStatus
} from '../types/reference-data.type';
import {
  ACCEPT_LANGUAGE,
  EnrollInTrainingKey,
  EnrollInTrainingMailType,
  PUPIL_TRAINING_TYPE,
  PupilTrainingTypeKey,
  TEACHER_TRAINING_TYPE,
  TeacherTrainingTypeKey,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../types/reference-data.enum';
import { CancelTrainingReason } from '../types/training.type';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG_TOKEN, AppConfig, FilterType, IFilter, TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { TransitionType } from '../types/person.type';

export enum OPTIONS_LIST_TYPE {
  SUB_SECTORS = 'trainingsubsectors',
  TRAINING_FUNCTIONS = 'trainingfunctions',
  TRAINING_METHODS = 'trainingmethods',
  TRAINING_STATUSES = 'trainingstatuses',
  INCLUDED_SESSION_OPTIONS = 'includedsessionoptions',
  RUBRICS = 'rubrics',
  REASON_TRAINING_CANCELLED = 'reasontrainingcancelled',
  REASON_ALLOWANCE_APPLICATION_TRAINING_CANCELLED = 'reasonallowanceapplicationtrainingcancelled',
  REASON_ENROLLMENT_CANCELLED = 'reasonenrollmentcancelled',
  LANGUAGES = 'languages',
  TEMPLATE_STATUSES = 'templatestatuses',
  OPEN_TRAINING_COST_TYPES = 'opentrainingcosttypes',
  GENDERS = 'genders',
  NATIONALITIES = 'nationalities',
  EDUCATION_LEVELS = 'educationlevels',
  ENROLLMENT_STATUS = 'enrollmentstatus',
  COUNTRIES = 'countries',
  RESPONSIBILITIES = 'responsibilities',
  WORK_STATUSES = 'workstatuses',
  RESTRUCTURING_OR_DISMISSAL = 'restructuringOrDismissal',
  REASON_ABSENCE = 'reasonabsence',
  LETTER_VERSIONS = 'letterversions',
  THEMES = 'themes',
  INVOICE_STATUSES = 'invoicestatuses',
  MAIL_STATES = 'mailstates',
  MAIL_TYPES = 'mailtypes',
  TASK_STATES = 'taskstates',
  CONTEXT_ITEM_CATEGORIES = 'contextitemcategories',
  MAIL_ITEM_STATES = 'mailitemstates',
  TRAINING_ALLOWANCE_APPLICATION_STATUSES = 'trainingallowanceapplicationstatuses',
  TRAINING_PLAN_STATES = 'trainingplanstates',
  PAYMENT_METHODS = 'paymentmethods',
  FINANCIAL_DOCUMENT_TYPES = 'financialdocumenttypes',
  TRANSITION_TYPES = 'transitiontypes',
  TRANSITION_STATUSES = 'transitionstatuses',
  EDUCATIONAL_TRAINING_PUPIL_SUBJECT_TYPES = 'educationaltrainingpupilsubjecttypes',
  EDUCATIONAL_TRAINING_TEACHER_SUBJECT_TYPES = 'educationaltrainingteachersubjecttypes',
  TRAINING_SUBSIDY_TYPES = 'trainingsubsidytypes',
  EDUCATIONAL_EVENT_SUBJECT_TYPES = 'educationaleventsubjecttypes',
  REIMBURSEMENT_STATUSES = 'reimbursementstatuses',
  REIMBURSEMENT_TYPES = 'reimbursementtypes',
  REIMBURSEMENT_ACTION_TYPES = 'reimbursementactiontypes',
  PURCHASE_ENTRY_STATUSES = 'purchaseentrystatuses',
  SALES_INVOICE_STATUSES = 'salesinvoicestatuses',
  NACE_CODES = 'nacecodes',
  PAID_EDUCATIONAL_LEAVE_TYPES = 'paideducationalleaverecognitiontypes',
  REGIONS = 'regions',
  PAID_EDUCATIONAL_LEAVE_STATUSES = 'paideducationalleaveatteststatuses',
  PAID_EDUCATIONAL_LEAVE_HOURS_STATUSES = 'paideducationalleavehourstatuses',
  PAID_EDUCATIONAL_LEAVE_TITLES = 'paideducationalleavetitles',
  PAID_EDUCATIONAL_LEAVE_SCHOOL_YEARS = 'paideducationalleaveattestschoolyears',
  PAID_EDUCATIONAL_LEAVE_CANCEL_REASONS = "reasonpaideducationalleaveattestcancelled",
  TRAINING_APPROVAL_FILTERS = "trainingApprovalFilters",
  TRAINING_TYPES = "trainingtypes",
  CALCULATION_TYPES = "trainingcalculationtypes",
  TEMPLATE_TYPES = "templatetypes",
  TRANSITION_LINK_STATUSES = "trainingtransitionreimbursementlinkstatuses"
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService implements OnDestroy {
  private cachedObservables: { [key: string]: Observable<any[]> } = {};
  private _subscription: Subscription;

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              private translationService: TranslationService,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
    this._subscription = this.translationService.languageChange$.subscribe(() => {
      this.cachedObservables = {};
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  getReferenceData(type: OPTIONS_LIST_TYPE): Observable<DataLabelType[]> {
    if (!this.cachedObservables[type]) {
      this.cachedObservables[type] = this.http.get<DataLabelType[]>(`${this.config.readApiUrl}/referencedata/${type}`)
        .pipe(shareReplay(1));
    }
    return this.cachedObservables[type];
  }

  getReferenceDataItem(key: string, type: OPTIONS_LIST_TYPE): Observable<DataLabelType> {
    return this.getReferenceData(type).pipe(
      concatMap(x => x),
      first(item => item.data === key),
      catchError(() => of({label: key, data: key})));
  }

  searchReferenceData(query = '', type: OPTIONS_LIST_TYPE): Observable<DataLabelType[]> {
    return this.getReferenceData(type).pipe(
      map(items => items
        .filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0))
    );
  }

  getReferenceDataAsFilter(type: OPTIONS_LIST_TYPE, filterType: FilterType): Observable<IFilter[]> {
    return this.getReferenceData(type)
      .pipe(map(types => types.map(type => ({
        type: filterType,
        label: type.label,
        value: type.data
      }) as IFilter)));
  }

  getYesNoFilter(filterType: FilterType): Observable<IFilter[]> {
    return of([
      {
        type: filterType,
        label: this.translateService.instant('yes'),
        value: 'true'
      } as IFilter,
      {
        type: filterType,
        label: this.translateService.instant('no'),
        value: 'false'
      } as IFilter
    ])
  }



  private _getReferenceData<T>(filterName: string, cacheKey?: string, headers?: HttpHeaders) {
    if (!this.cachedObservables[cacheKey || filterName]) {
      this.cachedObservables[cacheKey || filterName] =
        this.http.get<T[]>(`${this.config.readApiUrl}/referencedata/${filterName}`, { headers: headers })
          .pipe(shareReplay(1));
    }
    return this.cachedObservables[cacheKey || filterName];
  }

  private _getReferenceDataItem<T extends DataLabelType>(key: string, data: Observable<T[]>) {
    return data.pipe(
      concatMap(x => x),
      first(item => item.data === key));
  }

  getCaseManagers(onlyActive = false): Observable<CaseManager[]> {
    return this._getReferenceData<CaseManager>('casemanagers')
      .pipe(
        map((caseManagers) =>
          caseManagers
            .map((caseManager: any) => ({
              data: caseManager.data || caseManager.id,
              label: caseManager.label || caseManager.name,
              isActive: caseManager.isActive
            }))
            .filter(caseManager => !onlyActive || caseManager.isActive)
        )
      );
  }

  getCaseManager(key: string): Observable<CaseManager> {
    return this._getReferenceDataItem(key, this.getCaseManagers(false));
  }

  getCounselors(onlyActive = false): Observable<Counselor[]> {
    return this._getReferenceData<Counselor>('counselors')
      .pipe(
        map((counselors) =>
          counselors
            .map((counselor: any) => ({
              data: counselor.data || counselor.id,
              label: counselor.label || counselor.name,
              isActive: counselor.isActive
            }))
            .filter(counselor => !onlyActive || counselor.isActive)
        )
      );
  }

  getCounselor(key: string): Observable<Counselor> {
    return this._getReferenceDataItem(key, this.getCounselors(false));
  }

  getTrainingTypes(filterPupilAndTeacher = true): Observable<TrainingType[]> {
    return this._getReferenceData<TrainingType>('trainingtypes')
      .pipe(map((type: DataLabelType[]) => {
        if (filterPupilAndTeacher) {
          type = type.filter(type => !PUPIL_TRAINING_TYPE.includes(type.data as TRAINING_TYPE))
            .filter(type => !TEACHER_TRAINING_TYPE.includes(type.data as TRAINING_TYPE));
          type.push({ data: PupilTrainingTypeKey, label: this.translateService.instant(PupilTrainingTypeKey) });
          type.push({ data: TeacherTrainingTypeKey, label: this.translateService.instant(TeacherTrainingTypeKey) });
        }
        return type;
      }));
  }

  getTrainingType(type: string): Observable<TrainingType> {
    return this._getReferenceDataItem(type, this.getTrainingTypes(false));
  }

  getTrainingTitles(language?: string): Observable<TrainingTitleTreeItem[]> {
    return this._getReferenceData<TrainingTitleTreeItem>(`trainingtitles`, `trainingtitles-${language}`,
      new HttpHeaders().set(ACCEPT_LANGUAGE, language)).pipe(
      map((treeItems: TrainingRubricTreeItem[]) => {
        return treeItems
          .filter(treeItem => treeItem.label)
          .map(treeItem => ({
            data: treeItem.data,
            key: treeItem.data,
            label: treeItem.label,
            selectable: false,
            children: treeItem.mainTitles
              .filter(mainTitle => mainTitle.label)
              .map(mainTitle => ({
                data: mainTitle.data,
                key: mainTitle.data,
                label: mainTitle.label,
                selectable: false,
                children: mainTitle.trainingTitles
                  .filter(trainingTitle => trainingTitle.label)
                  .map(trainingTitle => ({
                    data: trainingTitle.data,
                    key: trainingTitle.data,
                    label: trainingTitle.label,
                    educationLeaveRecognitions: trainingTitle.educationLeaveRecognitions
                  }))
              }))
          })) as TrainingTitleTreeItem[];
      })
    );
  }

  getTrainingStatuses(): Observable<TrainingStatus[]> {
    return this._getReferenceData<TrainingStatus>('trainingstatuses')
      .pipe(
        map(trainingStatuses => trainingStatuses.filter(trainingStatus => trainingStatus.data !== TrainingStatusKey.Removed))
      );
  }

  getIncludedSessionOptions(): Observable<IncludedSessionOptions[]> {
    return this._getReferenceData<IncludedSessionOptions>('includedsessionoptions');
  }

  getTrainingRubrics(): Observable<TrainingRubric[]> {
    return this._getReferenceData<TrainingRubric>('rubrics');
  }

  getTrainingRubric(rubricId: string): Observable<TrainingRubric> {
    return this._getReferenceDataItem(rubricId, this.getTrainingRubrics());
  }

  getCancelTrainingReasons(): Observable<CancelTrainingReason[]> {
    return this._getReferenceData<CancelTrainingReason>('reasontrainingcancelled');
  }

  getCancelTrainingReason(key: string): Observable<CancelTrainingReason> {
    return this._getReferenceDataItem(key, this.getCancelTrainingReasons());
  }

  getCancelReimbursementTrainingReasons(): Observable<CancelTrainingReason[]> {
    return this._getReferenceData<CancelTrainingReason>('reasonallowanceapplicationtrainingcancelled');
  }

  getCancelReimbursementTrainingReason(key: string): Observable<CancelTrainingReason> {
    return this._getReferenceDataItem(key, this.getCancelReimbursementTrainingReasons());
  }

  getCancelEnrollmentReasons(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('reasonenrollmentcancelled');
  }

  getCancelEnrollmentReason(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getCancelEnrollmentReasons());
  }

  getLanguageCodes(): Observable<LanguageCode[]> {
    return this._getReferenceData<LanguageCode>('languages');
  }

  getTemplateStatuses(): Observable<TrainingTemplateStatus[]> {
    return this._getReferenceData<TrainingTemplateStatus>('templatestatuses');
  }

  getCostTypes(): Observable<CostType[]> {
    return this._getReferenceData<CostType>('opentrainingcosttypes');
  }

  getGenders(): Observable<Gender[]> {
    return this._getReferenceData<Gender>('genders');
  }

  getNationalities(): Observable<Nationality[]> {
    return this._getReferenceData<Nationality>('nationalities');
  }

  getEducationLevels(): Observable<EducationLevel[]> {
    return this._getReferenceData<EducationLevel>('educationlevels');
  }

  getEnrollmentStatuses(): Observable<EnrollmentStatus[]> {
    return this._getReferenceData<EnrollmentStatus>('enrollmentstatus');
  }

  getCountries(): Observable<Country[]> {
    return this._getReferenceData<Country>('countries');
  }

  getResponsibilities(): Observable<Responsibility[]> {
    return this._getReferenceData<Responsibility>('responsibilities');
  }

  getWorkStatuses(): Observable<WorkStatus[]> {
    return this._getReferenceData<WorkStatus>('workstatuses');
  }

  getWorkStatus(key: string): Observable<WorkStatus> {
    return this._getReferenceDataItem(key, this.getWorkStatuses());
  }

  getRestructuringOrDismissals(): Observable<RestructuringOrDismissal[]> {
    return this._getReferenceData<RestructuringOrDismissal>('restructuringOrDismissal');
  }

  getRestructuringOrDismissal(key: string): Observable<WorkStatus> {
    return this._getReferenceDataItem(key, this.getRestructuringOrDismissals());
  }

  getAbsenceReasons(): Observable<AbsenceReason[]> {
    return this._getReferenceData<AbsenceReason>('reasonabsence');
  }

  getLetterVersions(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('letterversions');
  }

  getLetterVersion(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getLetterVersions());
  }

  getInvoiceStatuses(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('invoicestatuses');
  }

  getInvoiceStatus(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getInvoiceStatuses());
  }

  getMailStatus(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('mailstates');
  }

  getMailTypes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('mailtypes')
      .pipe(map((mailTypes: DataLabelType[]) => {
        mailTypes = mailTypes.filter(type => !Object.keys(EnrollInTrainingMailType).includes(type.data));
        mailTypes.push({ data: EnrollInTrainingKey, label: EnrollInTrainingKey });
        return mailTypes;
      }));
  }

  getTaskStatus(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('taskstates');
  }

  getContextItemCategories(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('contextitemcategories');
  }

  getMailItemStatus(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('mailitemstates');
  }

  getReimbursementRequestStatuses(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('trainingallowanceapplicationstatuses');
  }

  getReimbursementRequestStatus(statusKey: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(statusKey, this.getReimbursementRequestStatuses());
  }

  getTrainingPlanStates(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('trainingplanstates');
  }

  getTrainingPlanState(stateId: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(stateId, this.getTrainingPlanStates());
  }

  getPaymentMethods(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('paymentmethods');
  }

  getFinancialTypes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('financialdocumenttypes');
  }

  getFinancialType(typeId: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(typeId, this.getFinancialTypes());
  }

  getTransitionTypes(): Observable<TransitionType[]> {
    return this._getReferenceData<DataLabelType>('transitiontypes');
  }

  getTransitionTypesFlat(): Observable<TransitionType[]> {
    return this.getTransitionTypes().pipe(
      concatMap(x => x),
      mergeMap(item => {
        const type: TransitionType = JSON.parse(JSON.stringify(item));
        if (type.transitionTypes?.length > 0) {
          type.transitionTypes.forEach(type => {
            type.parent = item.data;
            type.label = item.label + ': ' + type.label;
          });
          return from(type.transitionTypes);
        }
        else {
          return from([type]);
        }
      }),
      toArray()
    );
  }

  getTransitionType(key: string): Observable<TransitionType> {
    return this.getTransitionTypes().pipe(
      concatMap(x => x),
      mergeMap(item => {
        const type: TransitionType = JSON.parse(JSON.stringify(item));
        if (type.transitionTypes) {
          type.transitionTypes.forEach(type => {
            type.parent = item.data;
            type.label = item.label + ': ' + type.label;
          });
        }
        return from([type, ...type.transitionTypes || []]);
      }),
      first(item => item.data === key));
  }

  getTransitionStatuses(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('transitionstatuses');
  }

  getTransitionStatus(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getTransitionStatuses());
  }

  getPupilSubjectTypes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('educationaltrainingpupilsubjecttypes');
  }

  getPupilSubjectType(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getPupilSubjectTypes());
  }

  getTeacherSubjectTypes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('educationaltrainingteachersubjecttypes');
  }

  getTeacherSubjectType(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getTeacherSubjectTypes());
  }

  getTrainingSubsidyTypes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('trainingsubsidytypes');
  }

  getTrainingSubsidyType(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getTrainingSubsidyTypes());
  }

  getEducationEventSubjectTypes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('educationaleventsubjecttypes');
  }

  getEducationEventSubjectType(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getEducationEventSubjectTypes());
  }

  getReimbursementTypes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('reimbursementtypes');
  }

  getReimbursementType(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getReimbursementTypes());
  }

  getPurchaseEntryStatuses(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('purchaseentrystatuses');
  }

  getPurchaseEntryStatus(key: string): Observable<DataLabelType> {
    return this._getReferenceDataItem(key, this.getPurchaseEntryStatuses());
  }

  getNaceCodes(): Observable<DataLabelType[]> {
    return this._getReferenceData<DataLabelType>('nacecodes');
  }

  getPaidEducationalLeaveSchoolYearsFilter(): Observable<IFilter[]> {
    return this.getReferenceData(OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_SCHOOL_YEARS)
      .pipe(map((result: any) => {
        const schoolYears = result as number[];
        return schoolYears.map((schoolYear) => (
          {
            label: schoolYear + "-" + (schoolYear + 1),
            value: schoolYear + "",
            type: FilterType.filterSchoolYears
          }
        ));
    }));
  }
}
