import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { first, Observable, of, Subscription, switchMap } from 'rxjs';
import {
  TrainingPlan,
  TrainingPlanAction,
  TrainingPlanActionEvent, TrainingPlanReminderEvent,
  TrainingPlanUpdateEvent
} from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import { HistoryItem, MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import { TrainingPlanPopupComponent } from '../training-plan-popup/training-plan-popup.component';
import { TRAINING_PLAN_STATE, TrainingPlanActionEnum } from '../../../types/branch.enum';
import {
  TrainingPlanActionDialogComponent
} from '../training-plan-action-dialog/training-plan-action-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  TrainingPlanRequestPopupComponent
} from '../training-plan-request-popup/training-plan-request-popup.component';
import {
  TrainingPlanReminderPopupComponent
} from '../training-plan-reminder-popup/training-plan-reminder-popup.component';

@Component({
    selector: 'alimento-ipv-frontend-training-plans',
    templateUrl: './training-plans.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TrainingPlansComponent implements OnChanges, OnDestroy {
  @Input()
  branchId?: string;

  @Input()
  readOnly = false;

  @Input()
  yearFilter: Date;

  @Output()
  historyItems: EventEmitter<HistoryItem[]> = new EventEmitter<HistoryItem[]>();

  currentTrainingPlan: TrainingPlan;
  currentTrainingPlanAction: TrainingPlanActionEnum;
  trainingPlans: TrainingPlan[];
  private _subscriptions: Subscription[] = [];

  @ViewChild(TrainingPlanActionDialogComponent)
  planActionDialogComponent: TrainingPlanActionDialogComponent;

  @ViewChild(TrainingPlanReminderPopupComponent)
  trainingPlanReminderPopupComponent: TrainingPlanReminderPopupComponent;

  @ViewChild(TrainingPlanPopupComponent)
  trainingPlanPopupComponent: TrainingPlanPopupComponent;

  constructor(private branchService: BranchService,
              private messageService: MyMessageService,
              private confirmationService: ConfirmationService,
              private translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branchId']?.currentValue) {
      this._getTrainingPlans();
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  private _getTrainingPlans(): void {
    this.branchService.getTrainingPlans(this.branchId).pipe(first())
      .subscribe(trainingPlans => this.trainingPlans = trainingPlans);
  }

  trainingPlanActionClicked(event: TrainingPlanAction): void {
    this.currentTrainingPlan = JSON.parse(JSON.stringify(event.trainingPlan));
    this.currentTrainingPlanAction = event.action;
    if (event.action === TrainingPlanActionEnum.edit) {
      this.trainingPlanPopupComponent.openPopup();
    }
    else if (event.action === TrainingPlanActionEnum.register) {
      this.trainingPlanPopupComponent.openPopup();
    }
    else if (event.action === TrainingPlanActionEnum.reminder) {
      this.trainingPlanReminderPopupComponent.openPopup(event.trainingPlan);
    }
    else if (event.action === TrainingPlanActionEnum.approve) {
      this.planActionDialogComponent.open();
    }
    else if (event.action === TrainingPlanActionEnum.reject) {
      this.planActionDialogComponent.open();
    }
    else if (event.action === TrainingPlanActionEnum.delete) {
      this._deleteTrainingPlan(event.trainingPlan);
    }
    else if (event.action === TrainingPlanActionEnum.history) {
      this.branchService.getTrainingPlanHistory(event.trainingPlan.id).pipe(first())
        .subscribe(historyItems => {
          this.historyItems.emit(historyItems);
        });
    }
  }

  createNewTrainingPlan(): void {
    this.currentTrainingPlan = {
      stateId: TRAINING_PLAN_STATE.RECEIVED
    } as TrainingPlan;
    this.trainingPlanPopupComponent.openPopup();
  }

  requestTrainingPlan(trainingPlanRequestPopupComponent: TrainingPlanRequestPopupComponent): void {
    const latestTrainingPlan = this.trainingPlans
      .filter(trainingPlan => trainingPlan.stateId !== TRAINING_PLAN_STATE.REJECTED)[0];
    trainingPlanRequestPopupComponent.openPopup(latestTrainingPlan);
  }

  trainingPlanSubmit(event: TrainingPlanUpdateEvent): void {
    event.setLoading(true);

    const createOrUpdateTrainingPlan$ = event.id
      ? this.branchService.updateTrainingPlan(event.id, this.branchId, event.trainingPlan)
      : this.branchService.createTrainingPlan(this.branchId, event.trainingPlan);

    createOrUpdateTrainingPlan$.pipe(
      first(),
      switchMap(result => {
        if (event.action === TrainingPlanActionEnum.register) {
          return this.branchService.registerTrainingPlan(result.id, {}).pipe(first());
        }
        else {
          return of(result.id);
        }
      })
    )
      .subscribe({
        next: () => {
          this.messageService.success();

          this._getTrainingPlans();

          event.setLoading(false);
          this.trainingPlanPopupComponent.closePopup();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  executeRequestTrainingPlan(event: TrainingPlanUpdateEvent, trainingPlanRequestPopupComponent: TrainingPlanRequestPopupComponent): void {
    event.setLoading(true);

    this.branchService.requestTrainingPlan(this.branchId, event.trainingPlan)
      .pipe(first())
      .subscribe({
        next: () => {
          this._getTrainingPlans();
          event.setLoading(false);
          trainingPlanRequestPopupComponent.closePopup();
        },
        error: () => event.setLoading(false)
      });
  }

  executeTrainingPlanAction(event: TrainingPlanActionEvent) {
    event.setLoading(true);
    let action$: Observable<any>;
    if (this.currentTrainingPlanAction === TrainingPlanActionEnum.approve) {
      action$ = this.branchService.approveTrainingPlan(this.currentTrainingPlan.id, event.data);
    }
    else if (this.currentTrainingPlanAction === TrainingPlanActionEnum.reject) {
      action$ = this.branchService.rejectTrainingPlan(this.currentTrainingPlan.id, event.data);
    }

    action$.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success();
          event.setLoading(false);
          this.planActionDialogComponent.closeDialog();
          this._getTrainingPlans();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  private _deleteTrainingPlan(trainingPlan: TrainingPlan) {
    this.confirmationService.confirm({
      header: this.translateService.instant('branches.trainingPlans.deleteTitle'),
      message: this.translateService.instant('branches.trainingPlans.deleteMessage'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('branches.trainingPlans.deleteTitle'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('trainings.deleteDialog.cancel'),
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.branchService.removeTrainingPlan(trainingPlan.id).pipe(first())
          .subscribe(() => {
            this.messageService.success('branches.trainingPlans.deleted');
            this._getTrainingPlans();
          });
      }
    });
  }

  showTrainingPlan(plan: TrainingPlan): boolean {
    if (!this.yearFilter) {
      return true;
    }

    const years = [];
    let startYear = new Date(plan.validFrom).getFullYear();
    const endYear = new Date(plan.validTo).getFullYear();
    years.push(startYear);
    while (startYear < endYear) {
      startYear++;
      years.push(startYear);
    }

    return years.includes(new Date(this.yearFilter).getFullYear());
  }

  executeSendReminder(event: TrainingPlanReminderEvent): void {
    event.setLoading(true);
    this.branchService.sendTrainingPlanReminder(event.trainingPlanId, event.data).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success();
          this._getTrainingPlans();
          event.setLoading(false);
          this.trainingPlanReminderPopupComponent.closePopup();
        },
        error: () => event.setLoading(false)
      });
  }
}
