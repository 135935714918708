import { Component, OnDestroy } from '@angular/core';
import { first, Subscription } from 'rxjs';
import { environment } from '@alimento-ipv-frontend/environments';
import { TranslateService } from '@ngx-translate/core';
import {
  AppMenuItem,
  AuthenticationService,
  FeatureFlagService,
  FilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { COMMUNICATION_TO_DO, CommunicationsService } from '@alimento-ipv-frontend/application-lib';

@Component({
  selector: 'alimento-ipv-frontend-navbar',
  templateUrl: './navbar.component.html',
  standalone: false
})
export class NavbarComponent implements OnDestroy {
  appMenuItems: AppMenuItem[] = [];

  private _subscription: Subscription;
  protected readonly environment = environment;

  constructor(private translationService: TranslationService,
              private authenticationService: AuthenticationService,
              private communicationService: CommunicationsService,
              private translateService: TranslateService,
              private featureService: FeatureFlagService) {
    this._subscription = this.authenticationService.currentUser
      .subscribe(user => {
        if (user) {
          this.translationService.languageChange$.subscribe(() => {
            this._setMenuItems();
          });
        }
      });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _setMenuItems(): void {
    this.appMenuItems = [];
    this.translateService
      .get([''])
      .pipe(first())
      .subscribe(() => {
        const communicationRequest = { filters: [] } as SearchRequest;
        communicationRequest.filters.push({ type: FilterType.toDo, values: [COMMUNICATION_TO_DO] });
        communicationRequest.filters.push({
          type: FilterType.filterSenderId,
          values: [this.authenticationService.getCurrentUserId()]
        });

        this.featureService.initialize().pipe(first()).subscribe(() => {
          this.appMenuItems = [
            {
              icon: 'school',
              label: this.translateService.instant('trainings.menuTitle'),
              routerLink: ['/trainings']
            },
            {
              icon: 'copy_all',
              label: this.translateService.instant('templates.menuTitle'),
              routerLink: ['/training-templates']
            },
            {
              icon: 'stacks',
              label: this.translateService.instant('reimbursementRequests.menuTitle'),
              routerLink: ['/reimbursement-requests']
            },
            {
              icon: 'person',
              label: this.translateService.instant('persons.menuTitle'),
              routerLink: ['/persons']
            },
            // {
            //   icon: 'domain',
            //   label: this.translateService.instant('enterprises.menuTitle'),
            //   items: [
            {
              icon: 'groups',
              label: this.translateService.instant('enterpriseGroups.menuTitle'),
              routerLink: ['/enterprise-groups']
            },
            {
              icon: 'domain',
              label: this.translateService.instant('enterprises.menuTitle'),
              routerLink: ['/enterprises']
            },
            {
              icon: 'store',
              label: this.translateService.instant('branches.menuTitle'),
              routerLink: ['/branches']
            },
            // ]
            // },
            {
              icon: 'contract',
              label: this.translateService.instant('paidEducationalLeaves.menuTitle'),
              routerLink: ['/paid-educational-leaves']
            },
            {
              icon: 'mail',
              label: this.translateService.instant('communications.menuTitle'),
              routerLink: ['/communications'],
              badgeFunction: this.communicationService.getCommunicationsCount(communicationRequest)
            },
            {
              icon: 'event_upcoming',
              label: this.translateService.instant('externalOffer.menuTitle'),
              routerLink: ['/external-offer']
            }
          ];
        });
      });
  }
}
