<div class='title'>{{ 'enrollments.comments' | translate }}</div>

<div [formGroup]='formGroup'>
  <div>
    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      fieldKey='enrollments.comments'
      formField='comments'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('comments')?.value}}</span>
      <div *ngIf='formGroup.enabled'>
        <textarea #focusElement class='w-full' cols='30' formControlName='comments' id='comments' pTextarea
                  rows='5'></textarea>
      </div>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='enrollmentVia !== ENROLLMENT_VIA.CEVORA'
      [errorTemplate]='enrollmentCostError'
      [formGroup]='formGroup'
      [required]='true'
      fieldKey='enrollments.enrollmentCost'
      formField='cost'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>€ {{formGroup.get('cost')?.value}}</span>
      <div *ngIf='formGroup.enabled'>
        <p-inputNumber
          [max]='9999'
          [min]='0'
          [required]='true'
          currency='EUR'
          formControlName='cost'
          id='cost'
          inputStyleClass='w-36'
          locale='en-US'
          mode='currency'
        ></p-inputNumber>
      </div>
      <ng-template #enrollmentCostError>
        <small *ngIf="isFieldInvalid('cost') && formGroup.get('cost').hasError('required')">
          {{ 'validation.isRequired' | translate : { field: 'enrollments.enrollmentCost' | translate } }}
        </small>
        <small *ngIf="isFieldInvalid('cost') && formGroup.get('cost').hasError('min')">
          {{ 'validation.minValue' | translate : { number: 0 } }}
        </small>
        <small *ngIf="isFieldInvalid('cost') && formGroup.get('cost').hasError('max')">
          {{ 'validation.maxValue' | translate : { number: 10000 } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <div *ngIf='invoiceStatusReadOnly'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        fieldKey='enrollments.invoiceStatus'
        formField='invoiceStatus'
      >
        <span class='read-only-field'>{{invoiceStatusReadOnly}}</span>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>

  <div *ngIf='extraMail?.willTriggerMail' class='my-4 p-4 bg-grey-0 border-l-4 border-l-primary flex flex-col'>
    <span>{{getMessageKey(extraMail.typeOfMail) | translate}}</span>
    <span>{{'enrollments.sendExtraCommunication' | translate}}</span>
  </div>
</div>
