import { Component, OnInit } from '@angular/core';
import { first, map, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthorizationService,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  Role,
  SearchFilter,
  SearchFilterType,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { TrainingTemplateListItem, TrainingTemplateSearchItem } from '../../../types/training-template.type';
import { TrainingTemplateService } from '../../../services/training-template.service';
import { OPTIONS_LIST_TYPE, ReferenceDataService, TEMPLATE_TYPE } from '@alimento-ipv-frontend/application-lib';
import { SearchesService } from '../../../services/searches.service';

@Component({
  selector: 'alimento-ipv-frontend-training-template-list',
  templateUrl: './training-template-list.component.html',
  standalone: false
})
export class TrainingTemplateListComponent implements OnInit {
  templates: TrainingTemplateListItem[] = [];
  searchData$: InfiniteScrollDataAdapter<TrainingTemplateSearchItem>;
  searchFilters: SearchFilter[];
  readOnly = true;

  constructor(
    private trainingTemplateService: TrainingTemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private searchesService: SearchesService,
    private referenceDataService: ReferenceDataService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
  }

  ngOnInit(): void {
    this._createSearch();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: [{type: FilterType.filterType, values: [TEMPLATE_TYPE.OPEN_TRAINING]}]
    };

    this.searchData$ = new InfiniteScrollDataAdapter<TrainingTemplateSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<TrainingTemplateSearchItem>> => {
      return this.searchesService.searchTrainingTemplates(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.status',
        key: FilterType.filterStatuses,
        expanded: true,
        data: this.referenceDataService.getTemplateStatuses()
          .pipe(map(types => types.map(type =>
            ({ type: FilterType.filterStatuses, label: type.label, value: type.data }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.RUBRICS, FilterType.filterRubric)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        key: FilterType.filterCaseManager,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCaseManager,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'templates.language',
        key: FilterType.filterLanguage,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.LANGUAGES, FilterType.filterLanguage)
          .pipe(map(types => types.map(type => {
            type.label = type.label.toUpperCase();
            return type;
          })))
      },
    ];
  }

  createTrainingFromTemplate(trainingTemplateId: string): void {
    this.trainingTemplateService
      .createTrainingFromTemplate(trainingTemplateId)
      .pipe(first())
      .subscribe(trainingId =>
        this.router.navigate(['/trainings', 'open', trainingId], {
          relativeTo: this.route.parent,
          state: {
            newTraining: true
          }
        })
      );
  }
}
