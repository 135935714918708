import { Component, EventEmitter, Input, OnDestroy, Output, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, Subscription } from 'rxjs';
import { DataLabelType, MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { TRAINING_TYPE } from '../../../types/reference-data.enum';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { CreateExternalOfferEvent } from '@alimento-ipv-frontend/application-lib';
import { ExternalOfferCreateComponent } from '../external-offer-create/external-offer-create.component';

@Component({
  selector: 'alimento-ipv-frontend-bulk-external-offer-popup',
  templateUrl: './bulk-external-offer-popup.component.html',
  standalone: false
})
export class BulkExternalOfferPopupComponent implements OnDestroy {
  @Input()
  templateId: string;

  @Input()
  methodId?: string;

  @Output()
  formSubmit = new EventEmitter<CreateExternalOfferEvent>();

  @ViewChildren(ExternalOfferCreateComponent)
  trainingComponents: ExternalOfferCreateComponent[];

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;

  trainingTypes: DataLabelType[];

  private _subscriptions: (Subscription | undefined)[] = [];

  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  constructor(
    private formBuilder: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private messageService: MyMessageService
  ) {
    this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.TRAINING_TYPES).pipe(first())
      .subscribe(trainingTypes => this.trainingTypes = trainingTypes
        .filter(type => [TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL, TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND]
          .includes(type.data as TRAINING_TYPE)));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this._createFormGroup();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);

    const trainingsValid = this.trainingComponents
      .reduce((valid, current) => valid && current.isDataValid(), true);

    return trainingsValid && this.formGroup.valid;
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      trainingTemplateId: this.templateId,
      availableAsCustomTraining: [false],
      trainingTypeId: [undefined, [Validators.required]],
      trainings: this.formBuilder.array([])
    });

    this.addTraining();
  }

  submitForm(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    const data = JSON.parse(JSON.stringify(this.formGroup.value));
    data.trainings = this.trainingComponents.map(training => training.getData());

    data.trainings.forEach((training: any) => {
      training.availableAsCustomTraining = data.availableAsCustomTraining;
      training.trainingTypeId = data.trainingTypeId;
    });

    delete data.availableAsCustomTraining;

    this.formSubmit.emit({
      data: data,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  get trainings(): FormArray {
    return this.formGroup.controls['trainings'] as FormArray;
  }

  addTraining(): void {
    this.trainings.push(this.formBuilder.control({ }));
  }

  deleteTraining(index: number): void {
    this.trainings.removeAt(index);
  }
}
