import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  Signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { delay, first, forkJoin, map, Observable, shareReplay } from 'rxjs';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { conditionallyRequiredValidator } from '../../utils/conditionally-required-validator';
import { emailPattern, FilterType, FormComponent, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { getSelectedTree, mapTreeSelect } from '../../utils/tree-select-utils';
import { Dropdown } from 'primeng/dropdown';
import {
  CaseManager,
  CostType,
  Counselor,
  DataLabelType,
  TrainingFunction,
  TrainingMethod,
  TrainingSubSector,
  TrainingTitleTreeItem
} from '../../../types/reference-data.type';
import { CALCULATION_TYPE, CostTypeKey, TRAINING_TYPE } from '../../../types/reference-data.enum';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { Training } from '../../../types/training.type';
import { TrainingTemplate } from '../../../types/training-template.type';
import { TEMPLATE_TYPE } from '@alimento-ipv-frontend/application-lib';

@Component({
  selector: 'alimento-ipv-frontend-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BasicInfoComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: BasicInfoComponent
    },
    { provide: FormComponent, useExisting: BasicInfoComponent }
  ],
  standalone: false
})
export class BasicInfoComponent extends FormComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  @Input()
  showRequiredFieldErrors = false;

  @Input()
  nrOfSessionsWithDates: Signal<number> = signal(0);

  @Input()
  trainingType: TRAINING_TYPE;

  @Input()
  templateType: TEMPLATE_TYPE;

  caseManagers: CaseManager[] = [];
  caseManagerBackups: CaseManager[] = [];
  subSectors: TrainingSubSector[] = [];
  functions: TrainingFunction[] = [];
  counselors: Counselor[] = [];
  trainingTitles: TrainingTitleTreeItem[] = [];
  allCostTypes: CostType[] = [];
  costTypes: CostType[] = [];
  methods: TrainingMethod[] = [];
  letterVersions: DataLabelType[] = [];
  themes: DataLabelType[] = [];
  calculationTypes: DataLabelType[] = [];
  openTrainingCalculationTypes: DataLabelType[] = [];

  costTypesKey = CostTypeKey;
  methodReadOnly: string;
  calculationTypeReadOnly: string;
  loadingResources: Observable<any>;
  trainingTitleReadOnly: TrainingTitleTreeItem | null;
  caseManager: CaseManager;
  caseManagerBackup: CaseManager;
  counselor: Counselor;
  subSectorReadOnly: string;
  functionsReadOnly: string;
  letterVersionReadOnly: string;
  themesReadOnly: string;
  currentLanguage: string;
  teacherFilter = { type: FilterType.filterIsTeacher, values: ['true'] };

  @ViewChild('caseManagerField')
  caseManagerField: Dropdown;

  @ViewChild('caseManagerBackupField')
  caseManagerBackupField: Dropdown;

  @ViewChild('counselorField')
  counselorField: Dropdown;

  protected readonly TRAINING_TYPE = TRAINING_TYPE;
  protected readonly CALCULATION_TYPE = CALCULATION_TYPE;

  constructor(private referenceDataService: ReferenceDataService, private formBuilder: FormBuilder) {
    super();
    this._fetchReferenceData();
  }

  ngOnInit(): void {
    this._createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formGroup && changes['showRequiredFieldErrors']) {
      for (const key of Object.keys(this.formGroup.controls)) {
        this.formGroup.get(key)?.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  public static createFormData(training: Training | TrainingTemplate): any {
    return {
      customTitle: training.customTitle,
      languageCode: training.languageCode,
      rubricId: training.rubricId,
      mainTitleId: training.mainTitleId,
      trainingTitleId: training.trainingTitleId,
      letterVersionId: training.letterVersionId,
      year: training.year,
      casemanagerId: training.casemanagerId,
      casemanagerBackupId: training.casemanagerBackupId,
      counselorId: training.counselorId,
      subSectorsAndFederations: {
        subSectorIds: training.subsectorIds || [],
        federationIds: training.federationIds || []
      },
      functionIds: training.functionIds || [],
      methodId: training.methodId,
      smeWallet: training.smeWallet || false,
      smeBrochure: training.smeBrochure || false,
      minParticipants: training.minParticipants,
      maxParticipants: training.maxParticipants,
      maxParticipantsPerCompany: training.maxParticipantsPerCompany,
      availableAsCustomTraining: training.availableAsCustomTraining || false,
      cancellationFee: training.cancellationFee,
      costTypeId: training.costTypeId ? training.costTypeId : (training.typeId === TRAINING_TYPE.CEVORA ? CostTypeKey.Fixed : undefined),
      costFixed: training.costFixed !== undefined ? training.costFixed : (training.typeId === TRAINING_TYPE.CEVORA ? 87.5 : 150),
      costNonFood: training.costNonFood !== undefined ? training.costNonFood : 350,
      costFood: training.costFood !== undefined ? training.costFood : 150,
      costFoodOver100: training.costFoodOver100 !== undefined ? training.costFoodOver100 : 150,
      costCevora: training.costCevora !== undefined ? training.costCevora : 175,
      costPatroon: training.costPatroon !== undefined ? training.costPatroon : 100,
      partnerEmail: training.partnerEmail,
      partnerCollaboration: !!training.partnerCollaboration,
      themeIds: training.themeIds || [],
      mentorTraining: training.mentorTraining || false,
      calculationTypeId: training.calculationTypeId ||
        ((training.typeId === TRAINING_TYPE.OPEN_TRAINING || (training as TrainingTemplate).templateTypeId === TEMPLATE_TYPE.OPEN_TRAINING) ? CALCULATION_TYPE.DAY : null),
      maxReimbursedParticipantsPerCompany: training.maxReimbursedParticipantsPerCompany,
      reimbursementFee: training.reimbursementFee,
      reimbursementPercentage: training.reimbursementPercentage,
      teacherBranch: training.teacherBranchId,
      contactEmployments: training.contactEmploymentIds || [],
      location: training.location,
      website: training.website,
    };
  }

  override writeValue(training: Training): void {
    if (training) {
      const year = new Date();
      if (training.year) {
        year.setFullYear(training.year);
      }

      this.formGroup.patchValue({ ...training }, { emitEvent: false });

      if (this.trainingType) {
        this.formGroup.patchValue({ year: training.year ? year : undefined }, { emitEvent: false });
      }

      this.loadingResources.pipe(first()).subscribe(() => {
        if (training.languageCode) {
          this.currentLanguage = training.languageCode;
          this.referenceDataService.getTrainingTitles(training.languageCode).pipe(first())
            .subscribe(titles => {
              this.trainingTitles = titles;
              this.trainingTitleReadOnly = getSelectedTree(
                training.rubricId,
                training.mainTitleId,
                training.trainingTitleId,
                this.trainingTitles
              );

              this.formGroup.patchValue({ treeSelect: this.trainingTitleReadOnly }, { emitEvent: false });
            });
        }

        this.costTypes = this.allCostTypes.filter((type: CostType) => {
          if (this.trainingType === TRAINING_TYPE.CEVORA) {
            return type.data === CostTypeKey.Fixed;
          }
          return true;
        });
        this._setReadOnlyValues(training);
        this.closeUnselectedTreeItems();
        this.formGroup.get('maxParticipantPerCompany')?.updateValueAndValidity({ emitEvent: false });
        this.formGroup.get('maxParticipants')?.updateValueAndValidity({ emitEvent: false });
        this.formGroup.updateValueAndValidity({ emitEvent: false });
      });
    }
    else {
      if (this.trainingType === TRAINING_TYPE.OPEN_TRAINING || this.templateType === TEMPLATE_TYPE.OPEN_TRAINING) {
        this.formGroup.patchValue({
          calculationTypeId: CALCULATION_TYPE.DAY
        });
      }

      this.closeUnselectedTreeItems();
      this.loadingResources.pipe(first()).subscribe(() => {
        this.costTypes = this.allCostTypes.filter((type: CostType) => {
          if (this.trainingType === TRAINING_TYPE.CEVORA) {
            return type.data === CostTypeKey.Fixed;
          }
          return true;
        });
      });
    }
  }

  validate(): ValidationErrors | null {
    return (!this.hasErrors() && this.allRequiredFieldsFilled()(this.formGroup) === null) ? null : { basicInfoInvalid: true };
  }

  hasErrors(markAsTouched = false): boolean {
    if (this.formGroup.disabled) {
      return false;
    }

    if (markAsTouched) {
      validateAllFormFields(this.formGroup);
    }

    let isValid = true;
    Object.keys(this.formGroup.controls).forEach((field) => isValid = isValid && this.formGroup.get(field).valid);
    return !isValid;
  }

  onTreeSelectChange(): void {
    setTimeout(() => {
      const titleTree: { rubricId: string; mainTitleId: string; trainingTitleId: string } = mapTreeSelect(
        this.formGroup.get('treeSelect').value
      );
      this.formGroup.patchValue({
        rubricId: titleTree.rubricId,
        mainTitleId: titleTree.mainTitleId || undefined,
        trainingTitleId: titleTree.trainingTitleId || undefined
      });
    });
  }

  override isFieldInvalid(field: string): boolean {
    return !this.formGroup.get(field).disabled && !this.formGroup.get(field).valid && this.showRequiredFieldErrors;
  }

  private _fetchReferenceData(): void {
    this.loadingResources = forkJoin([
      this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.TRAINING_METHODS).pipe(first()),
      this.referenceDataService.getCaseManagers(true).pipe(first()),
      this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.SUB_SECTORS).pipe(first()),
      this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.TRAINING_FUNCTIONS).pipe(first()),
      this.referenceDataService.getCounselors(true).pipe(first()),
      this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.OPEN_TRAINING_COST_TYPES).pipe(first()),
      this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.LETTER_VERSIONS).pipe(first()),
      this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.THEMES).pipe(first()),
      this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.CALCULATION_TYPES).pipe(first())
    ]).pipe(shareReplay(1));

    this.loadingResources.subscribe(([methods, caseManagers, subSectors, functions, counselors, costTypes, letterVersions, themes, calculationTypes]) => {
      this.methods = methods;
      this.caseManagers = JSON.parse(JSON.stringify(caseManagers));
      this.caseManagerBackups = JSON.parse(JSON.stringify(caseManagers));
      this.subSectors = subSectors;
      this.functions = functions;
      this.counselors = counselors;
      this.allCostTypes = costTypes;
      this.letterVersions = letterVersions;
      this.themes = themes;
      this.calculationTypes = calculationTypes;
      this.openTrainingCalculationTypes = this.calculationTypes
        .filter(type => [CALCULATION_TYPE.HOUR, CALCULATION_TYPE.DAY].includes(type.data as CALCULATION_TYPE));
    });
  }

  private _createFormGroup(): void {
    const minParticipants = this.formBuilder.control(3, [
      Validators.min(0),
      Validators.max(99),
      conditionallyRequiredValidator(() => this.showRequiredFieldErrors)
    ]);
    const maxParticipantsPerCompany = this.formBuilder.control(3, [
      Validators.min(0),
      Validators.max(99),
      conditionallyRequiredValidator(() => this.showRequiredFieldErrors)
    ]);
    const maxParticipants = [10, [
      conditionallyRequiredValidator(() => this.showRequiredFieldErrors),
      Validators.min(0),
      Validators.max(999),
      this._validateMinValue(minParticipants, maxParticipantsPerCompany)
    ]];

    const fields: { [key: string]: any } = {};
    fields['costTypeId'] = [this.trainingType === TRAINING_TYPE.CEVORA ? CostTypeKey.Fixed : undefined,
      [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]];
    fields['costFixed'] = [this.trainingType === TRAINING_TYPE.CEVORA ? 87.5 : 150, [
      conditionallyRequiredValidator(
        () => this.showRequiredFieldErrors && this.formGroup?.get('costTypeId')?.value === CostTypeKey.Fixed
      ),
      Validators.min(0),
      Validators.max(9999)
    ]];
    fields['costNonFood'] = [350, [
      conditionallyRequiredValidator(
        () =>
          this.showRequiredFieldErrors &&
          (this.formGroup?.get('costTypeId')?.value === CostTypeKey.Paying ||
            this.formGroup?.get('costTypeId')?.value === CostTypeKey.NotPaying)
      ),
      Validators.min(0),
      Validators.max(9999)
    ]];
    fields['costFood'] = [150, [
      conditionallyRequiredValidator(
        () => this.showRequiredFieldErrors && this.formGroup?.get('costTypeId')?.value === CostTypeKey.Paying
      ),
      Validators.min(0),
      Validators.max(9999)
    ]];
    fields['costFoodOver100'] = [150, [
      conditionallyRequiredValidator(
        () =>
          this.showRequiredFieldErrors && this.formGroup?.get('costTypeId')?.value === CostTypeKey.NotPaying
      ),
      Validators.min(0),
      Validators.max(9999)
    ]];
    fields['costCevora'] = [175, [
      conditionallyRequiredValidator(
        () =>
          this.showRequiredFieldErrors &&
          (this.formGroup?.get('costTypeId')?.value === CostTypeKey.Paying ||
            this.formGroup?.get('costTypeId')?.value === CostTypeKey.NotPaying)
      ),
      Validators.min(0),
      Validators.max(9999)
    ]];
    fields['costPatroon'] = [150, [
      conditionallyRequiredValidator(
        () =>
          this.showRequiredFieldErrors &&
          (this.formGroup?.get('costTypeId')?.value === CostTypeKey.Paying ||
            this.formGroup?.get('costTypeId')?.value === CostTypeKey.NotPaying)
      ),
      Validators.min(0),
      Validators.max(9999)
    ]];
    fields['cancellationFee'] = [150, [Validators.min(0), Validators.max(9999)]];
    fields['calculationTypeId'] = [undefined, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]];
    fields['maxReimbursedParticipantsPerCompany'] = [undefined, [
      Validators.min(0),
      Validators.max(99),
      conditionallyRequiredValidator(() => this.showRequiredFieldErrors &&
        this.formGroup?.get('calculationTypeId')?.value !== CALCULATION_TYPE.INVOICE_PERCENTAGE)]];
    fields['reimbursementFee'] = [undefined, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors &&
      this.formGroup?.get('calculationTypeId')?.value !== CALCULATION_TYPE.INVOICE_PERCENTAGE)]];
    fields['reimbursementPercentage'] = [undefined, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors &&
      this.formGroup?.get('calculationTypeId')?.value === CALCULATION_TYPE.INVOICE_PERCENTAGE)]];
    fields['year'] = [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]];
    fields['externalReference'] = [null];
    fields['smeBrochure'] = [false];
    fields['mentorTraining'] = [false];
    fields['partnerEmail'] = [null, [emailPattern()]];
    fields['partnerCollaboration'] = [false];
    fields['letterVersionId'] = [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]];
    fields['smeWallet'] = [false];
    fields['availableAsCustomTraining'] = [false];
    fields['subSectorsAndFederations'] = [{}];
    fields['functionIds'] = [[]];
    fields['themeIds'] = [[]];
    fields['location'] = [];
    fields['website'] = [];
    fields['teacherBranch'] = [null];
    fields['contactEmployments'] = this.formBuilder.array([]);

    const formFields: any = {
      customTitle: [null, [Validators.required, Validators.maxLength(200)]],
      languageCode: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      treeSelect: [null],
      rubricId: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      mainTitleId: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      trainingTitleId: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      casemanagerBackupId: [null],
      counselorId: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      methodId: [null]
    };

    const fieldsByType: {[key: string]: string[]} = {};
    fieldsByType[TEMPLATE_TYPE.OPEN_TRAINING] = ['costTypeId', 'costFixed', 'costNonFood', 'costFood', 'costFoodOver100',
      'costCevora', 'costPatroon', 'cancellationFee', 'availableAsCustomTraining', "subSectorsAndFederations",
      'functionIds', 'themeIds', 'letterVersionId', 'smeWallet', 'calculationTypeId'];
    fieldsByType[TEMPLATE_TYPE.EXTERNAL_OFFER] = ['calculationTypeId', 'maxReimbursedParticipantsPerCompany',
      'reimbursementFee', 'reimbursementPercentage', 'availableAsCustomTraining', "subSectorsAndFederations",
      'functionIds', 'themeIds', 'mentorTraining'];
    fieldsByType[TRAINING_TYPE.OPEN_TRAINING] = ['costTypeId', 'costFixed', 'costNonFood', 'costFood',
      'costFoodOver100', 'costCevora', 'costPatroon', 'cancellationFee', 'year', 'availableAsCustomTraining',
      "subSectorsAndFederations", 'functionIds', 'themeIds', 'mentorTraining', 'partnerEmail', 'partnerCollaboration',
      'letterVersionId', 'smeWallet', 'calculationTypeId'];
    fieldsByType[TRAINING_TYPE.CEVORA] = ['costTypeId', 'costFixed', 'costNonFood', 'costFood', 'costFoodOver100',
      'costCevora', 'costPatroon', 'cancellationFee', 'year', 'externalReference', 'smeBrochure'];
    fieldsByType[TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND] = [...fieldsByType[TEMPLATE_TYPE.EXTERNAL_OFFER], "teacherBranch", "location", "website", 'contactEmployments'];
    fieldsByType[TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL] = [...fieldsByType[TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND]];

    fieldsByType[this.templateType || this.trainingType]
      .forEach(field => {
        formFields[field] = fields[field];
      });

    if (this.templateType !== TEMPLATE_TYPE.EXTERNAL_OFFER &&
      ![TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND, TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL].includes(this.trainingType)) {
      formFields.minParticipants = minParticipants;
      formFields.maxParticipants = maxParticipants;
      formFields.maxParticipantsPerCompany = maxParticipantsPerCompany;
    }

    if (this.trainingType === TRAINING_TYPE.CEVORA) {
      formFields.casemanagerId = [null, [Validators.required]];
    }
    else {
      formFields.casemanagerId = [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]];
    }

    this.formGroup = this.formBuilder.group(formFields, { validators: this.allRequiredFieldsFilled() });

    if ([TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND, TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL].includes(this.trainingType)) {
      this.addContactEmployment();
    }

    this._addSubscriptions();
  }

  private _setReadOnlyValues(training: Training): void {
    if (training.methodId) {
      this.referenceDataService.getReferenceDataItem(training.methodId, OPTIONS_LIST_TYPE.TRAINING_METHODS)
        .subscribe(method => this.methodReadOnly = method.label);
    }

    if (training.letterVersionId) {
      this.referenceDataService.getReferenceDataItem(training.letterVersionId, OPTIONS_LIST_TYPE.LETTER_VERSIONS)
        .subscribe(letterVersion => this.letterVersionReadOnly = letterVersion.label);
    }

    if (training.casemanagerId) {
      this.referenceDataService.getCaseManager(training.casemanagerId)
        .subscribe(caseManager => {
          this.caseManager = caseManager;
          if (!caseManager.isActive) {
            this.caseManagers.push(caseManager);
            this.formGroup.get('casemanagerId').setValue(training.casemanagerId, { emitEvent: false });
          }
        });
    }

    if (training.casemanagerBackupId) {
      this.referenceDataService.getCaseManager(training.casemanagerBackupId)
        .subscribe(caseManager => {
          this.caseManagerBackup = caseManager;
          if (!caseManager.isActive) {
            this.caseManagerBackups.push(caseManager);
            this.formGroup.get('casemanagerBackupId').setValue(training.casemanagerBackupId, { emitEvent: false });
          }
        });
    }

    if (training.counselorId) {
      this.referenceDataService.getCounselor(training.counselorId)
        .subscribe(counselor => {
          this.counselor = counselor;
          if (!counselor.isActive) {
            this.counselors.push(counselor);
            this.formGroup.get('counselorId').setValue(training.counselorId, { emitEvent: false });
          }
        });
    }

    if (training.functionIds) {
      this.functionsReadOnly = this.functions
        .filter(funct => training.functionIds?.includes(funct.data))
        .map(funct => funct.label)
        .join(', ');
    }

    if (training.subsectorIds) {
      this.subSectorReadOnly = this.subSectors
        .filter(subSector => training.subsectorIds?.includes(subSector.data))
        .map(subSector => subSector.label)
        .join(', ');
    }

    if (training.themeIds) {
      this.themesReadOnly = this.themes
        .filter(theme => training.themeIds?.includes(theme.data))
        .map(theme => theme.label)
        .join(', ');
    }

    if (training.calculationTypeId) {
      this.referenceDataService.getReferenceDataItem(training.calculationTypeId, OPTIONS_LIST_TYPE.CALCULATION_TYPES)
        .subscribe(calculationType => this.calculationTypeReadOnly = calculationType.label);
    }
  }

  private _addSubscriptions(): void {
    this.subscriptions.push(
      this.formGroup.get('minParticipants')?.valueChanges.subscribe(() => {
        this.formGroup.get('maxParticipants')?.updateValueAndValidity({ emitEvent: false });
      }),
      this.formGroup.get('maxParticipantsPerCompany')?.valueChanges.subscribe(() => {
        this.formGroup.get('maxParticipants')?.updateValueAndValidity({ emitEvent: false });
      }),
      this.formGroup.get('counselorId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCounselor(newValue)
          .subscribe(counselor => this.counselor = counselor);
      }),
      this.formGroup.get('casemanagerId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCaseManager(newValue)
          .subscribe(caseManager => this.caseManager = caseManager);
      }),
      this.formGroup.get('casemanagerBackupId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCaseManager(newValue)
          .subscribe(caseManager => this.caseManagerBackup = caseManager);
      }),
      this.formGroup.get('languageCode')?.valueChanges.subscribe(newValue => {
        if (newValue && this.currentLanguage !== newValue) {
          this.currentLanguage = newValue;
          this.referenceDataService.getTrainingTitles(newValue).pipe(first())
            .subscribe(titles => {
              this.trainingTitles = titles;
              this.formGroup.get('treeSelect').setValue(null, {emitEvent: false});
              this.onTreeSelectChange();
            });
        }
      }),
      this.formGroup.get('partnerCollaboration')?.valueChanges.subscribe(newValue => {
        this.formGroup.get('partnerEmail').setValidators(newValue ? [Validators.required, emailPattern()] : [emailPattern()]);
        this.formGroup.get('partnerEmail').updateValueAndValidity({ emitEvent: false });
      }),
      this.formGroup.get("teacherBranch")?.valueChanges.subscribe(branch => {
        while (this.contactEmployments.length > 0) {
          this.deleteContactEmployment(this.contactEmployments.length - 1);
        }
        this.addContactEmployment();

        if (branch) {
          this.formGroup.get("website").setValue(branch.website);
        }
      })
    );

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(delay(1)).subscribe((value) => {
        const data = { ...value };
        delete data.treeSelect;

        data.subsectorIds = data.subSectorsAndFederations?.subSectorIds || [];
        data.federationIds = data.subSectorsAndFederations?.federationIds || [];
        delete data.subSectorsAndFederations;

        if (data.teacherBranch) {
          data.teacherBranchId = data.teacherBranch.branchId || data.teacherBranch;
          delete data.teacherBranch;
        }

        if (data.contactEmployments) {
          data.contactEmploymentIds = data.contactEmployments
            .filter((contactEmployment: any) => contactEmployment)
            .map((contactEmployment: any) => contactEmployment?.employmentId || contactEmployment);
          delete data.contactEmployments;
        }

        if (data.year) {
          data.year = data.year.getFullYear();
        }
        const result = JSON.parse(JSON.stringify(data));
        this.onChange(result);
        this.onTouched();
      })
    );
  }

  private allRequiredFieldsFilled(): ValidatorFn {
    return (control: AbstractControl) => {
      const controlValue = control.value;

      let year = controlValue?.year;
      if (year instanceof Date) {
        year = year.getFullYear();
      }

      let subControl;
      if (this.templateType === TEMPLATE_TYPE.EXTERNAL_OFFER ||
        this.trainingType === TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND ||
        this.trainingType === TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL) {
        subControl = this._calculationFieldValid(controlValue) &&
          !!controlValue?.customTitle;
      }
      else {
        subControl = controlValue?.minParticipants !== null &&
          controlValue?.minParticipants <= 99 &&
          controlValue?.maxParticipants !== null &&
          controlValue?.maxParticipants <= 999 &&
          controlValue?.maxParticipantsPerCompany !== null &&
          controlValue?.maxParticipantsPerCompany <= 99 &&
          !!controlValue?.costTypeId &&
          this._costFieldsValid(controlValue) &&
          (this.trainingType === TRAINING_TYPE.CEVORA || !!controlValue?.letterVersionId) &&
          (this.templateType || (year !== null && year > 1700)) &&
          !!controlValue?.customTitle;
      }

      let isValid;
      if (this.trainingType === TRAINING_TYPE.CEVORA) {
        isValid = !!controlValue?.casemanagerId && subControl;
      }
      else {
        isValid =
          !!controlValue?.languageCode &&
          !!controlValue?.mainTitleId &&
          !!controlValue?.rubricId &&
          !!controlValue?.trainingTitleId &&
          !!controlValue?.casemanagerId &&
          !!controlValue?.counselorId &&
          subControl;

        if (this.trainingType === TRAINING_TYPE.OPEN_TRAINING && controlValue?.partnerCollaboration) {
          isValid = isValid && !!controlValue.partnerEmail;
        }
      }

      return isValid ? null : { required: true };
    };
  }

  private _costFieldsValid(value?: any): boolean {
    if (value?.costTypeId === CostTypeKey.Fixed) {
      return value?.costFixed >= 0 && value?.costFixed <= 9999;
    }
    else if (value?.costTypeId === CostTypeKey.NotPaying) {
      return (
        value?.costNonFood >= 0 &&
        value?.costFoodOver100 >= 0 &&
        value?.costCevora >= 0 &&
        value?.costPatroon >= 0 &&
        value?.costNonFood <= 9999 &&
        value?.costFoodOver100 <= 9999 &&
        value?.costCevora <= 9999 &&
        value?.costPatroon <= 9999
      );
    }
    else if (value?.costTypeId === CostTypeKey.Paying) {
      return (
        value?.costNonFood >= 0 &&
        value?.costFood >= 0 &&
        value?.costCevora >= 0 &&
        value?.costPatroon >= 0 &&
        value?.costNonFood <= 9999 &&
        value?.costFood <= 9999 &&
        value?.costCevora <= 9999 &&
        value?.costPatroon <= 9999
      );
    }

    return true;
  }

  private _calculationFieldValid(value?: any): boolean {
    if (value?.calculationTypeId === CALCULATION_TYPE.NO_REIMBURSEMENT) {
      return true;
    }
    if (value?.calculationTypeId === CALCULATION_TYPE.INVOICE_PERCENTAGE) {
      return value?.reimbursementPercentage >= 0 && value?.reimbursementPercentage <= 100;
    }
    else {
      return value?.reimbursementFee >= 0;
    }
  }

  private _validateMinValue(minParticipantsControl: any, maxParticipantsPerCompanyControl: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const errors = { lowerThanMinParticipants: false, lowerThanMaxParticipantsPerCompany: false };

      if (control.value) {
        if (minParticipantsControl.value && control.value < minParticipantsControl.value) {
          errors.lowerThanMinParticipants = true;
        }

        if (maxParticipantsPerCompanyControl.value && control.value < maxParticipantsPerCompanyControl.value) {
          errors.lowerThanMaxParticipantsPerCompany = true;
        }
      }

      if (errors.lowerThanMinParticipants || errors.lowerThanMaxParticipantsPerCompany) {
        return errors;
      }

      return null;
    };
  }

  closeUnselectedTreeItems(): void {
    const selectedItem = this.formGroup.get('treeSelect')?.value;
    this.trainingTitles.forEach(treeItem => {
      treeItem.expanded = selectedItem?.parent?.parent?.data === treeItem.data;
      treeItem.children.forEach(child => {
        child.expanded = selectedItem?.parent?.data === child.data;
      });
    });
  }

  hasInactiveCaseManagersOrCounselors(): boolean {
    return (this.caseManager && !this.caseManager.isActive) ||
      (this.caseManagerBackup && !this.caseManagerBackup.isActive) ||
      (this.counselor && !this.counselor.isActive);
  }

  focusFirstInactiveCaseManager(): void {
    if (this.caseManager && !this.caseManager.isActive) {
      this.caseManagerField?.focus();
    }
    else if (this.caseManagerBackup && !this.caseManagerBackup.isActive) {
      this.caseManagerBackupField?.focus();
    }
    else if (this.counselor && !this.counselor.isActive) {
      this.counselorField?.focus();
    }
  }

  getRecognitionType(educationLeaveRecognitionTypeId: string): Observable<string> {
    return this.referenceDataService.getReferenceDataItem(educationLeaveRecognitionTypeId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES)
      .pipe(map(item => item.label));
  }

  get contactEmployments(): FormArray {
    return this.formGroup.controls['contactEmployments'] as FormArray;
  }

  addContactEmployment() {
    this.contactEmployments.push(this.formBuilder.control(undefined));
  }

  deleteContactEmployment(index: number) {
    this.contactEmployments.removeAt(index);
  }

  protected readonly TEMPLATE_TYPE = TEMPLATE_TYPE;
}
