import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { ContactPerson, TrainingPlan, TrainingPlanReminderEvent } from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';

@Component({
  selector: 'alimento-ipv-frontend-training-plan-reminder-popup',
  templateUrl: './training-plan-reminder-popup.component.html',
  standalone: false
})
export class TrainingPlanReminderPopupComponent implements OnDestroy {
  @Input()
  branchId?: string;

  @Output()
  formSubmit = new EventEmitter<TrainingPlanReminderEvent>();

  contactPersons: ContactPerson[] = [];
  trainingPlan: TrainingPlan;
  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;

  private _subscriptions: (Subscription | undefined)[] = [];

  constructor(private fb: FormBuilder,
              private branchService: BranchService,
              private messageService: MyMessageService) {
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(trainingPlan: TrainingPlan): void {
    this.branchService.getContactPersons(this.branchId)
      .subscribe(contactPersons => {
        this.contactPersons = contactPersons;
        this.popupVisible = true;
        this.trainingPlan = trainingPlan;
        this.createForm();
      });
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getFormData(): any {
    return JSON.parse(JSON.stringify(this.formGroup.value));
  }

  submitPopup(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      data: this.getFormData(),
      trainingPlanId: this.trainingPlan.id,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      contactPersonId: [this.trainingPlan.contactPersonId, [Validators.required, this.validContactPerson()]]
    });
  }

  private validContactPerson(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const person = this.contactPersons
          .filter(contactPerson => contactPerson.personId === control.value)[0];
        if (!person?.email) {
          return { invalidContactPerson: true };
        }
      }
      return null;
    };
  }
}
