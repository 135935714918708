export enum TrainingPlanActionEnum {
  edit,
  approve,
  reject,
  register,
  reminder,
  delete,
  history
}

export enum TRAINING_PLAN_STATE {
  REQUESTED = "ac857c15-a313-44c1-9ea1-c2eace92cba6",
  RECEIVED = "b7e8db30-3df2-4fd0-af59-12933dbbf2d7",
  APPROVED = "0a6a932f-3973-4ded-879b-2ae678b1768b",
  REJECTED = "4b1a19d3-a4ce-4bb3-ab19-04cd880b977d"
}

export enum TRAINING_PLAN_ACTION {
  request = <any>"f6376cd0-9fc8-4357-af6a-05376bd9d821",
  send = <any>"1a6d3898-2fac-4d1f-9e63-60b2526839e9",
  approve = <any>"1716aec7-a0de-45b1-ba63-2f802e319b90",
  reject = <any>"75767db8-8b2c-4f9c-afc9-e165c52f989d",
  sendReminder = <any>"a3ce7ab9-dfc3-4fbd-b315-03abfdd7c664"
}

export enum BranchActionEnum {
  setAsMainBranch
}

export enum RESPONSIBILITY {
  BEV = "fc0f2c74-c8a4-4ef3-89e6-bee63f9e463a"
}

export enum BRANCH_SORT_FIELDS {
  ALIMENTO_ID = 'alimentoid',
  BRANCH_NAME = 'name',
}
