<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [closable]="true"
  [focusOnShow]='false'
  [style]='{width: "80vw"}'
  appendTo="body"
  header="{{
    (readOnly ? 'trainings.salesInvoices.viewInvoice' : 'trainings.salesInvoices.editInvoice') | translate
  }}"
>
  <div *ngIf="salesInvoice" [formGroup]="formGroup">

    <div class='conditional-fields'>
      <h2 class='mb-2 text-lg'>{{'trainings.salesInvoices.enrollmentInfo' | translate}}</h2>
      <div class='pb-4 grid grid-cols-3 gap-4'>
        <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                             [header]='"trainings.salesInvoices.branch"'
        >
          <div class='flex flex-col'>
            <span>{{branch?.branchName}}</span>
            <span *ngIf='branchAddress'>{{branchAddress}}</span>
          </div>
        </alimento-ipv-frontend-metadata-item>

        <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                             [header]='"trainings.salesInvoices.isFood"'
                                             [value]='(branch?.isFood ? "yes" : "no") | translate'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                             [header]='"trainings.salesInvoices.nrOfEmployees"'
                                             [value]='"" + (branch?.numberOfWorkersPC118 + branch?.numberOfClerksPC220)'
        ></alimento-ipv-frontend-metadata-item>


        <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                             [header]='"trainings.salesInvoices.invoiceNameAndAddress"'
        >
          <div class='flex flex-col'>
            <span>{{enrollment?.enrollment?.invoiceName || branch.branchName}}</span>
            <span *ngIf='invoiceAddress'>{{invoiceAddress}}</span>
          </div>
        </alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item *ngIf="!branch"
                                             [header]='"trainings.salesInvoices.invoiceNameAndAddress"'
                                             value='{{enrollment?.person?.firstName + " " + enrollment?.person?.lastName}} - {{personAddress}}'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item [header]='"trainings.salesInvoices.invoiceEmail"'
                                             [value]='salesInvoice?.invoiceEmail || " "'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                             [header]='"trainings.salesInvoices.invoiceReference"'
                                             [value]='enrollment?.enrollment?.invoiceReference || " "'
        ></alimento-ipv-frontend-metadata-item>
        <div *ngIf='!branch'></div>


        <alimento-ipv-frontend-metadata-item [header]='"trainings.salesInvoices.participantName"'
                                             [value]='enrollment?.person?.firstName + " " + enrollment?.person?.lastName'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item [header]='"trainings.salesInvoices.participantWorkStatus"'
                                             [value]='enrollment?.employment?.workStatus?.label'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item [header]='"trainings.salesInvoices.trainingCostAtEnrollment"'>
          <div *ngIf='!editCostVisible' class='flex items-center gap-4'>
            <span>{{(enrollment?.enrollment?.cost || 0) | currency:"EUR"}}</span>
            <p-button (onClick)="editCostVisible = true"
                      label="{{ 'edit' | translate }}"
                      variant='text'
            ></p-button>
          </div>
          <div *ngIf='editCostVisible'>
            <div class='flex items-center flex-wrap gap-2'>
              <p-inputNumber inputStyleClass='w-32'
                             [formControl]='enrollmentCostControl'
                             [maxFractionDigits]='2'
                             mode="currency"
                             currency="EUR"
                             locale='en-US'
                             id='enrollmentCost'>
              </p-inputNumber>
              <p-button (onClick)="updateEnrollmentCost()"
                        [loading]='updatingEnrollmentCost'
                        label="{{ 'validation.saveChanges' | translate }}"
              ></p-button>
              <p-button (onClick)="editCostVisible = false"
                        label="{{ 'cancel' | translate }}"
                        variant='text'
              ></p-button>
            </div>
            <div class='text-danger'>
              <small *ngIf="enrollmentCostControl?.hasError('min')">
                {{ 'validation.minValue' | translate : { number: 0 | currency:"EUR"} }}
              </small>
              <small *ngIf="enrollmentCostControl?.hasError('required')">
                {{'validation.isRequired'| translate : { field: 'trainings.salesInvoices.trainingCostAtEnrollment' | translate } }}
              </small>
            </div>
          </div>
        </alimento-ipv-frontend-metadata-item>
      </div>
    </div>

    <div class='conditional-fields'>
      <div class='pb-4 grid grid-cols-3 gap-4'>
        <alimento-ipv-frontend-metadata-item [header]='"trainings.salesInvoices.glAccount"'
                                             [value]='salesInvoice.glAccount || " "'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item [header]='"trainings.salesInvoices.reference"'
                                             [value]='salesInvoice.reference || " "'
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item [header]='"trainings.salesInvoices.invoiceAmount"'
                                             [value]='amount | currency:"EUR"'
        ></alimento-ipv-frontend-metadata-item>
      </div>

      <alimento-ipv-frontend-metadata-item *ngIf='branch?.administrativeRules'
                                           [header]='"branches.administrativeRules"'>
        <div class='flex flex-col items-start'>
          <div #content
               class='text-text whitespace-pre-line text-[16px]'
               [ngClass]='{ "h-full overflow-auto": showMoreAdministrativeRules,
              "max-h-[72px] overflow-hidden": !showMoreAdministrativeRules
              }'
               [innerHTML]='branch.administrativeRules'
          ></div>
          <button *ngIf='showMoreAdministrativeRules || hasOverflow(content)'
                  class='link-button font-bold'
                  (click)="toggleShowMoreAdministrativeRules()">
            {{ (showMoreAdministrativeRules ? 'notes.showLess': 'notes.showMore') | translate }}
          </button>
        </div>
      </alimento-ipv-frontend-metadata-item>
    </div>

<!--    <alimento-ipv-frontend-form-field [formGroup]='formGroup'-->
<!--                                      [required]='true'-->
<!--                                      formField='reimbursementTypeId'-->
<!--                                      fieldKey='reimbursements.type'-->
<!--    >-->
<!--      <span class='read-only-field' *ngIf='reimbursement?.reimbursementId'>{{type}}</span>-->
<!--      <p-select-->
<!--        *ngIf='!reimbursement?.reimbursementId'-->
<!--        [options]='allowedTypes$ | async'-->
<!--        [showClear]='true'-->
<!--        formControlName='reimbursementTypeId'-->
<!--        id='reimbursementTypeId'-->
<!--        optionLabel='label'-->
<!--        optionValue='data'-->
<!--        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"-->
<!--        styleClass='w-full'-->
<!--        appendTo='body'-->
<!--      ></p-select>-->
<!--    </alimento-ipv-frontend-form-field>-->

<!--    <alimento-ipv-frontend-form-field [formGroup]='formGroup'-->
<!--                                      [required]='true'-->
<!--                                      *ngIf='formGroup.get("reimbursementTypeId").value === REIMBURSEMENT_TYPE.TRANSITION'-->
<!--                                      fieldKey='reimbursement.transition'-->
<!--                                      formField='transitionId'-->
<!--    >-->
<!--      <div *ngIf='reimbursement?.reimbursementId && selectedTransition'>-->
<!--        <ng-container *ngTemplateOutlet='transitionTemplate; context: {transition: selectedTransition}'></ng-container>-->
<!--      </div>-->
<!--      <p-select-->
<!--        *ngIf='!reimbursement?.reimbursementId'-->
<!--        [options]="extraInfo.reimbursementTransitions"-->
<!--        appendTo="body"-->
<!--        formControlName="transitionId"-->
<!--        id="transitionId"-->
<!--        optionValue="transitionId"-->
<!--        styleClass="w-full"-->
<!--        placeholder="{{ 'enrollments.chooseOption' | translate }}"-->
<!--        [showClear]="true"-->
<!--      >-->
<!--        <ng-template let-item pTemplate="selectedItem">-->
<!--          <div class='mr-8'>-->
<!--            <ng-container *ngTemplateOutlet='transitionTemplate; context: {transition: item}'></ng-container>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--        <ng-template let-item pTemplate="item">-->
<!--          <ng-container *ngTemplateOutlet='transitionTemplate; context: {transition: item}'></ng-container>-->
<!--        </ng-template>-->
<!--      </p-select>-->

<!--      <ng-template #transitionTemplate let-item="transition">-->
<!--        <div class='flex items-center justify-between gap-4'>-->
<!--          <div class='flex flex-col'>-->
<!--            <span>{{item.transitionPersonName}}</span>-->
<!--            <span class='text-grey-3'>{{getTransitionType(item.transitionTypeId) | async}}</span>-->
<!--          </div>-->
<!--          <div class='flex flex-col items-end'>-->
<!--            <span>{{'persons.transitions.totalBudget' | translate}}: {{item.transitionBalance | currency:'EUR'}}</span>-->
<!--            <span>{{'reimbursements.maxAmount' | translate}}: {{item.reimbursementMaxAmount | currency:'EUR'}}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </alimento-ipv-frontend-form-field>-->

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='branch?.invoiceReferenceRequired || false'
                                      [fieldKey]='branch ? "trainings.salesInvoices.invoiceReference" : "trainings.salesInvoices.reference"'
                                      formField='invoiceReference'>
      <input *ngIf='formGroup.enabled' class="w-full" formControlName="invoiceReference" id="invoiceReference" pInputText type="text" />
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{salesInvoice.invoiceReference || " "}}</span>
    </alimento-ipv-frontend-form-field>

    <div class='flex justify-between items-center'>
      <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                        [required]='true'
                                        fieldKey='trainings.salesInvoices.trainingCostAtInvoice'>
        <span class='read-only-field'>{{salesInvoice.cost | currency:"EUR"}}</span>
      </alimento-ipv-frontend-form-field>

      <div>
        <span>{{salesInvoice.cost | currency:"EUR"}}</span>
      </div>
    </div>

    <div class='flex justify-end' *ngIf='amount !== salesInvoice.cost'>
      <div class='flex justify-between items-center w-60'>
        <span>{{'trainings.salesInvoices.otherInvoices' | translate}}</span>
        <span>{{(amount - salesInvoice.cost) | currency:"EUR"}}</span>
      </div>
    </div>

    <div class='flex justify-end font-bold'>
      <div class='flex justify-between items-center w-60 border-t border-t-black'>
        <span>{{'trainings.salesInvoices.invoiceAmount' | translate}}</span>
        <span>{{amount | currency:"EUR"}}</span>
      </div>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='notes'
      fieldKey='trainings.salesInvoices.internalNotes'
    >
      <textarea
        *ngIf='formGroup.enabled'
        class="w-full"
        formControlName="notes"
        id="notes"
        pTextarea
        rows="2"
      ></textarea>
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{salesInvoice.notes}}</span>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center justify-between gap-2 w-full">
      <div class='flex items-center gap-2'>
        <p-button
          *ngIf="!readOnly"
          [loading]="loading"
          (onClick)="updateSalesInvoice(false, false)"
          label="{{'trainings.salesInvoices.save' | translate }}"
        ></p-button>
        <p-button
          *ngIf="!readOnly"
          [loading]="loading"
          (onClick)="updateSalesInvoice(true, false)"
          label="{{'trainings.salesInvoices.saveAndNext' | translate }}"
        ></p-button>
        <p-button
          *ngIf="!readOnly"
          [loading]="loading"
          (onClick)="updateSalesInvoice(false, true)"
          label="{{'trainings.salesInvoices.approve' | translate }}"
        ></p-button>
        <p-button
          (onClick)="closePopup()"
          label="{{ 'reimbursements.cancel' | translate }}"
          variant='text'
        ></p-button>
      </div>
      <div class='flex items-center gap-2'>
        <p-button label='{{ "trainings.salesInvoices.previousInvoice" | translate}}'
                  (click)='changeSelectedInvoiceIndex.emit(-1)'
                  variant='text'
        ></p-button>
        <p-button label='{{ "trainings.salesInvoices.nextInvoice" | translate}}'
                  (click)='changeSelectedInvoiceIndex.emit(1)'
                  variant='text'
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
