import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PurchaseEntry, PurchaseEntryReference } from '../../../types/training.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { first } from 'rxjs';
import { SimpleBranch } from '@alimento-ipv-frontend/application-lib';

@Component({
    selector: 'alimento-ipv-frontend-purchase-entry-card',
    templateUrl: './purchase-entry-card.component.html',
    standalone: false
})
export class PurchaseEntryCardComponent implements OnChanges {
  @Input()
  purchaseEntry: PurchaseEntry;

  @Input()
  purchaseEntryReference: PurchaseEntryReference;

  @Input()
  readOnly = false;

  status: string;
  supplierBranch: SimpleBranch;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['purchaseEntry']?.currentValue && this.purchaseEntry.statusId) {
      this.referenceDataService.getPurchaseEntryStatus(this.purchaseEntry.statusId).pipe(first())
        .subscribe(status => this.status = status.label);
    }
    this.supplierBranch = this.purchaseEntry?.supplierBranch || this.purchaseEntryReference?.supplierBranch;
  }
}
