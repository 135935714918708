import { NgModule } from '@angular/core';
import { TrainingListComponent } from './pages/training-list/training-list.component';
import { TrainingDetailComponent } from './pages/training-detail/training-detail.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { ConfirmationService } from 'primeng/api';
import { CancelTrainingDialogComponent } from './components/cancel-training-dialog/cancel-training-dialog.component';
import { CloseTrainingDialogComponent } from './components/close-training-dialog/close-training-dialog.component';
import {
  CommunicateChangesDialogComponent
} from './components/communicate-changes-dialog/communicate-changes-dialog.component';
import {
  ParticipantsListDialogComponent
} from './components/participants-list-dialog/participants-list-dialog.component';
import { CertificatesDialogComponent } from './components/certificates-dialog/certificates-dialog.component';
import {
  ApproveEnrollmentsDialogComponent
} from './components/approve-enrollments-dialog/approve-enrollments-dialog.component';
import {
  TrainingCommunicationsComponent
} from './components/training-communications/training-communications.component';
import { UtilsModule } from '../utils/utils.module';
import { SessionsModule } from '../sessions/sessions.module';
import { EnrollmentsModule } from '../enrollments/enrollments.module';
import { CommunicationsModule } from '../communications/communications.module';
import {
  ReactivateTrainingDialogComponent
} from './components/reactivate-training-dialog/reactivate-training-dialog.component';
import { CevoraTrainingDetailComponent } from './pages/cevora-training-detail/cevora-training-detail.component';
import { BasicTrainingComponent } from './components/basic-training/basic-training.component';
import {
  FinancialDocumentPopupComponent
} from './components/financial-document-popup/financial-document-popup.component';
import { FinancialDocumentCardComponent } from './components/financial-document-card/financial-document-card.component';
import {
  ReimbursementRequestTrainingDetailComponent
} from './pages/reimbursement-request-training-detail/reimbursement-request-training-detail.component';
import { TrainingProgramModule } from '../training-programs/training-program.module';
import { TrainingProjectComponent } from './components/training-project/training-project.component';
import {
  FinancialDocumentsOverviewComponent
} from './components/financial-documents-overview/financial-documents-overview.component';
import {
  ReimbursementsOverviewComponent
} from './components/reimbursements-overview/reimbursements-overview.component';
import { ReimbursementPopupComponent } from './components/reimbursement-popup/reimbursement-popup.component';
import { ReimbursementCardComponent } from './components/reimbursement-card/reimbursement-card.component';
import {
  ReimbursementActionDialogComponent
} from './components/reimbursement-action-dialog/reimbursement-action-dialog.component';
import { InvoicesOverviewComponent } from './components/invoices-overview/invoices-overview.component';
import { PurchaseEntryCardComponent } from './components/purchase-entry-card/purchase-entry-card.component';
import {
  PurchaseEntryCreateDialogComponent
} from './components/purchase-entry-create-dialog/purchase-entry-create-dialog.component';
import {
  ReimbursementCorrectionPopupComponent
} from './components/reimbursement-correction-popup/reimbursement-correction-popup.component';
import { SalesInvoiceCardComponent } from './components/sales-invoice-card/sales-invoice-card.component';
import {
  SalesInvoiceActionDialogComponent
} from './components/sales-invoice-action-dialog/sales-invoice-action-dialog.component';
import { SalesInvoicePopupComponent } from './components/sales-invoice-popup/sales-invoice-popup.component';
import { CloneTrainingDialogComponent } from './components/clone-training-dialog/clone-training-dialog.component';
import { MoveTrainingDialogComponent } from './components/move-training-dialog/move-training-dialog.component';
import { PersonsModule } from '../persons/persons.module';
import {
  ExternalOfferTrainingDetailComponent
} from './pages/external-offer-training-detail/external-offer-training-detail.component';

@NgModule({
  imports: [
    UiLibModule,
    UtilsModule,
    UtilsModule,
    SessionsModule,
    EnrollmentsModule,
    CommunicationsModule,
    TrainingProgramModule,
    PersonsModule
  ],
  exports: [
    UtilsModule,
    ExternalOfferTrainingDetailComponent
  ],
  providers: [
    ConfirmationService,
    CanDeactivateGuard
  ],
  declarations: [
    TrainingListComponent,
    TrainingDetailComponent,
    CancelTrainingDialogComponent,
    CloseTrainingDialogComponent,
    CommunicateChangesDialogComponent,
    ParticipantsListDialogComponent,
    CertificatesDialogComponent,
    ApproveEnrollmentsDialogComponent,
    TrainingCommunicationsComponent,
    ReactivateTrainingDialogComponent,
    CevoraTrainingDetailComponent,
    BasicTrainingComponent,
    FinancialDocumentPopupComponent,
    FinancialDocumentCardComponent,
    ReimbursementRequestTrainingDetailComponent,
    TrainingProjectComponent,
    FinancialDocumentsOverviewComponent,
    ReimbursementsOverviewComponent,
    ReimbursementPopupComponent,
    ReimbursementCardComponent,
    ReimbursementActionDialogComponent,
    InvoicesOverviewComponent,
    PurchaseEntryCardComponent,
    PurchaseEntryCreateDialogComponent,
    ReimbursementCorrectionPopupComponent,
    SalesInvoiceCardComponent,
    SalesInvoiceActionDialogComponent,
    SalesInvoicePopupComponent,
    CloneTrainingDialogComponent,
    MoveTrainingDialogComponent,
    ExternalOfferTrainingDetailComponent
  ]
})
export class TrainingsModule {
}
