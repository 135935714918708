export enum FEATURE {
}

export enum NOTES_TYPE {
  TrainingNote = 0,
  PersonNote = 1,
  BranchNote = 2,
  AllowanceApplicationNote = 3,
  EnterpriseNote = 4,
  BranchTrainingPlanNote = 5,
  PaidEducationalLeaveNote = 6,
  TrainingTemplateNote = 7
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc'
}

export const NO_BRANCH_ID = "00000001-0001-0001-0001-000000000001";
export const NO_ENTERPRISE_ID = "00000002-0002-0002-0002-000000000002";

export enum HISTORY_TYPE {
  TRAINING_PLAN = "trainingPlan",
  ENTERPRISE_GROUP = "enterpriseGroup"
}

export const MAX_FETCH_COUNT = 3;
