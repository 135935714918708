import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SearchEnterpriseItem } from '../../../types/enterprise.type';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';
import { MenuItem } from 'primeng/api';
import { MenuItemUtilsService } from '@alimento-ipv-frontend/ui-lib';
import { ENTERPRISE_ACTION } from '../../../types/enterprise.enum';

@Component({
    selector: 'alimento-ipv-frontend-enterprise-card',
    templateUrl: './enterprise-card.component.html',
    standalone: false
})
export class EnterpriseCardComponent implements OnChanges {
  @Input()
  enterprise: SearchEnterpriseItem;

  @Input()
  isMainEnterprise = false;

  @Input()
  showActions = false;

  @Input()
  selected = false;

  @Output()
  actionClicked: EventEmitter<ENTERPRISE_ACTION> = new EventEmitter<ENTERPRISE_ACTION>();

  actions: MenuItem[];

  constructor(public branchesMapper: BranchesMapper,
              private menuItemUtilsService: MenuItemUtilsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enterprise']?.currentValue) {
      this._setMenuItems();
    }
  }

  private _setMenuItems(): void {
    this.actions = [];

    if (this.showActions) {
      if (!this.isMainEnterprise) {
        const setAsMainEnterprise = this.menuItemUtilsService.getMenuItem(
          'enterpriseGroups.setAsMainEnterprise',
          () => {
            this.actionClicked.emit(ENTERPRISE_ACTION.setAsMainEnterprise);
          }
        );
        this.actions.push(setAsMainEnterprise);

        const removeItem = this.menuItemUtilsService.getMenuItem(
          'enterpriseGroups.removeEnterprise',
          () => {
            this.actionClicked.emit(ENTERPRISE_ACTION.delete);
          },
          false,
          'pi pi-times'
        );
        removeItem.styleClass = 'danger';
        this.actions.push(removeItem);
      }
    }
  }

}
