import {
  Component,
  effect,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  flattenObject,
  LeaveConfirmService,
  MyMessageService,
  NavigationService,
  NOTES_TYPE,
  NoteService,
  NotesSidebarComponent,
  Role,
  TabMenuItem,
  TitleService,
  TranslationService,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { first, forkJoin, Observable, Subscription, tap } from 'rxjs';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CancelTrainingDialogComponent
} from '../../components/cancel-training-dialog/cancel-training-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { BasicInfoComponent } from '../../../utils/components/basic-info/basic-info.component';
import {
  DOCUMENT_SET_TYPE,
  EXTERNAL_OFFER_TRAINING_BULK_ACTION,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../../../types/reference-data.enum';
import {
  CancelTrainingEvent,
  CloseTraining,
  ReactivateTraining,
  Training,
  TrainingProject
} from '../../../types/training.type';
import { TrainingService } from '../../../services/training.service';
import { SessionService } from '../../../services/session.service';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { DocumentService } from '../../../services/document.service';
import {
  SummaryDescriptionComponent
} from '../../../utils/components/summary-description/summary-description.component';
import { TrainingMapper } from '../../../utils/mapper/training.mapper';
import { TrainingTemplateService } from '../../../services/training-template.service';
import { TrainingTemplate } from '../../../types/training-template.type';

@Component({
  selector: 'alimento-ipv-frontend-external-offer-training-detail',
  templateUrl: './external-offer-training-detail.component.html',
  standalone: false
})
export class ExternalOfferTrainingDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('basicInfoComponent')
  basicInfoComponent!: BasicInfoComponent;

  @ViewChild('summaryDescriptionComponent')
  summaryDescriptionComponent!: SummaryDescriptionComponent;

  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @Input()
  readOnly = true;

  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  trainingForm: FormGroup;
  trainingId!: string;
  type: TRAINING_TYPE;
  typeLabel: string;
  trainingStatus: TrainingStatusKey = TrainingStatusKey.Draft;
  training: WritableSignal<Training> = signal(undefined);
  template: TrainingTemplate;
  savingTraining = false;
  loading = false;
  methodId: string;
  trainingStatusKeys = TrainingStatusKey;
  firstSessionDate?: Date;
  closingTraining = false;
  reactivatingTraining: boolean;
  canCloseTraining = false;
  showNotes = false;
  notesHasChanges = false;
  reasonCancelled: string;
  TrainingNote = NOTES_TYPE.TrainingNote;
  publishedToggle = new FormControl(false);
  documentCount: WritableSignal<number> = signal(undefined);
  notesCount: WritableSignal<number> = signal(undefined);
  breadcrumb: MenuItem[];

  private _subscriptions: Subscription[] = [];
  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  constructor(
    private fb: FormBuilder,
    private trainingService: TrainingService,
    private messageService: MyMessageService,
    private confirmationService: ConfirmationService,
    private leaveConfirmationService: LeaveConfirmService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private translationService: TranslationService,
    public sessionService: SessionService,
    private authorizationService: AuthorizationService,
    private titleService: TitleService,
    private referenceDataService: ReferenceDataService,
    private documentService: DocumentService,
    private noteService: NoteService,
    private trainingMapper: TrainingMapper,
    private templateService: TrainingTemplateService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();

    effect(() => {
      const firstSessionWithDate = this.sessionService.sessions().filter(session => session.date)[0];
      this.firstSessionDate = firstSessionWithDate ? new Date(firstSessionWithDate.date) : undefined;
    });

    effect(() => {
      if (this.training()) {
        this.titleService.setTitle(
          [`${this.translateService.instant('titles.trainings.detail')} - ${this.training().customTitle}`]);
        if (this.training().reasonCancelledId) {
          this.referenceDataService.getCancelTrainingReason(this.training().reasonCancelledId)
            .subscribe(reason => this.reasonCancelled = reason.label);
        }
        else {
          this.reasonCancelled = undefined;
        }
        this.canCloseTraining = this.training().status.data === TrainingStatusKey.Active;
        this._setPublishToggleDataAndState();
      }
      this._createMenuItems();
      this._createBreadcrumb();
    });
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this.route.params.subscribe(params => {
        forkJoin([this.route.data.pipe(first()), this.route.queryParams.pipe(first())])
          .subscribe(([data, queryParams]) => {
            this.trainingId = params['trainingId'];
            this.type = data['trainingType'];

            if (this.trainingId) {
              this.loadTrainingData();
              this.sessionService.loadSessions(this.trainingId, this.type);
            }
            else {
              this._createTrainingForm();
            }
          });
      })
    );

    this._subscriptions.push(
      this.route.queryParams.subscribe(
        queryParams => {
          this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0;
        })
    );

    this._subscriptions.push(
      this.publishedToggle.valueChanges.subscribe((newValue) => {
        this.trainingService.bulkExternalOfferTrainingAction(
          newValue ? EXTERNAL_OFFER_TRAINING_BULK_ACTION.PUBLISH : EXTERNAL_OFFER_TRAINING_BULK_ACTION.UNPUBLISH, [this.trainingId])
          .pipe(first()).subscribe({
          next: () => {
            this.messageService.success(newValue ? 'trainings.published' : 'trainings.unpublished');
            this.training.update(training => {
              training.publish = newValue;
              return training;
            });
            this.publishedToggle.setValue(newValue, { emitEvent: false });
          },
          error: () => {
            this.publishedToggle.setValue(!newValue, { emitEvent: false });
          }
        });
      })
    );
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.trainingForm?.dirty && !this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.trainingForm?.dirty) {
      saveActions.push(this._saveTrainingData(this.training()?.status.data === TrainingStatusKey.Active));
    }

    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem): void {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveTraining(): void {
    const executeSave = () => {
      this.savingTraining = true;
      this._saveTrainingData(this.training()?.status.data === TrainingStatusKey.Active)
        .subscribe({
          next: () => this.savingTraining = false,
          error: () => this.savingTraining = false
        });
    };

    if (this.basicInfoComponent.hasInactiveCaseManagersOrCounselors()) {
      this.confirmationService.confirm({
        header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
        message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
        acceptIcon: 'hidden',
        rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
        rejectButtonStyleClass: 'p-button-text',
        accept: () => executeSave(),
        reject: () => {
          this.activeTabIndex = 0;
          setTimeout(() => this.basicInfoComponent.focusFirstInactiveCaseManager());
        }
      });
    }
    else {
      executeSave();
    }
  }

  deleteTraining(): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('trainings.deleteDialog.title'),
      message: this.translateService.instant('trainings.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('trainings.deleteDialog.delete'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('trainings.deleteDialog.cancel'),
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.trainingService.deleteTraining(this.trainingId, TRAINING_TYPE.OPEN_TRAINING)
          .subscribe(() => this.router.navigate(['/trainings']));
      }
    });
  }

  cancelTraining(event: CancelTrainingEvent, dialog: CancelTrainingDialogComponent): void {
    event.setLoading(true);
    this.trainingService.cancelTraining(this.trainingId, event.data, this.type).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Cancelled;

        this.messageService.success('trainings.cancelled');

        event.setLoading(false);
        dialog.closeDialog();

        this.loadTrainingData();
        this.sessionService.loadSessions(this.trainingId, this.type);
        this._setPublishToggleDataAndState();
      }
    });
  }

  loadTrainingData(): void {
    this.trainingService.getTraining(this.trainingId, this.type).pipe(first())
      .subscribe({
        next: (training: Training) => {
          this.type = training.typeId;
          this.referenceDataService.getReferenceDataItem(this.type, OPTIONS_LIST_TYPE.TRAINING_TYPES).pipe(first())
            .subscribe(trainingType => this.typeLabel = trainingType.label);
          this.training.set(training);
          this._fillTrainingForm(this.training());
          this._setPublishToggleDataAndState();
          this._fetchTemplateData();
          // this._loadDocuments();
          this.loadNotesCount();
        },
        error: () => {
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('externalOffer.trainings'),
                id: this.trainingId
              }),
              redirectUrl: '/external-offer'
            }
          });
        }
      });
  }

  closeTraining(closeTraining: CloseTraining): void {
    this.trainingService.closeTraining(this.trainingId, closeTraining, this.type).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Completed;
        this.training().publish = false;

        this.messageService.success('states.completed');
        this.sessionService.loadSessions(this.trainingId, this.type);
        this._setPublishToggleDataAndState();
      }
    });
  }

  reactivateTraining(reactivateTraining: ReactivateTraining) {
    this.trainingService.reactivateTraining(this.trainingId, reactivateTraining, this.type).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Active;
        this.messageService.success('states.reactivated');
        this.sessionService.loadSessions(this.trainingId, this.type);
        this._setPublishToggleDataAndState();
      }
    });
  }

  canPublish(): boolean {
    return this.trainingStatus !== this.trainingStatusKeys.Draft && this.trainingStatus !== this.trainingStatusKeys.Cancelled && !!this.training().description &&
      !(this.trainingStatus === this.trainingStatusKeys.Completed && this.publishedToggle.value === false);
  }

  private _createTrainingForm(): void {
    this.trainingForm = this.fb.group({
      trainingAlimentoId: [null],
      typeId: [this.type],
      basicDataForm: [],
      descriptionForm: []
    });

    this._subscriptions.push(
      this.trainingForm.get('basicDataForm')?.valueChanges.subscribe((newValue) => this.methodId = newValue?.methodId)
    );
  }

  private _fillTrainingForm(training: Training | TrainingProject) {
    if (!this.trainingForm) {
      this._createTrainingForm();
    }
    this.trainingStatus = (training.status?.data as TrainingStatusKey) ?? TrainingStatusKey.Draft;

    this.trainingForm.patchValue({
      trainingAlimentoId: training.trainingAlimentoId,
      typeId: training.typeId,
      basicDataForm: BasicInfoComponent.createFormData(training as Training),
      descriptionForm: SummaryDescriptionComponent.createFormData(training as Training)
    });

    this.methodId = this.trainingForm.get('basicDataForm')?.value?.methodId;
    if (this.readOnly) {
      this.trainingForm.disable();
    }
  }

  private _saveTrainingData(validate = false): Observable<{ id: string }> {
    const training: Training = {
      ...(flattenObject(this.trainingForm.value) as Training),
      publish: false
    };

    if (validate) {
      this.basicInfoComponent?.hasErrors(true);
      validateAllFormFields(this.trainingForm);

      if (!this.trainingForm.valid) {
        this.messageService.error('trainings.sessions.hasErrors');
        return new Observable<{ id: string }>((observable) => observable.error());
      }
    }

    const createOrUpdateTraining$ = this.trainingId
      ? this.trainingService.updateTraining(this.trainingId, training, this.type)
      : this.trainingService.createTraining(training, this.type);

    return createOrUpdateTraining$.pipe(
      tap({
        next: (response: { id: string }) => {
          this.trainingId = response.id;
          this.trainingForm.markAsPristine();
          this.messageService.success('trainings.saved');
          this._setDetailUrl();

          this.loadTrainingData();
        }
      })
    );
  }

  private _setDetailUrl(): void {
    if (this.trainingId) {

      this.navigationService.replaceState(
        this.router
          .createUrlTree(this.trainingMapper.getNavigationPath(this.trainingId, this.type), {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _setPublishToggleDataAndState(): void {
    this.publishedToggle.setValue(this.training().publish, { emitEvent: false });
    if (this.canPublish()) {
      this.publishedToggle.enable({ emitEvent: false });
    }
    else {
      this.publishedToggle.disable({ emitEvent: false });
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { index: 0, name: 'basicData', title: this.translateService.instant('trainings.basicData.formTitle') },
          {
            index: 1,
            name: 'description',
            title: this.translateService.instant('trainings.descriptionData.formTitle')
          },
          {
            index: 2,
            name: 'sessions',
            title: this.translateService.instant('trainings.sessions.sessions'),
            count: this.sessionService.nrOfSessions,
            disabled: !this.training()?.trainingId
          }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('trainings.notesData.formTitle'),
            disabled: !this.training()?.trainingId,
            count: this.notesCount,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          // {
          //   name: 'documents',
          //   title: this.translateService.instant('documents'),
          //   disabled: !this.training()?.trainingId || !this.training()?.documentSetUrl,
          //   count: this.documentCount,
          //   command: () => {
          //     window.open(this.training()?.documentSetUrl, '_blank');
          //   }
          // }
        ];
      })
    );
  }

  private _createBreadcrumb(): void {
    this.breadcrumb = [
      { label: 'titles.externalOffer.list', routerLink: ['/external-offer'] },
      { label: this.training()?.trainingId ? (this.training().trainingAlimentoId + ' - ' + this.training()?.customTitle) : 'trainings.actions.newFormation' }
    ];
  }

  private _fetchTemplateData(): void {
    if (this.training()?.trainingTemplateId) {
      this.templateService.getTemplate(this.training()?.trainingTemplateId).pipe(first())
        .subscribe(template => this.template = template);
    }
  }

  private _loadDocuments(): void {
    if (this.training()?.documentSetUrl) {
      this.documentService.getDocumentCount(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
        .subscribe(count => {
          this.documentCount.set(count.count);
          if (count.count === -1) {
            this.documentService.createDocumentSet(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
              .subscribe(documentSet => {
                this.training.update(training => {
                  training.documentSetUrl = documentSet.url;
                  return training;
                });
                this.documentCount.set(0);
              });
          }
        });
    }
  }

  loadNotesCount(): void {
    this.noteService.getNotesCount(this.trainingId, NOTES_TYPE.TrainingNote).pipe(first())
      .subscribe(count => {
        this.notesCount.set(count);
      });
  }
}
