<div *ngIf="template"
     (click)='navigateToTemplate($event)'
     class="shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-primary-hover relative border border-input-border cursor-pointer"
>
  <div class='grid grid-cols-2 lg:grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_250px] gap-4'>
    <div class='flex flex-col gap-2'>
      <div class='flex flex-wrap items-center gap-2'>
        <span class='font-bold text-lg'>{{template.alimentoId}} - {{template.customTitle}}</span>
        <alimento-ipv-frontend-external-offer-status-chip [status]='template.statusId'></alimento-ipv-frontend-external-offer-status-chip>
      </div>
      <div class='flex gap-2 items-center' *ngIf='template.rubricId'>
<!--        <i class='font-material text-lg'>folder</i>-->
        <span>{{rubric}}</span>
      </div>
    </div>
    <div class='flex flex-col gap-2 text-grey-3'>
      <div class='flex gap-2 items-center' *ngIf='template.caseManagerName'>
        <i class='font-material text-lg'>person</i>
        <span>{{template.caseManagerName}}</span>
      </div>

      <div class='flex gap-2 items-center' *ngIf='template.language'>
        <i class='font-material text-lg'>globe</i>
        <span>{{template.language | uppercase}}</span>
      </div>
    </div>
    <div class='flex flex-col gap-2 lg:items-end'>
      <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
      <div class='flex gap-2 items-center' *ngIf='template.trainingsCount'>
        <i class='font-material text-lg'>school</i>
        <span>{{template.trainingsCount}}</span>
      </div>
    </div>
  </div>
</div>
