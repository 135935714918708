import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { TrainingSearchItem } from '../../../types/training.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { TrainingMapper } from '../../mapper/training.mapper';

@Component({
    selector: 'alimento-ipv-frontend-training-card',
    templateUrl: './training-card.component.html',
    standalone: false
})
export class TrainingCardComponent implements OnChanges {

  @Input()
  training: TrainingSearchItem;

  @Input()
  selected = false;

  trainingType: string;
  rubric: string;

  constructor(private referenceDataService: ReferenceDataService,
              private trainingMapper: TrainingMapper,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['training']?.currentValue) {
      this.referenceDataService.getTrainingType(this.training.typeId).pipe(first())
        .subscribe(type => this.trainingType = type.label);

      if (this.training.rubricId) {
        this.referenceDataService.getTrainingRubric(this.training.rubricId).pipe(first())
          .subscribe(rubric => this.rubric = rubric.label);
      }
    }
  }

  navigateToTraining(event: MouseEvent): void {
    const path = this.trainingMapper.getNavigationPath(this.training.trainingId, this.training.typeId);
    if (event.ctrlKey) {
      window.open(path.join('/'), '_blank');
    }
    else {
      this.router.navigate(path);
    }
  }
}
