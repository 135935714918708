<alimento-ipv-frontend-purchase-entry-create-dialog #createDialog
                                                    [branches]='branches'
                                                    (submitPopup)='executeCreatePurchaseEntries($event, createDialog)'
></alimento-ipv-frontend-purchase-entry-create-dialog>

<alimento-ipv-frontend-sales-invoice-action-dialog [salesInvoice]='currentSalesInvoice'
                                                   (onSubmit)='executeSubmitSalesInvoiceActionDialog($event)'
>
</alimento-ipv-frontend-sales-invoice-action-dialog>

<alimento-ipv-frontend-sales-invoice-popup (formSubmit)='salesInvoiceUpdate($event)'
                                           (changeSelectedInvoiceIndex)='changeSelectedInvoiceIndex($event)'
                                           (updateSalesInvoices)='salesInvoiceChanges.emit()'
></alimento-ipv-frontend-sales-invoice-popup>

<alimento-ipv-frontend-header title="{{ 'trainings.invoices.menuTitle' | translate }}">
  <p-button
    *ngIf="showCreatePurchaseEntryReferencesButton"
    (onClick)='createPurchaseEntries(createDialog)'
    label="{{ 'trainings.purchaseEntries.create' | translate  }}"
  ></p-button>
</alimento-ipv-frontend-header>

<p-tabs [(value)]='activeIndex'>
  <p-tablist>
    <p-tab value='0'>{{"trainings.salesInvoices.menuTitle" | translate }} ({{salesInvoices()?.length}})</p-tab>
    <p-tab value='1' *ngIf='isOpenExternOrCustom'>{{"reimbursements.menuTitle" | translate}}  ({{purchaseEntries()?.length}})</p-tab>
    <p-tab value='2' *ngIf='!isOpenExternOrCustom'>{{"trainings.purchaseEntryReferences.menuTitle" | translate}}  ({{purchaseEntryReferences()?.length}})</p-tab>
  </p-tablist>
  <p-tabpanels>
    <p-tabpanel value='0'>
      <div *ngIf="salesInvoices()">
        <div class='flex flex-col'>
          <div *ngFor='let salesInvoice of salesInvoices(); let index = index' class='border-b border-b-grey-1 last:border-none'>
            <alimento-ipv-frontend-sales-invoice-card
              [previousSalesInvoice]='index > 0 ? salesInvoices()[index - 1] : undefined'
              [salesInvoice]='salesInvoice'
              [readOnly]='readOnly'
              (actionClicked)='onSaleInvoiceActionClicked(index, salesInvoice, $event)'
            ></alimento-ipv-frontend-sales-invoice-card>
          </div>
        </div>

        <div *ngIf='salesInvoices().length === 0'>{{ 'trainings.salesInvoices.noSalesInvoices' | translate }}</div>
      </div>
    </p-tabpanel>

    <p-tabpanel value='1'>
      <div *ngIf='purchaseEntries()'>
        <div class='flex flex-col'>
          <div *ngFor='let purchaseEntry of purchaseEntries()' class='border-b border-b-grey-1 last:border-none'>
            <alimento-ipv-frontend-purchase-entry-card
              [purchaseEntry]='purchaseEntry'
              [readOnly]='readOnly'
            ></alimento-ipv-frontend-purchase-entry-card>
          </div>
        </div>

        <div *ngIf='purchaseEntries().length === 0'>{{ 'trainings.purchaseEntries.noPurchaseEntries' | translate }}</div>
      </div>
    </p-tabpanel>

    <p-tabpanel value='2'>
      <div *ngIf='purchaseEntryReferences()'>
        <div class='flex flex-col'>
          <div *ngFor='let purchaseEntryReference of purchaseEntryReferences()' class='border-b border-b-grey-1 last:border-none'>
            <alimento-ipv-frontend-purchase-entry-card
              [purchaseEntryReference]='purchaseEntryReference'
              [readOnly]='readOnly'
            ></alimento-ipv-frontend-purchase-entry-card>
          </div>
        </div>

        <div *ngIf='purchaseEntryReferences().length === 0'>{{ 'trainings.purchaseEntryReferences.noPurchaseEntryReferences' | translate }}</div>
      </div>
    </p-tabpanel>
  </p-tabpanels>
</p-tabs>
