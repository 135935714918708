import { Component, effect, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { first, map, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthorizationService,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  MyMessageService,
  NavigationService,
  PaginatedResponse,
  Role,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { TrainingTemplateListItem, TrainingTemplateSearchItem } from '../../../types/training-template.type';
import {
  EXTERNAL_OFFER_TRAINING_BULK_ACTION,
  OPTIONS_LIST_TYPE,
  ReferenceDataService,
  TEMPLATE_TYPE,
  TRAINING_TYPE,
  TrainingSearchItem,
  TrainingService
} from '@alimento-ipv-frontend/application-lib';
import { SearchesService } from '../../../services/searches.service';
import { TRAINING_SORT_FIELDS, TRAINING_STATUS } from '../../../types/training.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'alimento-ipv-frontend-external-offer',
  templateUrl: './external-offer.component.html',
  standalone: false
})
export class ExternalOfferComponent implements OnInit {
  templates: TrainingTemplateListItem[] = [];
  searchTemplateData$: InfiniteScrollDataAdapter<TrainingTemplateSearchItem>;
  searchTemplateFilters: SearchFilter[];
  sortTemplatesFilters: IFilter[];
  searchTrainingData$: InfiniteScrollDataAdapter<TrainingSearchItem>;
  searchTrainingFilters: SearchFilter[];
  sortTrainingsFilters: IFilter[];
  readOnly = true;
  loading = false;

  showPublish = false;
  showUnpublish = false;
  showClose = false;
  showReactivate = false;

  activeIndex: WritableSignal<string> = signal('0');
  currentSelection: TrainingSearchItem[];

  @ViewChild('trainingSearch')
  searchComponent: SearchComponent;

  protected readonly EXTERNAL_OFFER_TRAINING_BULK_ACTION = EXTERNAL_OFFER_TRAINING_BULK_ACTION;

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private searchesService: SearchesService,
    private referenceDataService: ReferenceDataService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private trainingService: TrainingService,
    private messageService: MyMessageService,
    private translateService: TranslateService
  ) {
    effect(() => {
      this.navigationService.replaceState(
        this.router.createUrlTree(
          ['external-offer'],
          { queryParams: { tabIndex: this.activeIndex() } }
        ).toString()
      );
    });
  }

  ngOnInit(): void {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this.activatedRoute.queryParams
      .pipe(first())
      .subscribe(params => {
        this.activeIndex.set(params['tabIndex'] || '0');
      });
    this._createTemplateSearch();
    this._createTrainingSearch();
  }

  private _createTemplateSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: [{ type: FilterType.filterType, values: [TEMPLATE_TYPE.EXTERNAL_OFFER] }],
      sortField: TRAINING_SORT_FIELDS.ALIMENTO_ID,
      sortOrder: 0
    };

    this.searchTemplateData$ = new InfiniteScrollDataAdapter<TrainingTemplateSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<TrainingTemplateSearchItem>> => {
      return this.searchesService.searchTrainingTemplates(searchRequest);
    }, searchRequest, true);
    this.searchTemplateFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.status',
        key: FilterType.filterStatuses,
        expanded: true,
        data: this.referenceDataService.getTemplateStatuses()
          .pipe(map(types => types.map(type =>
            ({ type: FilterType.filterStatuses, label: type.label, value: type.data }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.RUBRICS, FilterType.filterRubric)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        key: FilterType.filterCaseManager,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCaseManager,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.basicData.counselor',
        key: FilterType.filterCounselor,
        data: this.referenceDataService.getCounselors(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCounselor,
              label: type.label,
              value: type.data
            }) as IFilter)))
      }
    ];

    this.sortTemplatesFilters = [
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.alimentoId'),
        value: TRAINING_SORT_FIELDS.ALIMENTO_ID
      }
    ];
  }

  private _createTrainingSearch(): void {
    var trainingTypes = this.referenceDataService.getTrainingTypes(false);

    trainingTypes.pipe(first())
      .subscribe(types => {
        let excludedTypes = types.filter(type => type.data !== TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND && type.data !== TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL)
          .map(type => type.data);

        let searchRequest: SearchRequest = {
          first: 0,
          rows: 9,
          filters: [
            {
              type: FilterType.filterExcludedType,
              values: excludedTypes
            }
          ],
          sortField: TRAINING_SORT_FIELDS.ALIMENTO_ID,
          sortOrder: 0
        };

        this.searchTrainingData$ = new InfiniteScrollDataAdapter<TrainingSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<TrainingSearchItem>> => {
          return this.searchesService.searchTrainings(searchRequest);
        }, searchRequest, true);
      });

    this.searchTrainingFilters = [
      {
        type: SearchFilterType.date,
        label: 'trainings.sessions.date',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.status',
        key: FilterType.filterStatuses,
        expanded: true,
        data: this.referenceDataService.getTrainingStatuses()
          .pipe(map(statuses => statuses
            .filter(type => type.data === TRAINING_STATUS.ACTIVE || type.data === TRAINING_STATUS.CLOSED)
            .map(status =>
              ({ type: FilterType.filterStatuses, label: status.label, value: status.data }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.type',
        key: FilterType.filterType,
        expanded: true,
        data: trainingTypes.pipe(map(types => types
          .filter(type => type.data === TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND || type.data === TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL)
          .map(type =>
            ({
              type: FilterType.filterType,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.published',
        expanded: true,
        key: FilterType.filterIsPublished,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterIsPublished)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.RUBRICS, FilterType.filterRubric)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        key: FilterType.filterCaseManager,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCaseManager,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.basicData.counselor',
        key: FilterType.filterCounselor,
        data: this.referenceDataService.getCounselors(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCounselor,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
    ];

    this.sortTrainingsFilters = [
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.alimentoId'),
        value: TRAINING_SORT_FIELDS.ALIMENTO_ID
      },
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.title'),
        value: TRAINING_SORT_FIELDS.TITLE
      },
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.startDate'),
        value: TRAINING_SORT_FIELDS.START_DATE
      }
    ];
  }

  onselectionchange(event: TrainingSearchItem[]): void {
    this.currentSelection = event;
    this.showPublish = event.length > 0 && event.every(training => !training.published && training.statusId === TRAINING_STATUS.ACTIVE);
    this.showUnpublish = event.length > 0 && event.every(training => training.published);
    this.showClose = event.length > 0 && event.every(training => training.statusId === TRAINING_STATUS.ACTIVE);
    this.showReactivate = event.length > 0 && event.every(training => training.statusId === TRAINING_STATUS.CLOSED);
  }

  executeBulkAction(action: EXTERNAL_OFFER_TRAINING_BULK_ACTION) {
    if (this.currentSelection?.length > 0) {
      this.loading = true;
      this.trainingService.bulkExternalOfferTrainingAction(action, this.currentSelection.map(selection => selection.trainingId))
        .pipe(first())
        .subscribe({
          next: () => {
            this.messageService.success('actionExecuted');
            this.loading = false;
            this.currentSelection = [];
            this.searchComponent.removeSelection();
            this.searchComponent.refresh();
          },
          error: () => this.loading = false
        });
    }
  }
}
