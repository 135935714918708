import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';
import { InterimOffice } from '../types/branch.type';

@Injectable({
  providedIn: 'root'
})
export class InterimOfficeService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getInterimOffice(interimOfficeId: string): Observable<InterimOffice> {
    return this.http.get<InterimOffice>(`${this.config.readApiUrl}/interimoffices/${interimOfficeId}`);
  }

  createInterimOffice(interimOffice: InterimOffice): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/interimoffices/`, interimOffice);
  }

  updateInterimOffice(interimOfficeId: string, interimOffice: InterimOffice): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/interimoffices/${interimOfficeId}`, interimOffice);
  }
}
