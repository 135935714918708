import { Component, HostListener, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, first, forkJoin, map, Observable, Subscription, switchMap, tap } from 'rxjs';
import {
  AuthorizationService,
  CanComponentDeactivate, ExtraMenuItem,
  FilterType,
  flattenObject,
  IFilter,
  InfiniteScrollDataAdapter,
  LeaveConfirmService,
  MyMessageService,
  NavigationService, NOTES_TYPE, NoteService, NotesSidebarComponent,
  PaginatedResponse,
  Role, SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { BasicInfoComponent } from '../../../utils/components/basic-info/basic-info.component';
import {
  SummaryDescriptionComponent
} from '../../../utils/components/summary-description/summary-description.component';
import {
  EXTERNAL_OFFER_TRAINING_BULK_ACTION,
  TemplateStatusKey,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../../../types/reference-data.enum';
import { TrainingTemplate } from '../../../types/training-template.type';
import { TrainingTemplateService } from '../../../services/training-template.service';
import { SessionService } from '../../../services/session.service';
import {
  CreateExternalOfferEvent,
  ReferenceDataService,
  TEMPLATE_TYPE,
  TrainingSearchItem,
  TrainingService
} from '@alimento-ipv-frontend/application-lib';
import {
  BulkExternalOfferPopupComponent
} from '../../components/bulk-external-offer-popup/bulk-external-offer-popup.component';
import { TRAINING_SORT_FIELDS, TRAINING_STATUS } from '../../../types/training.enum';

@Component({
  selector: 'alimento-ipv-frontend-training-template-detail',
  templateUrl: './training-template-detail.component.html',
  standalone: false
})
export class TrainingTemplateDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('basicInfoComponent')
  basicInfoComponent!: BasicInfoComponent;

  @ViewChild('summaryDescriptionComponent')
  summaryDescriptionComponent!: SummaryDescriptionComponent;

  @ViewChild(BulkExternalOfferPopupComponent)
  bulkPopupComponent: BulkExternalOfferPopupComponent;

  @ViewChild('trainingSearch')
  searchComponent: SearchComponent;

  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  trainingsCount: WritableSignal<number> = signal(undefined);
  notesCount: WritableSignal<number> = signal(undefined);

  activeTabIndex = 0;
  templateType: TEMPLATE_TYPE;

  templateForm: FormGroup;
  templateId!: string;
  templateStatus: TemplateStatusKey = TemplateStatusKey.Draft;
  showRequiredFieldErrors = false;
  templateReadyToActivate = false;
  template!: TrainingTemplate;
  savingTemplate = false;
  activatingTemplate = false;
  templateStatusKeys = TemplateStatusKey;
  requiredFieldToggle = new FormControl(false);
  readOnly = false;
  loading = false;
  notesHasChanges: boolean;
  showNotes = false;
  breadcrumb: MenuItem[];
  searchTrainingData$: InfiniteScrollDataAdapter<TrainingSearchItem>;
  searchTrainingFilters: SearchFilter[];
  defaultTrainingFilter: { [key: string]: string[] } = {};
  sortTrainingsFilters: IFilter[];
  currentSelection: TrainingSearchItem[];
  showBulkPublish = false;
  showBulkUnpublish = false;
  showBulkClose = false;
  showBulkReactivate = false;

  TrainingTemplateNote = NOTES_TYPE.TrainingTemplateNote;

  private _subscriptions: Subscription[] = [];
  protected readonly EXTERNAL_OFFER_TRAINING_BULK_ACTION = EXTERNAL_OFFER_TRAINING_BULK_ACTION;
  protected readonly TRAINING_TYPE = TRAINING_TYPE;
  protected readonly TEMPLATE_TYPE = TEMPLATE_TYPE;

  get isActivateDisabled(): boolean {
    return this.activatingTemplate ||
      this.templateStatus === this.templateStatusKeys.Removed ||
      !this.basicInfoComponent?.formGroup.get('customTitle').value;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  constructor(
    private fb: FormBuilder,
    private trainingTemplateService: TrainingTemplateService,
    private messageService: MyMessageService,
    private confirmationService: ConfirmationService,
    private leaveConfirmationService: LeaveConfirmService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private authorizationService: AuthorizationService,
    private titleService: TitleService,
    private trainingService: TrainingService,
    private referenceDataService: ReferenceDataService,
    private noteService: NoteService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._setMenuItems();
  }

  ngOnInit() {
    this._subscriptions.push(
      this.route.params.subscribe(params => {
        forkJoin([this.route.data.pipe(first()), this.route.queryParams.pipe(first())])
          .subscribe(([data]) => {
            this.templateId = params['templateId'];
            this.templateType = data['templateType'] || TEMPLATE_TYPE.OPEN_TRAINING;

            if (this.templateId) {
              this._loadTemplateData();
            }
            else {
              this._createTemplateForm();
              this._createBreadcrumb();
            }
          });
      })
    );

    this._subscriptions.push(
      this.route.queryParams.subscribe(
        (queryParams) => (this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0)
      )
    );

    this._subscriptions.push(
      this.requiredFieldToggle.valueChanges.subscribe((newValue) => {
        this.requiredFieldToggle.setValue(newValue, { emitEvent: false });
        this.showRequiredFieldErrors = !!newValue;
        setTimeout(() => this._calculateTabMenuStatus());
      })
    );
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.templateForm.dirty) {
      return true;
    }

    return this.leaveConfirmationService.leaveDialog(() =>
      this.saveTemplateData(this.template?.status?.data === TemplateStatusKey.Active)
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this.setDetailUrl();
  }

  private _loadTemplateData() {
    this.trainingTemplateService.getTemplate(this.templateId).pipe(first())
      .subscribe({
        next: (template: TrainingTemplate) => {
          this.template = template;
          this.titleService.setTitle([`${this.translateService.instant(
              this.templateType === TEMPLATE_TYPE.OPEN_TRAINING ? 'titles.templates.detail' : 'titles.externalOffer.templateDetail')} - ${template.customTitle}`]);
          this._fillTemplateForm(template);

          if (this.templateType === TEMPLATE_TYPE.OPEN_TRAINING) {
            this.sessionService.loadSessions(this.templateId);
          }
          this._createBreadcrumb();
          this._setMenuItems();
          this.loadNotesCount();

          if (this.templateType === TEMPLATE_TYPE.EXTERNAL_OFFER) {
            this._createTrainingsCount();
            this._createTrainingSearch();
          }
        },
        error: () => {
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('templates.templates'),
                id: this.templateId
              }),
              redirectUrl: this.templateType === TEMPLATE_TYPE.OPEN_TRAINING ? '/training-templates' : '/external-offer'
            }
          });
        }
      });
  }

  saveTemplate() {
    const executeSave = () => {
      this.savingTemplate = true;
      this.saveTemplateData(this.template?.status?.data === TemplateStatusKey.Active).subscribe({
        next: () => (this.savingTemplate = false),
        error: () => (this.savingTemplate = false)
      });
    };

    if (this.basicInfoComponent.hasInactiveCaseManagersOrCounselors()) {
      this.confirmationService.confirm({
        header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
        message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
        acceptIcon: 'hidden',
        rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
        rejectButtonStyleClass: 'p-button-text',
        accept: () => executeSave(),
        reject: () => {
          this.activeTabIndex = 0;
          setTimeout(() => this.basicInfoComponent.focusFirstInactiveCaseManager());
        }
      });
    }
    else {
      executeSave();
    }
  }

  deleteTemplate() {
    this.confirmationService.confirm({
      header: this.translateService.instant('templates.deleteDialog.title'),
      message: this.translateService.instant('templates.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('templates.deleteDialog.delete'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('templates.deleteDialog.cancel'),
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.trainingTemplateService
          .deleteTemplate(this.templateId)
          .subscribe(() => this.router.navigate(['/training-templates']));
      }
    });
  }

  activateTemplate() {
    if (this.templateStatus === TemplateStatusKey.Draft) {
      const executeActivate = () => {
        this.activatingTemplate = true;
        this.saveTemplateData(true)
          .pipe(switchMap(() => this.trainingTemplateService.activateTemplate(this.templateId)), first())
          .subscribe({
            next: () => {
              this.templateStatus = TemplateStatusKey.Active;
              this.messageService.success('templates.activated');
              this.activatingTemplate = false;

              if (this.templateType === TEMPLATE_TYPE.OPEN_TRAINING) {
                this.sessionService.loadSessions(this.templateId);
              }
            },
            error: () => (this.activatingTemplate = false)
          });
      };

      if (this.basicInfoComponent.hasInactiveCaseManagersOrCounselors()) {
        this.confirmationService.confirm({
          header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
          message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
          acceptIcon: 'hidden',
          rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
          rejectButtonStyleClass: 'p-button-text',
          accept: () => executeActivate(),
          reject: () => {
            this.activeTabIndex = 0;
            setTimeout(() => this.basicInfoComponent.focusFirstInactiveCaseManager());
          }
        });
      }
      else {
        executeActivate();
      }
    }
  }

  createTrainingFromTemplate(templateId: string) {
    if (this.templateType === TEMPLATE_TYPE.OPEN_TRAINING) {
      this.trainingTemplateService
        .createTrainingFromTemplate(templateId)
        .pipe(first())
        .subscribe(trainingId =>
          this.router.navigate(['/trainings', 'open', trainingId], {
            relativeTo: this.route.parent,
            state: {
              newTraining: true
            }
          })
        );
    }
    else {
      this.bulkPopupComponent.openPopup();
    }
  }

  createExternalOfferTrainingFromTemplate(event: CreateExternalOfferEvent, bulkPopup: BulkExternalOfferPopupComponent): void {
    event.setLoading(true);
    this.trainingTemplateService.createExternalOfferTrainingFromTemplate(event.data).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('externalOffer.trainingCreated');
          this._createTrainingSearch();
          this._createTrainingsCount();
          event.setLoading(false);
          bulkPopup.closePopup();
        },
        error: () => event.setLoading(false)
      })
  }

  private _createTemplateForm(): void {
    this.templateForm = this.fb.group({
      templateAlimentoId: [null],
      templateTypeId: [this.templateType],
      basicDataForm: [],
      descriptionForm: []
    });

    if (this.readOnly) {
      this.templateForm.disable();
    }

    this._subscriptions.push(
      this.templateForm.statusChanges.pipe(delay(1)).subscribe(() => this._calculateTabMenuStatus()),
    );
  }

  private _fillTemplateForm(template: TrainingTemplate) {
    if (!this.templateForm) {
      this._createTemplateForm();
    }

    this.templateStatus = (template.status?.data as TemplateStatusKey) ?? TemplateStatusKey.Draft;
    this.showRequiredFieldErrors = this.templateStatus === TemplateStatusKey.Active;

    this.templateForm.patchValue({
      templateAlimentoId: template.trainingTemplateAlimentoId,
      typeId: template.typeId,
      basicDataForm: BasicInfoComponent.createFormData(template),
      descriptionForm: SummaryDescriptionComponent.createFormData(template)
    });
  }

  private _calculateTabMenuStatus(): void {
    const basicDataForm: AbstractControl | null = this.templateForm.get('basicDataForm');
    const descriptionForm: AbstractControl | null = this.templateForm.get('descriptionForm');

    const basicDataMenuItem: TabMenuItem = this.tabMenuItems.filter((menuItem) => menuItem.name === 'basicData')[0];
    const descriptionMenuItem: TabMenuItem = this.tabMenuItems.filter((menuItem) => menuItem.name === 'description')[0];

    basicDataMenuItem.errors = this.basicInfoComponent.hasErrors();
    basicDataMenuItem.completed = !!basicDataForm?.valid;

    descriptionMenuItem.errors = this.summaryDescriptionComponent.hasErrors();
    descriptionMenuItem.completed = !!descriptionForm?.valid;

    this.templateReadyToActivate = !!basicDataForm?.valid && !!descriptionForm?.valid;
  }

  private saveTemplateData(validate = false): Observable<{ id: string }> {
    const template: TrainingTemplate = { ...(flattenObject(this.templateForm.value) as TrainingTemplate) };

    if (this.isActivateDisabled || validate) {
      validateAllFormFields(this.templateForm);

      if (!this.templateForm.valid) {
        this.messageService.notAllFieldsValid();
        return new Observable<{ id: string }>((observable) => observable.error());
      }
    }

    const createOrUpdateTemplate$ = this.templateId
      ? this.trainingTemplateService.updateTemplate(this.templateId, template)
      : this.trainingTemplateService.createTemplate(template);

    return createOrUpdateTemplate$.pipe(
      tap((response: { id: string }) => {
        this.templateId = response.id;
        this.templateForm.markAsPristine();
        this.messageService.success('templates.saved');
        this.setDetailUrl();
        this._loadTemplateData();
      })
    );
  }

  private setDetailUrl(): void {
    if (this.templateId) {
      this.navigationService.replaceState(
        this.router
          .createUrlTree([this.templateType === TEMPLATE_TYPE.OPEN_TRAINING ? '/training-templates' : '/external-offer/templates', this.templateId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _setMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { index: 0, name: 'basicData', title: this.translateService.instant('trainings.basicData.formTitle') },
          {
            index: 1,
            name: 'description',
            title: this.translateService.instant('trainings.descriptionData.formTitle')
          },
          this.templateType === TEMPLATE_TYPE.OPEN_TRAINING ?
            {
              index: 2,
              name: 'sessions',
              title: this.translateService.instant('trainings.sessions.sessions'),
              disabled: !this.templateId,
              count: this.sessionService.nrOfSessions
            } :
            {
              index: 3,
              name: 'trainings',
              title: this.translateService.instant('externalOffer.trainings'),
              disabled: !this.templateId,
              count: this.trainingsCount
            }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('branches.notesTitle'),
            count: this.notesCount,
            disabled: !this.templateId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          // {
          //   name: 'documents',
          //   title: this.translateService.instant('documents'),
          //   disabled: !this.branch()?.branchId || !this.branch()?.documentSetUrl,
          //   count: this.documentCount,
          //   command: () => {
          //     window.open(this.branch()?.documentSetUrl, '_blank');
          //   }
          // }
          // { name: 'history', title: this.translateService.instant('branches.historyTitle') },
        ];

        if (this.templateForm && this.basicInfoComponent && this.summaryDescriptionComponent) {
          this._calculateTabMenuStatus();
        }
      })
    );
  }

  private _createBreadcrumb(): void {
    if (this.templateType === TEMPLATE_TYPE.OPEN_TRAINING) {
      this.breadcrumb = [
        { label: 'titles.templates.list', routerLink: ['/training-templates'] },
        { label: this.template?.trainingTemplateAlimentoId ? (this.template.trainingTemplateAlimentoId + ' - ' + this.template?.customTitle) : 'titles.templates.create' }
      ];
    }
    else {
      this.breadcrumb = [
        { label: 'titles.externalOffer.list', routerLink: ['/external-offer'] },
        { label: this.template?.trainingTemplateAlimentoId ? (this.template.trainingTemplateAlimentoId + ' - ' + this.template?.customTitle) : 'titles.externalOffer.create' }
      ];
    }
  }

  loadNotesCount(): void {
    this.noteService.getNotesCount(this.templateId, NOTES_TYPE.TrainingTemplateNote).pipe(first())
      .subscribe(count => {
        this.notesCount.set(count);
      });
  }

  private _createTrainingSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: [],
      sortField: TRAINING_SORT_FIELDS.ALIMENTO_ID,
      sortOrder: 0
    };
    searchRequest.filters.push({
      type: FilterType.filterTrainingTemplateId,
      values: [this.templateId]
    });

    this.defaultTrainingFilter = {};
    this.defaultTrainingFilter[FilterType.filterStatuses] = [TRAINING_STATUS.ACTIVE];

    this.searchTrainingData$ = new InfiniteScrollDataAdapter<TrainingSearchItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<TrainingSearchItem>> => {
      return this.trainingService.searchTrainings(searchRequest);
    }, searchRequest, true);

    this.searchTrainingFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.date,
        label: 'trainings.sessions.date',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.status',
        key: FilterType.filterStatuses,
        expanded: true,
        data: this.referenceDataService.getTrainingStatuses()
          .pipe(map(types => types.filter(type => this.templateType === TEMPLATE_TYPE.OPEN_TRAINING
                || (type.data === TrainingStatusKey.Active || type.data === TrainingStatusKey.Completed))
            .map(type =>({
               type: FilterType.filterStatuses, label: type.label, value: type.data
              }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.type',
        key: FilterType.filterType,
        expanded: true,
        data: this.referenceDataService.getTrainingTypes()
          .pipe(map(types => types
            .filter(type => type.data === TRAINING_TYPE.EXTERNAL_OFFER_ON_DEMAND || type.data === TRAINING_TYPE.EXTERNAL_OFFER_EXTERNAL)
            .map(type =>
              ({
                type: FilterType.filterType,
                label: type.label,
                value: type.data
              }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.published',
        expanded: true,
        key: FilterType.filterIsPublished,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterIsPublished)
      },
    ];

    this.sortTrainingsFilters = [
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.alimentoId'),
        value: TRAINING_SORT_FIELDS.ALIMENTO_ID
      },
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.startDate'),
        value: TRAINING_SORT_FIELDS.START_DATE
      }
    ];
  }

  private _createTrainingsCount(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterTrainingTemplateId,
      values: [this.templateId]
    });
    searchRequest.filters.push({
      type: FilterType.filterStatuses,
      values: [TRAINING_STATUS.ACTIVE]
    });

    this.trainingService.getTrainingsCount(searchRequest).pipe(first())
      .subscribe(count => this.trainingsCount.set(count));
  }

  executeBulkAction(action: EXTERNAL_OFFER_TRAINING_BULK_ACTION) {
    if (this.currentSelection?.length > 0) {
      this.loading = true;
      this.trainingService.bulkExternalOfferTrainingAction(action, this.currentSelection.map(selection => selection.trainingId))
        .pipe(first())
        .subscribe({
          next: () => {
            this.messageService.success('actionExecuted');
            this.loading = false;
            this.currentSelection = [];
            this.searchComponent.removeSelection();
            this.searchComponent.refresh();
          },
          error: () => this.loading = false
        });
    }
  }

  onTrainingSelectionChange(event: TrainingSearchItem[]) {
    this.currentSelection = event;
    this.showBulkPublish = event.length > 0 && event.every(training => !training.published && training.statusId === TRAINING_STATUS.ACTIVE);
    this.showBulkUnpublish = event.length > 0 && event.every(training => training.published);
    this.showBulkClose = event.length > 0 && event.every(training => training.statusId === TRAINING_STATUS.ACTIVE);
    this.showBulkReactivate = event.length > 0 && event.every(training => training.statusId === TRAINING_STATUS.CLOSED);
  }
}
