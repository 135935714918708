import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  DataLabelType, DateMapper,
  dateValid,
  FilterType,
  FormComponent,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { first } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-external-offer-create',
  templateUrl: './external-offer-create.component.html',
  standalone: false
})
export class ExternalOfferCreateComponent extends FormComponent {
  @Input()
  methodId?: string;

  teacherFilter = { type: FilterType.filterIsTeacher, values: ['true'] };
  contactEmploymentCount = 0;
  methods: DataLabelType[];

  constructor(
    private formBuilder: FormBuilder,
    private referenceDataService: ReferenceDataService
  ) {
    super();

    this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.TRAINING_METHODS).pipe(first())
      .subscribe(methods => this.methods = methods);

    this.createFormGroup();
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      teacherBranch: [undefined],
      contactEmployments: this.formBuilder.array([]),
      location: [],
      website: [],
      publish: [false],
      sessions: this.formBuilder.array([])
    });

    this.addContactEmployment();

    this.formGroup.get("contactEmployments").disable();

    this.subscriptions.push(this.formGroup.get('teacherBranch')?.valueChanges.subscribe((branch) => {
      while (this.contactEmployments.length > 0) {
        this.deleteContactEmployment(this.contactEmployments.length - 1);
      }
      this.addContactEmployment();

      if (branch) {
        this.formGroup.get("website").setValue(branch.website);
        this.formGroup.get("contactEmployments").enable();
      }
      else {
        this.formGroup.get("contactEmployments").disable();
      }
    }));
  }

  override getData(): any {
    let data = super.getData();

    if (data.teacherBranch) {
      data.teacherBranchId = data.teacherBranch.branchId;
      delete data.teacherBranch;
    }

    if (data.contactEmployments) {
      data.contactEmploymentIds = data.contactEmployments
        .filter((contactEmployment: any) => contactEmployment)
        .map((contactEmployment: any) => contactEmployment?.employmentId);
      delete data.contactEmployments;
    }

    data.sessions.forEach((session: any) => {
      session.date = DateMapper.getDateFromDateTimeAsString(session.date);
      session.startTime = session.startTime !== '' ? session.startTime : null;
      session.endTime = session.endTime !== '' ? session.endTime : null;
      session.duration = session.duration !== '' ? session.duration + ':00' : null;
    });

    return data;
  }

  private _createSessionFormGroup(): FormGroup {
    const startTime = this.formBuilder.control('');

    const formGroup = this.formBuilder.group({
      title: ['', [Validators.maxLength(500)]],
      date: [undefined, [dateValid(), Validators.required]],
      startTime: startTime,
      endTime: ['', [this.validateMinValue(startTime)]],
      duration: ['', [this.validateDuration(), this.validateMinDuration()]],
      sessionMethodId: [this.methodId, [Validators.required]]
    });
    this._addSessionSubscriptions(formGroup);

    return formGroup;
  }

  get contactEmployments(): FormArray {
    return this.formGroup.controls['contactEmployments'] as FormArray;
  }

  addContactEmployment() {
    this.contactEmploymentCount++;
    this.contactEmployments.push(this.formBuilder.control(undefined));
  }

  deleteContactEmployment(index: number) {
    this.contactEmploymentCount--;
    this.contactEmployments.removeAt(index);
  }

  get sessions(): FormArray {
    return this.formGroup.controls['sessions'] as FormArray;
  }

  addSession() {
    this.sessions.push(this._createSessionFormGroup());
  }

  deleteSession(index: number) {
    this.sessions.removeAt(index);
  }

  private _addSessionSubscriptions(formGroup: FormGroup): void {
    this.subscriptions.push(
      formGroup.get('startTime')?.valueChanges.subscribe(() => {
        formGroup.get('endTime')?.updateValueAndValidity();
        formGroup.get('duration')?.updateValueAndValidity();
      })
    );

    this.subscriptions.push(
      formGroup.get('endTime')?.valueChanges.subscribe(() => {
        formGroup.get('duration')?.updateValueAndValidity();
      })
    );
  }

  private _getDurationAsNumber(duration: string, isEndTime = false): number {
    const value = Number(duration.replace(':', ''));
    if (value === 0 && isEndTime) {
      return 2400;
    }
    return value;
  }

  private validateMinValue(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        control.value &&
        otherControl.value &&
        this._getDurationAsNumber(control.value, true) <= this._getDurationAsNumber(otherControl.value)
      ) {
        return { minValueToLow: true };
      }
      return null;
    };
  }

  private validateMinDuration(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && this._getDurationAsNumber(control.value) <= 0) {
        return { minDurationToLow: true };
      }
      return null;
    };
  }

  private validateDuration(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const startTime = this.formGroup?.get('startTime')?.value;
      const endTime = this.formGroup?.get('endTime')?.value;
      if (
        control.value &&
        startTime &&
        endTime &&
        this._getDurationAsNumber(endTime, true) - this._getDurationAsNumber(startTime) < this._getDurationAsNumber(control.value)
      ) {
        return { invalidDuration: true };
      }
      return null;
    };
  }
}
