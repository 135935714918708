import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  signal,
  Signal,
  SimpleChanges
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuItemUtilsService, TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { first, map, Observable, Subscription } from 'rxjs';
import { EnrollmentAction, EnrollmentActionEnum, EnrollmentListItem } from '../../../types/enrollment.type';
import { Training } from '../../../types/training.type';
import { ENROLLMENT_VIA } from '../../../types/enrollment.enum';
import { EnrollmentService } from '../../../services/enrollment.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { EnrollmentStatusKey, TrainingStatusKey } from '../../../types/reference-data.enum';
import { Branch, BranchService } from '@alimento-ipv-frontend/application-lib';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-card',
    templateUrl: './enrollment-card.component.html',
    standalone: false
})
export class EnrollmentCardComponent implements OnChanges, OnDestroy {
  @Input()
  enrollment?: EnrollmentListItem;

  @Input()
  readOnly = false;

  @Input()
  training: Signal<Training> = signal(undefined);

  @Input()
  isOpenExternOrCustom = false;

  @Input()
  activeEnrollmentId?: string;

  @Output()
  actionClicked = new EventEmitter<EnrollmentAction>();

  actions: MenuItem[] = [];
  showExtraInfo = false;
  isCancelled = false;
  reasonCancelled: string;

  private _enrollment = signal<EnrollmentListItem>(undefined);
  private _subscriptions: Subscription[] = [];
  readonly CEVORA = ENROLLMENT_VIA.CEVORA;
  hasContactPerson = false;
  branch: Branch;

  constructor(private menuItemUtils: MenuItemUtilsService,
              private translationService: TranslationService,
              private enrollmentService: EnrollmentService,
              private referenceDataService: ReferenceDataService,
              private branchService: BranchService) {
    effect(() => {
      this._enrollment();
      this.enrollmentService.nrOfParticipants();
      this.training();
      this.translationService.languageChange();
      this.actions = this.getActions();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enrollment']?.currentValue) {
      this._setReadOnlyData();
      this._clearSubscriptions();
      this._enrollment.set(this.enrollment);
    }
  }

  private _setReadOnlyData(): void {
    this.isCancelled = this.enrollment?.statusId === EnrollmentStatusKey.Cancelled;
    this.hasContactPerson = this.enrollment?.contactLastName !== this.enrollment?.lastName &&
      this.enrollment?.contactFirstName !== this.enrollment?.firstName;

    if (this.enrollment?.reasonCancelledId) {
      this.referenceDataService.getCancelEnrollmentReason(this.enrollment?.reasonCancelledId).pipe(first())
        .subscribe(reasonCancelEnrollment => this.reasonCancelled = reasonCancelEnrollment.label);
    }

    if (this.enrollment?.branch?.data) {
      this.branchService.getBranch(this.enrollment?.branch?.data).pipe(first())
        .subscribe(branch => this.branch = branch)
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  private _menuItemActions(action: EnrollmentActionEnum): () => any {
    return () => this.actionClicked.emit({ action: action, enrollment: this.enrollment });
  }

  getActions(): MenuItem[] {
    if (!this.enrollment) {
      return [];
    }
    const menuItems = [
      this.menuItemUtils.getMenuItem(
        'enrollments.actions.viewDetails',
        this._menuItemActions(EnrollmentActionEnum.view)
      ),
      this.menuItemUtils.getMenuItem(
        'enrollments.actions.navigateToPerson',
        this._menuItemActions(EnrollmentActionEnum.navigateToPerson),
        false,
        'pi pi-external-link'
      )
    ];

    if (this.enrollment.branch?.data) {
      menuItems.push(this.menuItemUtils.getMenuItem(
        'enrollments.actions.navigateToBranch',
        this._menuItemActions(EnrollmentActionEnum.navigateToBranch),
        false,
        'pi pi-external-link'
      ));
    }

    if (this.readOnly) {
      return menuItems;
    }

    if (!(this.training()?.status?.data === TrainingStatusKey.Cancelled || this.training()?.isCancelled) &&
      (this.enrollment?.statusId === EnrollmentStatusKey.Cancelled || this.enrollment?.statusId === EnrollmentStatusKey.Reserve)) {
      menuItems.splice(1, 0, this.menuItemUtils.getMenuItem(
        'enrollments.actions.reEnroll',
        this._menuItemActions(EnrollmentActionEnum.reEnroll),
        this.enrollmentService.nrOfParticipants() >= this.training()?.maxParticipants
      ));
    }

    if (this.enrollment?.statusId === EnrollmentStatusKey.Enrolled || this.enrollment?.statusId === EnrollmentStatusKey.Reserve) {
      menuItems.splice(1, 0, this.menuItemUtils.getMenuItem(
        'enrollments.actions.edit',
        this._menuItemActions(EnrollmentActionEnum.edit)
      ));

      const cancelItem = this.menuItemUtils.getMenuItem(
        'enrollments.actions.cancel',
        this._menuItemActions(EnrollmentActionEnum.cancel),
        false,
        'pi pi-times'
      );
      cancelItem.styleClass = 'danger';
      menuItems.push(cancelItem);
    }
    return menuItems;
  }

  getTransitionLabel(transitionTypeId: string): Observable<string> {
    return this.referenceDataService.getTransitionType(transitionTypeId)
      .pipe(map(item => item.label));
  }
}
