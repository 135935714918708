<p-dialog
  *ngIf='dialogVisible'
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '80vw', height: '80vh' }"
  [focusOnShow]='false'
  appendTo="body"
  header="{{ 'branches.linkEnterprise' | translate }}"
>
  <lib-search [data$]='search$'
              [cardTemplate]='cardTemplate'
              [selectable]='true'
              [filters]='searchFilters'
              [inlineFilters]='true'
              [activeFiltersOnTop]='false'
              [showSearchFilter]='false'
              [saveFilters]='false'
              (onSelectChange)='selectionChange($event)'
              name='enterprise-link'
              searchPlaceholderKey='enterprises.searchPlaceholder'
              availableResultsTranslateKey='enterprises.enterprise'
  >
    <ng-template #cardTemplate let-item="item" let-selected="selected">
      <alimento-ipv-frontend-enterprise-card [enterprise]='item'
                                             [selected]='selected'
      >
      </alimento-ipv-frontend-enterprise-card>
    </ng-template>
  </lib-search>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="linkEnterprise()"
        [disabled]='!currentSelection'
        [loading]='loading'
        label="{{ 'branches.linkEnterprise' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'leaveDialog.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
