<alimento-ipv-frontend-training-plan-popup [trainingPlan]='currentTrainingPlan'
                                           (formSubmit)='trainingPlanSubmit($event)'
></alimento-ipv-frontend-training-plan-popup>

<alimento-ipv-frontend-training-plan-request-popup #trainingPlanRequestPopup
                                                   [branchId]='branchId'
                                                   (formSubmit)='executeRequestTrainingPlan($event, trainingPlanRequestPopup)'
></alimento-ipv-frontend-training-plan-request-popup>

<alimento-ipv-frontend-training-plan-reminder-popup [branchId]='branchId'
                                                    (formSubmit)='executeSendReminder($event)'
></alimento-ipv-frontend-training-plan-reminder-popup>

<alimento-ipv-frontend-training-plan-action-dialog [trainingPlan]='currentTrainingPlan'
                                                   [action]='currentTrainingPlanAction'
                                                   (submitDialog)='executeTrainingPlanAction($event)'
></alimento-ipv-frontend-training-plan-action-dialog>

<alimento-ipv-frontend-header>
  <div title class='flex gap-2 items-center'>
    <h2 class="text-xl text-text font-bold">{{ 'branches.trainingPlansTitle' | translate }}</h2>
    <a *ngIf='yearFilter'
       class='text-primary cursor-pointer'
       [routerLink]='["/branches", branchId]'
       [queryParams]='{activeTabIndex:4}'>
      {{'branches.trainingPlans.manage' | translate}}
    </a>
  </div>
  <div class='flex items-center gap-2'>
    <p-button
      (onClick)='requestTrainingPlan(trainingPlanRequestPopup)'
      *ngIf='!readOnly'
      icon='pi pi-plus'
      label="{{ 'branches.trainingPlans.request' | translate }}"
    ></p-button>
    <p-button
      (onClick)='createNewTrainingPlan()'
      *ngIf='!readOnly'
      icon='pi pi-plus'
      label="{{ 'branches.trainingPlans.new' | translate }}"
    ></p-button>
  </div>
</alimento-ipv-frontend-header>

<div class='flex flex-col gap-2' *ngIf='trainingPlans'>
  <div *ngFor='let plan of trainingPlans'>
    <alimento-ipv-frontend-training-plan-card
      *ngIf='showTrainingPlan(plan)'
      [trainingPlan]='plan'
      [readOnly]='readOnly'
      (actionClicked)='trainingPlanActionClicked($event)'
    ></alimento-ipv-frontend-training-plan-card>
  </div>
  <div *ngIf='trainingPlans.length === 0'>{{ 'branches.noTrainingPlans' | translate }}</div>
</div>
