<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<div *ngIf='templateForm' [formGroup]="templateForm">
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='templateId'
                                       (hasChanges)='notesHasChanges = $event'
                                       (countUpdated)='loadNotesCount()'
                                       [readonly]='readOnly'
                                       [type]='TrainingTemplateNote'>
  </alimento-ipv-frontend-notes-sidebar>

  <alimento-ipv-frontend-detail-page-layout
    header="{{ (templateType === TEMPLATE_TYPE.OPEN_TRAINING ? 'templates.templateOpenTraining' : 'titles.externalOffer.templateDetail') | translate }}"
    [breadcrumb]='breadcrumb'
    [fallbackUrl]="templateType === TEMPLATE_TYPE.OPEN_TRAINING ? '/training-templates' : '/external-offer'"
  >
    <ng-container status>
      <alimento-ipv-frontend-template-status-chip
        [status]="templateStatus"
      ></alimento-ipv-frontend-template-status-chip>
    </ng-container>

    <ng-container top-action>
      <div class="flex gap-4" *ngIf='!readOnly'>
        <p-button
          label="{{ 'templates.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="savingTemplate"
          (click)="saveTemplate()"
          [disabled]="isActivateDisabled"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        formControlName="basicDataForm"
        [templateType]='templateType'
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>

      <alimento-ipv-frontend-summary-description
        [hidden]="activeTabIndex !== 1"
        formControlName="descriptionForm"
        [templateType]='templateType'
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #summaryDescriptionComponent
      >
      </alimento-ipv-frontend-summary-description>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="templateId"
        [trainingMethodId]="basicInfoComponent.formGroup.get('methodId')?.value"
      >
      </alimento-ipv-frontend-sessions>

      <div *ngIf="activeTabIndex === 3 && searchTrainingData$">
        <alimento-ipv-frontend-header title="{{ 'externalOffer.trainings' | translate }}">
          <div class='flex items-center gap-2'>
            <p-button
              *ngIf='showBulkPublish'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.PUBLISH)'
              [loading]='loading'
              label="{{ 'externalOffer.publish' | translate }}"
            ></p-button>
            <p-button
              *ngIf='showBulkUnpublish'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.UNPUBLISH)'
              [loading]='loading'
              label="{{ 'externalOffer.unpublish' | translate }}"
            ></p-button>
            <p-button
              *ngIf='showBulkClose'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.cLOSE)'
              [loading]='loading'
              label="{{ 'externalOffer.close' | translate }}"
            ></p-button>
            <p-button
              *ngIf='showBulkReactivate'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.REACTIVATE)'
              [loading]='loading'
              label="{{ 'externalOffer.reactivate' | translate }}"
            ></p-button>
          </div>
        </alimento-ipv-frontend-header>

        <lib-search #trainingSearch
                    [data$]='searchTrainingData$'
                    [filters]='searchTrainingFilters'
                    [activeFilters]='defaultTrainingFilter'
                    [cardTemplate]='cardTemplate'
                    [sortItems]='sortTrainingsFilters'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [showSearchFilter]='false'
                    [saveFilters]='false'
                    [selectable]='true'
                    [multiselect]='true'
                    [showCheckboxes]='true'
                    (onSelectChange)='onTrainingSelectionChange($event)'
                    name='external-offer-trainings'
                    searchPlaceholderKey='trainings.searchbarPlaceholder'
                    availableResultsTranslateKey='trainings.nrOfTrainings'
        >
          <ng-template #cardTemplate let-item="item" let-selected="selected">
            <alimento-ipv-frontend-training-card
              [training]='item'
              [selected]='selected'
              tabindex="0"
            >
            </alimento-ipv-frontend-training-card>
          </ng-template>
        </lib-search>
      </div>
    </ng-container>

    <ng-container metadata>
       <p-button
        styleClass='w-full'
        label="{{ 'templates.actions.saveAndActivate' | translate }}"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="activatingTemplate"
        (click)="activateTemplate()"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft"
        [disabled]="!templateReadyToActivate || savingTemplate"
      ></p-button>

      <alimento-ipv-frontend-bulk-external-offer-popup #bulkPopup
                                                       *ngIf='templateType === TEMPLATE_TYPE.EXTERNAL_OFFER && !readOnly && templateStatus === templateStatusKeys.Active'
                                                       [templateId]='templateId'
                                                       [methodId]='template?.methodId'
                                                       (formSubmit)='createExternalOfferTrainingFromTemplate($event, bulkPopup)'
      ></alimento-ipv-frontend-bulk-external-offer-popup>

      <p-button *ngIf="!readOnly && templateStatus === templateStatusKeys.Active"
        styleClass='w-full'
        label="{{ 'templates.actions.createTrainingFromTemplate' | translate }}"
        variant='outlined'
        (click)="createTrainingFromTemplate(templateId)"

      ></p-button>

      <div
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
        class="activation-not-possible"
      >
        <span [innerHTML]="'templates.activateNotPossible' | translate"></span>
      </div>

      <div *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
           class='flex items-center justify-between gap-2 w-full'>
        <p-button label="{{ 'trainings.showErrors' | translate }}"
                  [link]='true'
                  styleClass='whitespace-nowrap pl-0'
                  (onClick)='showRequiredToggle.writeValue(!showRequiredToggle.checked())'
        ></p-button>
        <p-toggle-switch #showRequiredToggle [formControl]="requiredFieldToggle"
        ></p-toggle-switch>
      </div>

      <span
        class="h-[1px] bg-grey-2"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
      ></span>

      <p-button *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && templateId"
                styleClass='px-0 text-danger '
                label="{{ 'templates.actions.delete' | translate }}"
                [link]='true'
                (onClick)='deleteTemplate()'
      ></p-button>

      <span class="h-[1px] bg-grey-2" *ngIf="templateId"></span>

      <alimento-ipv-frontend-metadata-item *ngIf="template?.createdBy"
                                           header="{{ 'templates.createdBy' | translate }}"
                                           value="{{ template?.createdBy }}"
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="template?.createdOn"
                                           header="{{ 'templates.createdOn' | translate }}"
                                           value="{{ template?.createdOn | date : 'dd/MM/yyyy' }}"
      ></alimento-ipv-frontend-metadata-item>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
