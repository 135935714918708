<div [formGroup]="formGroup" class="person-form max-w-[650px]">

  <div class="flex gap-4">
    <div class='basis-1/2'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='firstName'
        fieldKey='persons.firstName'
        [required]='!dataOptional'
        [formInputTemplate]='firstNameTemplate'
      >
        <ng-template #firstNameTemplate>
          <span *ngIf='readOnly'>{{person?.firstName}}</span>
          <input #focusElement *ngIf='!readOnly' class="w-full" formControlName="firstName" id="firstName" pInputText type="text" />
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <div class='basis-1/2'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='lastName'
        fieldKey='persons.lastName'
        [required]='true'
        [formInputTemplate]='lastNameTemplate'
      >
        <ng-template #lastNameTemplate>
          <span *ngIf='readOnly'>{{person?.lastName}}</span>
          <input *ngIf='!readOnly' class="w-full" formControlName="lastName" id="lastName" pInputText type="text" />
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>

  <div class='max-w-[204px]'>
    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [errorTemplate]='dateErrorTemplate'
      [required]='dateOfBirthRequired'
      formField='dateOfBirth'
      fieldKey='persons.dateOfBirth'
    >
      <span *ngIf='readOnly'>{{(person?.dateOfBirth || "") | date:'dd/MM/yyyy'}}</span>
      <lib-date-picker
        *ngIf='!readOnly'
        [maxDate]="maxDate"
        [showClear]="true"
        formControlName="dateOfBirth"
        id="dateOfBirth"
      ></lib-date-picker>

      <ng-template #dateErrorTemplate>
        <small *ngIf='formGroup.get("dateOfBirth")?.hasError("required")'>
          {{ 'trainings.sessions.dateRequired' | translate }}
        </small>
        <small *ngIf='formGroup.get("dateOfBirth").hasError("invalidDate")'>
          {{ 'validation.invalidDate' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>
  </div>

  <small *ngIf="nameAndBirthdayNotUnique" class="flex w-full items-center p-2 mb-2 bg-warning-bg text-warning rounded">
    <span class="material-symbols-outlined mr-2"> warning </span>
    {{ 'persons.nameAndDateOfBirthNotUnique' | translate }}
  </small>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [formInputTemplate]='genderTemplate'
    [required]='!dataOptional'
    formField='gender'
    fieldKey='persons.gender'
  >
    <ng-template #genderTemplate>
      <span *ngIf='readOnly'>{{person?.gender?.label}}</span>
      <p-select
        *ngIf="!readOnly"
        [filter]="true"
        [options]="genders$ | async"
        appendTo="body"
        formControlName="gender"
        id="gender"
        optionLabel="label"
        optionValue="data"
        placeholder="{{ 'persons.genderPlaceholder' | translate }}"
        styleClass="w-full"
        [showClear]="true"
      ></p-select>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [formInputTemplate]='languagesTemplate'
    [required]='false'
    formField='language'
    fieldKey='persons.language'
  >
    <ng-template #languagesTemplate>
      <span *ngIf='readOnly'>{{languageReadOnly}}</span>
      <p-select
        *ngIf="!readOnly"
        [filter]="true"
        [options]="languages"
        appendTo="body"
        formControlName="language"
        id="language"
        optionLabel="label"
        optionValue="data"
        placeholder="{{ 'persons.languagePlaceholder' | translate }}"
        styleClass="w-full"
        [showClear]="true"
      ></p-select>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [formInputTemplate]='nationalityTemplate'
    [required]='false'
    formField='nationality'
    fieldKey='persons.nationality'
  >
    <ng-template #nationalityTemplate>
      <span *ngIf='readOnly'>{{nationalityReadOnly}}</span>
      <p-select
        *ngIf="!readOnly"
        [filter]="true"
        [options]="nationalities"
        appendTo="body"
        formControlName="nationality"
        id="nationality"
        optionLabel="label"
        optionValue="data"
        placeholder="{{ 'persons.nationalityPlaceholder' | translate }}"
        styleClass="w-full"
        [showClear]="true"
      ></p-select>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [formInputTemplate]='nationalIdentificationNumberTemplate'
    [errorTemplate]='nationalIdentificationErrorTemplate'
    [required]='false'
    formField='nationalIdentificationNumber'
    fieldKey='persons.nationalIdentificationNumberBis'
  >
    <ng-template #nationalIdentificationNumberTemplate>
      <span *ngIf='readOnly' class='inputHeight'>{{person?.nationalIdentificationNumber}}</span>
      <input *ngIf="!readOnly && formGroup.get('nationality')?.value !== BELGIAN_NATIONALITY"
             class="w-full" formControlName="nationalIdentificationNumber" id="nationalIdentificationNumber"
             pInputText type="text" />
      <p-inputMask
        *ngIf="!readOnly && formGroup.get('nationality')?.value === BELGIAN_NATIONALITY"
        [autoClear]="false"
        [required]="false"
        formControlName="nationalIdentificationNumber"
        id="nationalIdentificationNumber"
        mask="99.99.99-999.99"
        styleClass="max-w-[204px]"
      ></p-inputMask>
    </ng-template>
    <ng-template #nationalIdentificationErrorTemplate>
      <small *ngIf="nationalIdentificationNumber?.hasError('nationalIdInvalid')" class="p-error block">
        {{ 'persons.nationalIdValidation.invalid' | translate }}
      </small>

      <small *ngIf="nationalIdentificationNumber?.hasError('duplicateNationalId')" class="p-error block">
        {{ 'persons.nationalIdValidation.duplicate'| translate :
        { name: nationalIdentificationNumber?.errors?.['duplicateNationalId']?.firstName + ' ' +
            nationalIdentificationNumber?.errors?.['duplicateNationalId']?.lastName }
        }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [formInputTemplate]='educationLevelTemplate'
    [required]='false'
    formField='educationLevel'
    fieldKey='persons.educationLevel'
  >
    <ng-template #educationLevelTemplate>
      <span *ngIf='readOnly' class='inputHeight'>{{person?.educationLevel?.label}}</span>
      <p-select
        *ngIf="!readOnly"
        [filter]="true"
        [options]="educationLevels$ | async"
        appendTo="body"
        formControlName="educationLevel"
        id="educationLevel"
        optionLabel="label"
        optionValue="data"
        placeholder="{{ 'persons.educationLevelPlaceholder' | translate }}"
        styleClass="w-full"
        [showClear]="true"
      ></p-select>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <div [hidden]="!showPersonalContactData">
    <h2 class="text-xl text-text font-bold my-4">{{ 'persons.personalContactData' | translate }}</h2>

    <div class="p-4 mb-4 bg-grey-0">
      {{ 'persons.personalContactDataInfo' | translate }}
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='phoneNumber'
      fieldKey='persons.phoneNumber'
      [formInputTemplate]='phoneNumberTemplate'
      [errorTemplate]='phoneErrorTemplate'
    >
      <ng-template #phoneNumberTemplate>
        <span *ngIf='readOnly' class='inputHeight'>{{person?.phoneNumber}}</span>
        <input *ngIf='!readOnly' class="w-full" formControlName="phoneNumber" id="phoneNumber" pInputText type="text" />
      </ng-template>
      <ng-template #phoneErrorTemplate>
        <small *ngIf="phoneNumber?.errors?.['pattern']" class="p-error block">
          {{ 'persons.invalidPhoneNumber' | translate }}
        </small>
        <small *ngIf="formGroup.get('phoneNumber')?.errors?.['required']" class="p-error block">
          {{ 'validation.isRequired' | translate : { field: 'persons.phoneNumber' | translate } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='email'
      fieldKey='persons.email'
      [required]='!dataOptional'
      [formInputTemplate]='emailTemplate'
      [errorTemplate]='emailErrorTemplate'
    >
      <ng-template #emailTemplate>
        <span *ngIf='readOnly' class='inputHeight'>{{person?.email}}</span>
        <input *ngIf='!readOnly' class="w-full" formControlName="email" id="email" pInputText type="text" />
      </ng-template>
      <ng-template #emailErrorTemplate>
        <small *ngIf="formGroup.get('email')?.errors?.['required']" class="p-error block">
          {{ 'validation.isRequired' | translate : { field: 'persons.email' | translate } }}
        </small>
        <small *ngIf="formGroup.get('email')?.errors?.['email']" class="p-error block">
          {{ 'validation.invalidEmail' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-address
      [formControl]="$any(formGroup.get('address'))"
      [optional]="!showPersonalContactData || dataOptional"
      [readOnly]='readOnly'
    >
    </alimento-ipv-frontend-address>
  </div>
</div>
