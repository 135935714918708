<div *ngIf="training"
     (click)='navigateToTraining($event)'
     [ngClass]='{
     "bg-primary-hover border-primary" : selected,
     "bg-white": !selected,
     }'
     class="shadow-grey-2 shadow-md my-2 p-4 hover:bg-primary-hover relative border border-input-border cursor-pointer"
>
  <div class='grid grid-cols-2 lg:grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_250px] gap-4'>
    <div class='flex flex-col gap-2'>
      <div class='flex flex-wrap items-center gap-2'>
        <span class='font-bold text-lg'>{{training.alimentoId}} - {{training.customTitle}}</span>
        <i *ngIf="training.published" class='font-material filled text-primary' [pTooltip]='"trainings.published" | translate'>check_circle</i>
        <alimento-ipv-frontend-training-status-chip [status]='training.statusId'></alimento-ipv-frontend-training-status-chip>
      </div>
      <span>{{trainingType}}</span>
    </div>
    <div class='flex flex-col gap-2 text-grey-3'>
      <div class='flex gap-2 items-center' *ngIf='training.trainingAllowanceApplicationBranchId'>
        <i class='font-material text-lg'>store</i>
        <a class="hover:underline cursor-pointer text-primary"
           [routerLink]='["/branches", training.trainingAllowanceApplicationBranchId]' (click)='$event.stopImmediatePropagation()'>
          {{training.trainingAllowanceApplicationBranchName}}
        </a>
      </div>

      <div class='flex gap-2 items-center' *ngIf='training.attendanceStatusId'>
        <i class='font-material text-lg'>inventory</i>
        <span></span>
      </div>

      <div class='flex gap-2 items-center' *ngIf='training.teacherBranchName'>
        <i class='font-material text-lg'>school</i>
        <span>{{training.teacherBranchName}}</span>
      </div>

      <div class='flex gap-2 items-center' *ngIf='training.location'>
        <i class='font-material text-lg'>location_on</i>
        <span>{{training.location}}</span>
      </div>

      <div class='flex gap-2 items-center' *ngIf='training.caseManagerName'>
        <i class='font-material text-lg'>person</i>
        <span>{{training.caseManagerName}}</span>
      </div>

      <div class='flex gap-2 items-center' *ngIf='training.rubricId'>
        <i class='font-material text-lg'>folder</i>
        <span>{{rubric}}</span>
      </div>
    </div>
    <div class='flex flex-col gap-2 lg:items-end'>
      <div class='flex gap-2 items-center' *ngIf='training.startDate || training.endDate'>
        <i class='font-material text-lg'>calendar_month</i>
        <span>{{training.startDate | date : 'dd/MM/yyyy'}} - {{training.endDate | date : 'dd/MM/yyyy'}}</span>
      </div>
      <div *ngIf='training.numberOfParticipants || training.maxNumberOfParticipants' class='flex gap-1 items-center'>
        <span *ngIf='training.maxNumberOfParticipants'>{{training.numberOfParticipants}}/{{training.maxNumberOfParticipants}}</span>
        <span *ngIf='!training.maxNumberOfParticipants'>{{training.numberOfParticipants}}</span>
        <span>{{'trainings.basicData.participants' | translate | lowerFirstLetter}}</span>
      </div>
    </div>
  </div>
</div>
