<div *ngIf="purchaseEntry || purchaseEntryReference" class="hover:bg-primary hover:bg-opacity-[7%] p-4">
  <div class="flex flex-col gap-1 lg:flex-row lg:justify-between lg:gap-4">
    <div class='flex flex-col gap-1'>
      <div class='flex items-center gap-16'>
        <span *ngIf='purchaseEntry' class='font-bold'>{{"trainings.purchaseEntries.number" | translate}}: {{purchaseEntry.purchaseEntryNumber}}</span>
        <div class='flex items-center gap-1 flex-wrap'>
          <span class='font-bold'>{{"trainings.purchaseEntries.reference" | translate}}:</span>
          <span>{{purchaseEntry?.reference || purchaseEntryReference?.reference}}</span>
          <span *ngIf='purchaseEntry?.referenceDate'>- {{purchaseEntry.referenceDate | date: 'dd/MM/yyyy'}}</span>
          <span *ngIf='purchaseEntryReference?.referenceDate'>- {{purchaseEntryReference.referenceDate | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class='flex items-center gap-1 flex-wrap'>
        <span class='text-lg'>{{supplierBranch?.alimentoId}}</span>
        <span>- {{supplierBranch?.name}}</span>
        <span>- {{supplierBranch?.address}}</span>
      </div>
    </div>
    <div class='flex flex-col items-end'>
      <span *ngIf='purchaseEntry' class='font-bold text-primary'>{{status}}</span>
      <span *ngIf='purchaseEntry?.paymentDate'>
        {{'reimbursements.paymentDate' | translate:{date: purchaseEntry.paymentDate | date: "dd/MM/yyyy"} }}
      </span>
      <span *ngIf='purchaseEntryReference?.paymentDate'>
        {{'reimbursements.paymentDate' | translate:{date: purchaseEntryReference.paymentDate | date: "dd/MM/yyyy"} }}
      </span>
    </div>
  </div>
</div>

