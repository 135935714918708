<p-dialog
  *ngIf='popupVisible'
  (blur)='popupVisible = false'
  (onHide)='closePopup()'
  [(visible)]='popupVisible'
  [closable]='false'
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [focusOnShow]='false'
  [style]="{ width: '60rem' }"
  appendTo='body'
  header="{{(participant?.id ? 'enrollments.editParticipant' : 'enrollments.addParticipant') | translate}}"
>
  <div *ngIf='participant' [formGroup]='formGroup'>
    <h2 class="text-xl text-text font-bold my-4">{{'enrollments.participant' | translate}}</h2>

    <div class='flex flex-col gap-2' *ngIf='showSearch; else newPersonTemplate'>
      <div class='flex flex-col gap-2'>
        <div class='field-radiobutton'>
          <p-radioButton
            [formControl]='personFormControl'
            inputId='existing'
            name='personMatch'
            value='existing'
          ></p-radioButton>
          <label for='existing'>{{ 'enrollments.participants.existingPerson' | translate }}</label>
        </div>
        <div *ngIf='personFormControl.value === "existing"'>
          <alimento-ipv-frontend-person-select
            [formControl]="searchPersonFormControl"
            [includeEmployments]='true'
            [showEmployments]='true'
          ></alimento-ipv-frontend-person-select>
        </div>
      </div>

      <div class='flex flex-col gap-2'>
        <div class='field-radiobutton'>
          <p-radioButton
            [formControl]='personFormControl'
            inputId='new'
            name='personMatch'
            value='new'
          ></p-radioButton>
          <label for='new'>{{ 'enrollments.participants.newPerson' | translate }}</label>
        </div>
        <div *ngIf='personFormControl.value === "new"'>
          <div class='conditional-fields'>
            <alimento-ipv-frontend-person [dataOptional]='dataOptional'
                                          [disableUniqueValidation]='disableUniquePersonValidation'
                                          [dateOfBirthRequired]='!dataOptional && birthDateRequired'
                                          formControlName='person'
                                          name="person"
            ></alimento-ipv-frontend-person>
          </div>
        </div>
      </div>
    </div>

    <ng-template #newPersonTemplate>
      <alimento-ipv-frontend-person [dataOptional]='dataOptional'
                                    [disableUniqueValidation]='disableUniquePersonValidation'
                                    [dateOfBirthRequired]='!dataOptional && birthDateRequired'
                                    formControlName='person'
                                    name="person"
      ></alimento-ipv-frontend-person>
    </ng-template>

    <div class='mt-4'>
      <h2 class="text-xl text-text font-bold my-4">{{'enrollments.professionalPersonData' | translate}}</h2>
      <alimento-ipv-frontend-form-field *ngIf='groupId || !dataOptional'
                                        [formGroup]='formGroup'
                                        [required]='!dataOptional'
                                        formField='employer'
                                        fieldKey='branches.branch'
      >
        <alimento-ipv-frontend-branch-select formControlName='employer'
                                             [extraFilters]='groupIdFilter'
        ></alimento-ipv-frontend-branch-select>

      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='function'
        fieldKey='enrollments.function'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('function')?.value}}</span>
        <div *ngIf='formGroup.enabled'>
          <input class="w-full" formControlName="function" id="function" pInputText type="text" />
        </div>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='!dataOptional'
        formField='workStatusId'
        fieldKey='enrollments.workStatus'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{workStatusReadOnly}}</span>
        <div *ngIf='formGroup.enabled'>
          <p-select
            *ngIf="workStatus$ | async as workStatus"
            [filter]="true"
            [options]="workStatus"
            appendTo="body"
            formControlName="workStatusId"
            id="workStatusId"
            optionLabel="label"
            optionValue="data"
            placeholder="{{ 'enrollments.chooseOption' | translate }}"
            styleClass="w-full"
            [showClear]="true"
          ></p-select>
        </div>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field *ngIf='showEmploymentStartDate'
                                        [formGroup]='formGroup'
                                        formField='employmentStartDate'
                                        fieldKey='enrollments.employmentStartDate'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('employmentStartDate')?.value | date:'dd/MM/yyyy'}}</span>
        <div *ngIf='formGroup.enabled'>
          <lib-date-picker formControlName='employmentStartDate'></lib-date-picker>
        </div>

        <div class="flex gap-4 w-full items-center p-2 mb-2 mt-4 bg-warning-bg text-warning rounded" *ngIf='externalWebsite'>
          <span class="material-symbols-outlined"> warning </span>
          <span>{{'enrollments.verify.noEmploymentStartDateInfo' | translate}}</span>
        </div>
      </alimento-ipv-frontend-form-field>

      <div class='conditional-fields' [hidden]='!showInterimOfficeFields'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='showInterimOfficeFields && externalWebsite'
          formField='interimOfficeId'
          fieldKey='enrollments.interimOffice'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{interimOfficeReadOnly}}</span>
          <div *ngIf='formGroup.enabled' class='flex flex-col gap-2'>
            <div class='field-radiobutton'>
              <p-radioButton inputId='newInterimOffice_false'
                             formControlName='newInterimOffice'
                             name='newInterimOffice'
                             [value]='false'
              ></p-radioButton>
              <label for='newInterimOffice_false'>{{ 'enrollments.existingInterimOffice' | translate }}</label>
            </div>
            <div *ngIf="formGroup.get('newInterimOffice').value === false">
              <alimento-ipv-frontend-interim-office-select
                formControlName='interimOfficeId'
              ></alimento-ipv-frontend-interim-office-select>
            </div>

            <div class='field-radiobutton'>
              <p-radioButton inputId='newInterimOffice_true'
                             formControlName='newInterimOffice'
                             name='newInterimOffice'
                             [value]='true'
              ></p-radioButton>
              <label for='newInterimOffice_true'>{{ 'enrollments.newInterimOffice' | translate }}</label>
            </div>
            <div *ngIf="formGroup.get('newInterimOffice').value === true">
              <alimento-ipv-frontend-form-field formField='interimOfficeName'
                                                fieldKey='enrollments.interimOfficeName'
                                                [required]='this.externalWebsite'
                                                [formGroup]='formGroup'
              >
                <input class="w-full" formControlName="interimOfficeName" id="interimOfficeName" pInputText type="text" />
              </alimento-ipv-frontend-form-field>
              <div class='max-w-[425px]'>
                <alimento-ipv-frontend-address #interimAddress formControlName='interimOfficeAddress' [optional]='!this.externalWebsite'
                ></alimento-ipv-frontend-address>
              </div>
            </div>
          </div>
        </alimento-ipv-frontend-form-field>

        <div>
          <h2 class="text-xl text-text font-bold my-4">{{ 'enrollments.interimContact' | translate }}</h2>
          <div class='flex gap-4'>
            <div class='basis-1/2'>
              <alimento-ipv-frontend-form-field
                [formGroup]='formGroup'
                [required]='externalWebsite && showInterimOfficeFields'
                formField='interimOfficeContactFirstName'
                fieldKey='enrollments.interimContactFirstName'
              >
                <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactFirstName')?.value}}</span>
                <div *ngIf='formGroup.enabled'>
                  <input class="w-full" formControlName="interimOfficeContactFirstName" id="enrollment.interimOfficeContactFirstName" pInputText type="text" />
                </div>
              </alimento-ipv-frontend-form-field>
            </div>
            <div class='basis-1/2'>
              <alimento-ipv-frontend-form-field
                [formGroup]='formGroup'
                [required]='externalWebsite && showInterimOfficeFields'
                formField='interimOfficeContactLastName'
                fieldKey='enrollments.interimContactLastName'
              >
                <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactLastName')?.value}}</span>
                <div *ngIf='formGroup.enabled'>
                  <input class="w-full" formControlName="interimOfficeContactLastName" id="enrollment.interimOfficeContactLastName" pInputText type="text" />
                </div>
              </alimento-ipv-frontend-form-field>
            </div>
          </div>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [errorTemplate]='emailErrorTemplate'
            [required]='externalWebsite && showInterimOfficeFields'
            formField='interimOfficeContactEmail'
            fieldKey='enrollments.interimContactEmail'
          >
            <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactEmail')?.value}}</span>
            <div *ngIf='formGroup.enabled'>
              <input class="w-full" formControlName="interimOfficeContactEmail" id="enrollment.interimOfficeContactEmail" pInputText type="text" />
            </div>

            <ng-template #emailErrorTemplate>
              <small *ngIf="formGroup.get('interimOfficeContactEmail')?.errors?.['email']">
                {{ 'validation.invalidEmail' | translate }}
              </small>
              <small *ngIf="formGroup.get('interimOfficeContactEmail')?.errors?.['required']">
                {{ 'validation.isRequired' | translate:{field: 'enrollments.interimContactEmail' | translate} }}
              </small>
            </ng-template>
          </alimento-ipv-frontend-form-field>
        </div>
      </div>
    </div>

    <div formArrayName='sessions' *ngIf='showAbsences'>
      <div class='flex gap-2 items-center cursor-pointer' (click)='registerAbsenceExpanded = !registerAbsenceExpanded'>
        <i class="pi pi-angle-{{ registerAbsenceExpanded ? 'up' : 'down' }}"></i>
        <h2 class='text-xl text-text font-bold my-4'>{{'trainings.sessions.actions.registerAbsence' | translate}} ({{'validation.optional' | translate}})</h2>
      </div>
      <div *ngIf='registerAbsenceExpanded'>
        <div class="w-full flex items-center p-2 font-bold border-b border-b-grey-3 pr-4">
          <span class="w-1/2">{{ 'trainings.sessions.sessions' | translate }}</span>
          <span class="w-1/2">{{ 'trainings.sessions.registerAbsenceDialog.absent' | translate }}</span>
        </div>

        <div *ngFor="let sessionAbsence of sessions.controls; let i = index">
          <div class="p-2 not-last:border-b border-b-grey-2">
            <div [formGroupName]="i" class="flex items-center min-h-[2.4rem]">
              <div class="w-1/2"
                   tooltipStyleClass='max-w-max'
                   tooltipPosition='bottom'
                   pTooltip="{{ sessionAbsence.get('date').value | date: 'dd/MM/yyyy' }} {{ sessionAbsence.get('startTime').value }} - {{sessionAbsence.get('endTime').value}}">
                {{ sessionAbsence.get('title').value}}
              </div>
              <div class="flex items-center gap-4 w-1/2">
                <p-checkbox [binary]="true" formControlName="absent"></p-checkbox>
                <div *ngIf='!readOnly'>
                  <p-select
                    *ngIf="sessionAbsence.get('absent').value === true"
                    [options]="absenceReasons"
                    formControlName="reasonForAbsenceId"
                    optionValue="data"
                    optionLabel="label"
                    [placeholder]="'trainings.sessions.registerAbsenceDialog.reasonPlaceholder' | translate"
                    appendTo="body"
                    styleClass="w-full"
                  ></p-select>
                </div>
                <span *ngIf='readOnly'>
                    {{getReasonLabel(sessionAbsence.get('reasonForAbsenceId')?.value)}}
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate='footer'>
    <div class='flex items-center gap-2 w-full'>
      <p-button
        *ngIf='participant'
        [loading]='loading'
        (onClick)='addOrUpdateForm()'
        label="{{ (participant?.id ? 'enrollments.editParticipant' : 'enrollments.addParticipant') | translate }}"
      ></p-button>
      <p-button
        (onClick)='closePopup()'
        label="{{ 'persons.transitions.actions.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
