<div [formGroup]="formGroup">
  <div class='grid grid-cols-2 gap-x-8 gap-y-2'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='trainings.sessions.teacherBranch'
                                      formField='teacherBranch'>
      <alimento-ipv-frontend-branch-select
        formControlName='teacherBranch'
        placeholder='trainings.sessions.teacherBranchPlaceholder'
        [extraFilters]='[teacherFilter]'
      ></alimento-ipv-frontend-branch-select>
    </alimento-ipv-frontend-form-field>

    <div class='flex items-center gap-2'>
      <p-toggle-switch #publishedToggleEl formControlName='publish'
      ></p-toggle-switch>
      <label (click)='publishedToggleEl.writeValue(!publishedToggleEl.checked())'
             class='whitespace-nowrap hover:underline cursor-pointer'
             for='publishedToggleEl'>
        {{ 'trainings.actions.publish' | translate }}
      </label>
    </div>

    <div class='flex flex-col gap-2'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [errorTemplate]='websiteErrorTemplate'
        fieldKey='branches.website'
        formField='website'
      >
        <input class="w-full"
               formControlName="website"
               id="website"
               pInputText
        />
        <ng-template #websiteErrorTemplate>
          <small *ngIf="formGroup.get('website')?.hasError('maxlength')">
            {{ 'validation.maxLength' | translate:{number: 500} }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [errorTemplate]='locationErrorTemplate'
        fieldKey='externalOffer.location'
        formField='location'
      >
        <input class="w-full"
               formControlName="location"
               id="location"
               pInputText
        />
        <ng-template #locationErrorTemplate>
          <small *ngIf="formGroup.get('location')?.hasError('maxlength')">
            {{ 'validation.maxLength' | translate:{number: 500} }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <div>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formGroupClass='!pb-2'
        fieldKey='externalOffer.contactEmployments'
      >
        <ng-container formArrayName='contactEmployments'>
          <div class='flex flex-col gap-2'>
            <div *ngFor="let contactEmploymentForm of contactEmployments.controls; let i = index">
              <div [formGroupName]="i" class='flex items-center justify-between'>
                <div class='flex-grow'>
                  <alimento-ipv-frontend-employment-select
                    [formControl]='$any(contactEmploymentForm)'
                    [branchId]='formGroup.get("teacherBranch").value?.branchId'
                  ></alimento-ipv-frontend-employment-select>
                </div>
                <div class='basis-12'>
                  <button *ngIf="contactEmploymentCount > 1" (click)="deleteContactEmployment(i)" class="p-2 group">
                    <i class="pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </alimento-ipv-frontend-form-field>

      <div *ngIf='contactEmploymentCount < 2 && formGroup.get("teacherBranch").value?.branchId'
           class='flex items-center w-fit p-2 text-primary hover:bg-primary-hover' >
        <button (click)='addContactEmployment()'><i class='pi pi-plus mr-2'></i> {{'externalOffer.addContactEmployment' | translate}}</button>
      </div>
    </div>
  </div>

  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    fieldKey='paidEducationalLeaves.sessions'
  >
    <div *ngFor='let sessionForm of sessions.controls; let i = index' formArrayName='sessions'>
      <div [formGroupName]='i' class='flex items-start gap-4 flex-wrap'>
        <div class="w-[156px]">
          <alimento-ipv-frontend-form-field
            [formGroup]='$any(sessionForm)'
            [errorTemplate]='dateErrorTemplate'
            [required]='true'
            formField='date'
            fieldKey='trainings.sessions.date'
          >
            <lib-date-picker
              formControlName="date"
              id="date"
            ></lib-date-picker>

            <ng-template #dateErrorTemplate>
              <small *ngIf='sessionForm.get("date")?.hasError("required")'>
                {{ 'trainings.sessions.dateRequired' | translate }}
              </small>
              <small *ngIf='sessionForm.get("date").hasError("invalidDate")'>
                {{ 'validation.invalidDate' | translate }}
              </small>
            </ng-template>
          </alimento-ipv-frontend-form-field>
        </div>

        <div>
          <div class='flex items-center gap-4'>
            <div>
              <label class="block" for="startTime">{{ 'trainings.sessions.startTime' | translate }} ({{ 'validation.optional' | translate }})</label>
              <alimento-ipv-frontend-time-picker
                id="startTime"
                [formControl]="$any($any(sessionForm).controls['startTime'])"
              ></alimento-ipv-frontend-time-picker>
            </div>

            <div>
              <label class="block" for="endTime">{{ 'trainings.sessions.endTime' | translate }} ({{ 'validation.optional' | translate }})</label>
              <alimento-ipv-frontend-time-picker
                id="endTime"
                [formControl]="$any($any(sessionForm).controls['endTime'])"
              ></alimento-ipv-frontend-time-picker>
            </div>

            <div>
              <label class="block" for="duration">{{ 'trainings.sessions.duration' | translate }} ({{ 'validation.optional' | translate }})</label>
              <alimento-ipv-frontend-time-picker
                id="duration"
                [formControl]="$any($any(sessionForm).controls['duration'])"
              ></alimento-ipv-frontend-time-picker>
            </div>
          </div>

          <small *ngIf="sessionForm.get('endTime')?.hasError('minValueToLow')" class="p-error block">
            {{ 'trainings.sessions.endTimeToLow' | translate }}
          </small>
          <small *ngIf="sessionForm.get('duration')?.hasError('invalidDuration')" class="p-error block">
            {{ 'trainings.sessions.invalidDuration' | translate }}
          </small>
          <small *ngIf="sessionForm.get('duration')?.hasError('minDurationToLow')" class="p-error block">
            {{ 'trainings.sessions.durationToLow' | translate }}
          </small>
        </div>

        <div class='flex-grow'>
          <alimento-ipv-frontend-form-field
            [formGroup]='$any(sessionForm)'
            formField='sessionMethodId'
            fieldKey='trainings.sessions.method'
            [required]='true'
          >
            <p-select
              [filter]="true"
              [options]="methods"
              [showClear]="true"
              appendTo="body"
              formControlName="sessionMethodId"
              id="sessionMethodId"
              optionLabel="label"
              optionValue="data"
              placeholder="{{ 'trainings.sessions.methodPlaceholder' | translate }}"
              styleClass="w-full"
            ></p-select>
          </alimento-ipv-frontend-form-field>
        </div>
        <div class='basis-12'>
          <button (click)="deleteSession(i)" class="p-2 mt-6 group">
            <i class="pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover"></i>
          </button>
        </div>
      </div>
    </div>

    <div class='flex items-center w-fit p-2 text-primary hover:bg-primary-hover' >
      <button (click)='addSession()'><i class='pi pi-plus mr-2'></i> {{'trainings.addSession' | translate}}</button>
    </div>
  </alimento-ipv-frontend-form-field>

</div>
