<alimento-ipv-frontend-overview-page-layout header='externalOffer.menuTitle'>
  <ng-container top-right>
    <p-button
      *ngIf='!readOnly'
      label="{{ 'templates.actions.add' | translate }}"
      [routerLink]="['templates','create']"
    ></p-button>
  </ng-container>

  <p-tabs [(value)]='activeIndex'>
    <p-tablist>
      <p-tab value='0'>
        <span class='text-xl p-tabview-title'>{{'externalOffer.templates' | translate}}</span>
      </p-tab>
      <p-tab value='1'>
        <span class='text-xl p-tabview-title'>{{'externalOffer.trainings' | translate}}</span>
      </p-tab>
    </p-tablist>

    <p-tabpanels>
      <p-tabpanel value='0'>
        <div class='my-4'>
          <lib-search [data$]='searchTemplateData$'
                      [filters]='searchTemplateFilters'
                      [cardTemplate]='templateCardTemplate'
                      [sortItems]='sortTemplatesFilters'
                      [expandAll]='false'
                      name='external-offer-templates-list'
                      searchPlaceholderKey='templates.searchbarPlaceholder'
                      availableResultsTranslateKey='templates.menuTitle'
          >
            <ng-template #templateCardTemplate let-template="item">
              <alimento-ipv-frontend-external-offer-card [template]='template'
              >
              </alimento-ipv-frontend-external-offer-card>
            </ng-template>
          </lib-search>
        </div>
      </p-tabpanel>

      <p-tabpanel value='1'>
        <div class='flex justify-end'>
          <div class='flex items-center gap-2'>
            <p-button
              *ngIf='showPublish'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.PUBLISH)'
              [loading]='loading'
              label="{{ 'externalOffer.publish' | translate }}"
            ></p-button>
            <p-button
              *ngIf='showUnpublish'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.UNPUBLISH)'
              [loading]='loading'
              label="{{ 'externalOffer.unpublish' | translate }}"
            ></p-button>
            <p-button
              *ngIf='showClose'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.cLOSE)'
              [loading]='loading'
              label="{{ 'externalOffer.close' | translate }}"
            ></p-button>
            <p-button
              *ngIf='showReactivate'
              (click)='executeBulkAction(EXTERNAL_OFFER_TRAINING_BULK_ACTION.REACTIVATE)'
              [loading]='loading'
              label="{{ 'externalOffer.reactivate' | translate }}"
            ></p-button>
          </div>
        </div>
        <div class='my-4'>
          <lib-search [data$]='searchTrainingData$'
                      [filters]='searchTrainingFilters'
                      [cardTemplate]='trainingCardTemplate'
                      [expandAll]='false'
                      [selectable]='true'
                      [multiselect]='true'
                      [showCheckboxes]='true'
                      [addSelectAll]='true'
                      [maxSelectAll]='100'
                      [sortItems]='sortTrainingsFilters'
                      idField='alimentoId'
                      #trainingSearch
                      name='external-offer-trainings-list'
                      searchPlaceholderKey='trainings.searchbarPlaceholder'
                      availableResultsTranslateKey='trainings.menuTitle'
                      (onSelectChange)='onselectionchange($event)'
          >
            <ng-template #trainingCardTemplate let-training="item" let-selected="selected">
              <alimento-ipv-frontend-training-card [training]='training'
                                                   [selected]='selected'
                                                   tabindex="0"
              >
              </alimento-ipv-frontend-training-card>
            </ng-template>
          </lib-search>
        </div>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</alimento-ipv-frontend-overview-page-layout>
